export default {
    methods: {
        /**
         * Retrieves the property value from the `propertyValue` property by name.
         * @param {String} propName The property name.
         * @returns {Array<Stringæ>|undefined}
         */
        getPropValueAnnotations(propName) {
            if (
                !this.propertyValueByNames[propName] ||
                !this.propertyValueByNames[propName].length
            )
                return;

            const values = [];

            this.propertyValueByNames[propName].forEach((propValue) => {
                values.push(propValue.value);

                this.propertyAnnotations[propName] =
                    this.propertyAnnotations[propName] || {};
                this.propertyAnnotations[propName][propValue.value] =
                    propValue.tags;
            });

            return values;
        },
    },

    computed: {
        /**
         * Checks if the current class contains values in the `propertyValues` attribute.
         * @return boolean
         */
        hasPropertyValues: function () {
            return (
                !!this.$attrs &&
                this.$attrs.propertyValues &&
                this.$attrs.propertyValues.length
            );
        },

        /**
         * Groups by name values contained in the `propertyValues` attribute.
         * Eg. converts propertyValues which are in this format:
         * `[ 0: { name: 'label', value: 'concept', ...  }, 1: { name: 'label', value: 'idea', ... }, 2: { name: 'comment', value: 'interesting' } ]`
         * into this format:
         * `{ label: [ { value: 'concept', ... }, { value: 'idea',  ... } ], comment: [ { value: 'interesting' } ] }`
         * @returns {Object} An object having property names as keys and property values as values.
         * @deprecated from v2.0 Use, instead useClassView().getClassTagsByPropName computed property.
         */
        propertyValueByNames: function () {
            if (!this.hasPropertyValues) return {};

            const propertyValuesByNameCollection = {};

            this.$attrs.propertyValues.forEach((propertyValue) => {
                if (!propertyValue.name) return;

                propertyValuesByNameCollection[propertyValue.name] =
                    propertyValuesByNameCollection[propertyValue.name] || [];
                propertyValuesByNameCollection[propertyValue.name].push(
                    propertyValue
                );
            });

            return propertyValuesByNameCollection;
        },

        /**
         * Builds an array of names of properties values contained in the `propertyValues` attribute.
         * @returns {Array<String>} A collection of property values names.
         */
        propertyValuesNames: function () {
            if (!this.$attrs.propertyValues) return [];
            if (!this.$attrs.propertyValues.length) return [];

            return Object.keys(this.propertyValueByNames);
        },
    },
};
