import ApiSearch from '../api/search.js';

class AxiomsParser {
    /**
     * The current ontology id.
     * @type {string}
     */
    ontologyId = '';

    /**
     * The original axiom expression to be parsed.
     * @type {string}
     */
    axiomsString = '';

    /**
     * The string expression breakdown where the key
     * is the expression type (eg. c for class, k for keyword etc..)
     * and the value is the expression itself.
     * @type {Object}
     */
    explodedString = [];

    /**
     * The string formatted as valid HTML.
     * @type {string}
     */
    formattedString = '';

    /**
     * A collection of the classes ids contained in the axiom string.
     * @type {Array<String>}
     */
    c = [];

    /**
     * A collection of the object properties ids contained in the axiom string.
     * @type {Array<String>}
     */
    op = [];

    /**
     * A collection of the data properties ids contained in the axiom string.
     * @type {Array<String>}
     */
    dp = [];

    /**
     * A collection of the classes contained in the axiom string
     * where each element is an object having as key the primary id
     * and as value the property object.
     * @type {Array<Object>}
     */
    classesLabelsMap = {};

    /**
     * A collection of the obsolete classes contained in the axiom string
     * where each element is an object having as key the primary id
     * and as value the property object.
     * @type {Array<Object>}
     */
    notFoundClassesLabelMap = {};

    /**
     * A collection of the properties contained in the axiom string
     * where each element is an object having as key the primary id
     * and as value the property object.
     * @type {Array<Object>}
     */
    propertiesLabelsMap = {};

    /**
     * A collection of the classes ids contained in the axiom string
     * for which we haven't found any primary label.
     * @type {Array<String>}
     */
    notFoundClasses = [];

    /**
     * Sets the current ontology.
     * @param {String} ontologyId
     * @returns {AxiomsParser} The current instance for chaining.
     */
    setOntology = (ontologyId) => {
        this.ontologyId = ontologyId;
        return this;
    };

    /**
     * Sets the axioms string to be parsed.
     * @param {String} axiomsString
     * @returns {AxiomsParser} The current instance for chaining.
     */
    setAxiomsString = (axiomsString) => {
        this.axiomsString = axiomsString;
        return this;
    };

    /**
     * Explodes the given string and assigns it to the explodedString property.
     * @returns {AxiomsParser} The current instance for chaining.
     */
    collect = () => {
        const regex = /(c|op|dp|k|i|f|ext|n|q):([^ ^)]+)/gim;
        this.explodedString = this.axiomsString.match(regex).map((entry) => {
            const parts = entry.split(':');

            return { [parts[0]]: entry.replace(parts[0] + ':', '') };
        });

        return this;
    };

    /**
     * Filters the exploded string by a given token type
     * and populates the relative type property.
     * @param {String} type The token type
     * @see https://scibite.atlassian.net/wiki/spaces/CENtree/pages/160170006/Complex+axioms#Class-expression-syntax
     * @returns {AxiomsParser} The current instance for chaining.
     */
    filterByType = (type) => {
        if (!this.explodedString.length) return this;
        this.explodedString.map((entry) => {
            entry[type] && this[type].push(entry[type]);
        });

        return this;
    };

    /**
     * Uses filterByType to filter the string by the class token `c`.
     * @returns {AxiomsParser}
     */
    filterClasses = () => {
        return this.filterByType('c');
    };

    /**
     * Uses filterByType to filter the string by the class object property `op`.
     * @returns {AxiomsParser}
     */
    filterObjectProperties = () => {
        return this.filterByType('op');
    };

    /**
     * Uses filterByType to filter the string by the class data property `dp`.
     * @returns {AxiomsParser}
     */
    filterDataProperties = () => {
        return this.filterByType('dp');
    };

    /**
     * Retrieves the classes labels and builds `classesLabelsMap` collection.
     * @returns {Promise<AxiomsParser>}
     */
    mapClassesLabels = async () => {
        this.notFoundClasses = [...this.classes];

        const response = await ApiSearch.classesByPrimaryIds(
            this.ontologyId,
            this.classes
        );
        const classes = Object.entries(response.data);

        if (!classes.length) return this;

        classes.forEach((currentCLass) => {
            const primaryId = currentCLass[0];
            this.classesLabelsMap[primaryId] = currentCLass[1].primaryLabel;
            this.notFoundClasses = this.notFoundClasses.filter(
                (classPrimaryId) => classPrimaryId !== primaryId
            );
        });

        return this;
    };

    /**
     * Goes through the notFoundClasses array and builds `notFoundClassesLabelMap` collection.
     * @returns {Promise<AxiomsParser>}
     */
    mapObsoleteClassesLabels = async () => {
        return new Promise(async (resolve) => {
            if (!this.notFoundClasses.length) return resolve();

            const mapPromises = this.notFoundClasses.map(async (primaryId) => {
                let primaryLabel = 'no label';

                try {
                    const response = await ApiSearch.obsoleteClasses(
                        this.ontologyId,
                        primaryId,
                        1
                    );

                    if (response.data.length && response.data[0].primaryLabel) {
                        primaryLabel = response.data[0].primaryLabel;
                    }
                } catch (error) {
                    // Do nothing, just don't prevent resolving the promise.
                }

                this.notFoundClassesLabelMap[primaryId] = primaryLabel;
            });

            await Promise.all(mapPromises);
            resolve(this);
        });
    };

    /**
     * Retrieves the properties labels and builds `propertiesLabelsMap` collection.
     * @returns {Promise<AxiomsParser>}
     */
    mapPropertiesLabels = async () => {
        const response = await ApiSearch.propertiesByPrimaryIds(
            this.ontologyId,
            this.properties
        );
        const properties = Object.entries(response.data);
        if (!properties.length) return this;

        properties.forEach((property) => {
            const primaryId = property[0];
            this.propertiesLabelsMap[primaryId] = property[1].primaryLabel;
        });

        return this;
    };

    /**
     * Format the axiom string to its final form and assigns it to the `formattedString` property.
     * @returns {AxiomsParser}
     */
    formatAxiomString = () => {
        if (!this.explodedString.length) return this;

        this.formattedString = this.axiomsString;

        this.wrapStatements();
        this.replaceIdsWithLabels('classes');
        this.replaceIdsWithLabels('obsolete_classes');
        this.replaceIdsWithLabels('properties');

        const wrapper = document.createElement('div');
        wrapper.innerHTML = this.formattedString;

        for (let i = 0; i < wrapper.children.length; i++) {
            const child = wrapper.children.item(i);
            if (child.href && child.href.includes('obs::')) {
                child.classList.add('obsolete');
                child.removeAttribute('href');
                child.innerText = child.innerText.replace('obs::', '');
            }
        }

        this.formattedString = wrapper.innerHTML;

        return this;
    };

    /**
     * Wraps statements included in `formattedString` inside html tags depending on the statement type.
     * @todo Improve the regex to avoid using the `::` wrapper tweak.
     */
    wrapStatements = () => {
        this.explodedString.forEach((statement) => {
            const statementParts = Object.entries(statement)[0];
            const [key, value] = statementParts;
            const regex = new RegExp(`${key}:${value}`, 'g');

            if (key === 'c') {
                const protectedValue = value.replace('http', '::http::');

                this.formattedString = this.formattedString.replace(
                    regex,
                    `<a href='${protectedValue}' class='axiom--${key}'>${value}</a>`
                );
            } else {
                this.formattedString = this.formattedString.replace(
                    regex,
                    `<span class='axiom--${key}'>${value}</span>`
                );
            }
        });
    };

    /**
     * Replace properties and classes ids with the relative labels.
     * @param {('classes'|'obsolete_classes'|'properties')} labelType
     * @returns {void|undefined}
     */
    replaceIdsWithLabels = (labelType) => {
        let labelsMap;
        let prefix = '';

        switch (labelType) {
            case 'classes':
                labelsMap = this.classesLabelsMap;
                break;
            case 'obsolete_classes':
                labelsMap = this.notFoundClassesLabelMap;
                prefix = 'obs::';
                break;
            case 'properties':
                labelsMap = this.propertiesLabelsMap;
                break;
            default:
                return;
        }

        Object.entries(labelsMap).forEach((label) => {
            const [key, value] = label;
            const regex = new RegExp(`${key}`, 'g');
            this.formattedString = this.formattedString.replace(
                regex,
                prefix + value
            );
        });

        this.formattedString = this.formattedString.replace(
            /::http::/g,
            'http'
        );
    };

    //#region Getters
    /**
     * Gets the `c` property.
     * @returns {Array<String>}
     */
    get classes() {
        return this.c;
    }

    /**
     * Gets the a combination of object properties and data properties.
     * @returns {Array<String>}
     */
    get properties() {
        return [...this.objectProperties, ...this.dataProperties];
    }

    /**
     * Gets the the `dp` property.
     * @returns {Array<String>}
     */
    get dataProperties() {
        return this.dp;
    }

    /**
     * Gets the the `op` property.
     * @returns {Array}
     */
    get objectProperties() {
        return this.op;
    }
    //#endregion Getters

    /**
     * Class static entry point, performs all operations necessary for converting axiom expressions into
     * an HTML string.
     * @param {String} ontologyId The current ontology id.
     * @param {String} axiomsString The axiom string to be converted.
     * @returns {Promise<string>} A promise of the HTML string as a result of the conversion.
     */
    static parse = async (ontologyId, axiomsString) => {
        const parser = new AxiomsParser()
            .setOntology(ontologyId)
            .setAxiomsString(axiomsString)
            .collect()
            .filterClasses()
            .filterObjectProperties()
            .filterDataProperties();

        await parser.mapClassesLabels();
        await parser.mapObsoleteClassesLabels();
        await parser.mapPropertiesLabels();

        return parser.formatAxiomString().formattedString;
    };
}

export default AxiomsParser;
