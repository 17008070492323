<template>
    <div>
        <b-table
            v-if="history.length"
            striped
            :items="changes"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc="sortDesc"
            responsive="sm"
            stacked="md">
            <template v-slot:cell(showDetails)="row">
                <b-button
                    size="sm"
                    @click="getDiff(row)"
                    class="mr-2 border-0 p-0 btn-link-secondary btn-sm bg-transparent text-secondary">
                    {{ row.detailsShowing ? 'Hide' : 'Show' }} Edit
                    <font-awesome-icon
                        :icon="
                            row.detailsShowing ? 'chevron-up' : 'chevron-down'
                        " />
                </b-button>
            </template>

            <template v-slot:row-details="row">
                <b-card no-body>
                    <b-tabs card>
                        <b-tab title="Previous Edit" active lazy>
                            <b-table
                                v-if="
                                    comparedClassHistory.hasOwnProperty(
                                        row.item.editId
                                    )
                                "
                                :fields="
                                    comparedClassHistory[row.item.editId].fields
                                "
                                :items="
                                    comparedClassHistory[row.item.editId].edits
                                "
                                responsive="sm"
                                stacked="md"
                                striped />
                            <div class="not-found" v-else>
                                <h4
                                    class="text-secondary d-flex align-items-center justify-content-center mt-4">
                                    <font-awesome-icon
                                        icon="info-circle"
                                        size="2x" />
                                    <span class="pl-3">
                                        Sorry, no differences were found for
                                        this class.
                                    </span>
                                </h4>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </template>
        </b-table>

        <div class="not-found" v-else>
            <h4
                class="text-secondary d-flex align-items-center justify-content-center mt-4">
                <font-awesome-icon icon="info-circle" size="2x" />
                <span class="pl-3">
                    Sorry, no history could be found for this class.
                </span>
            </h4>
        </div>
    </div>
</template>

<script>
import ApiOntology from '@/api/ontology';

export default {
    name: 'ClassViewHistory',
    props: {
        history: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'showDetails',
                    label: 'Show Details',
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true,
                },
                {
                    key: 'user',
                    label: 'User',
                    sortable: true,
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true,
                    formatter: (date) => {
                        return this.$moment(date).format('LLL');
                    },
                },
            ],
            sortBy: 'date',
            sortDesc: false,
            comparedClassHistory: {},
        };
    },
    methods: {
        async getDiff(row) {
            // Toggles the accordion for the row
            row.toggleDetails(row);

            if (
                row.detailsShowing &&
                this.comparedClassHistory.hasOwnProperty(row.item.editId)
            ) {
                return;
            }

            const dif = await ApiOntology.compareClassHistory(
                row.item.ontologyId,
                row.item.editId
            );

            if (!dif.data) {
                return;
            }

            // Makes the changes interior table
            let table = {};
            table.fields = ['fieldName', 'oldValue', 'newValue'];
            table.edits = dif.data.changesArray.map((change) => {
                let rowColour = '';

                switch (change.operation.toLowerCase()) {
                    case 'add':
                        rowColour = 'success';
                        break;
                    case 'replace':
                        rowColour = 'primary';
                        break;
                    case 'remove':
                        rowColour = 'info';
                        break;
                    default:
                        rowColour = '';
                }

                return {
                    fieldName: change.fieldName,
                    newValue: change.value,
                    oldValue: change.oldValue,
                    _rowVariant: rowColour,
                };
            });

            /**
             * Is needed to tell Vue that it needs to watch this data property
             * https://vuejs.org/v2/guide/reactivity.html#For-Objects
             * */
            this.$set(this.comparedClassHistory, dif.data.editId, table);
        },
    },
    computed: {
        changes() {
            return this.history.map((change) => {
                const firstNonCommit = change.ontologyEditActionSet.find(
                    (edit) => edit.editAction.toLowerCase() !== 'commit'
                );

                return {
                    user: change.userLoginFullName,
                    // Finds the first action that is not a commit
                    action: firstNonCommit.editAction,
                    date: change.creationDate,
                    editId: change.id,
                    ontologyId: change.ontologyId,
                };
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'src/scss/variables.scss';

.btn-accord {
    background-color: transparent;
    color: $secondary;
    &:hover {
        background-color: transparent;
        color: $secondary;
    }
    &:active {
        background-color: transparent !important;
        color: $secondary !important;
    }
}
</style>
