<template>
    <span>
        <class-typeahead
            ref="typeahead"
            v-b-popover.hover="{
                placement: 'top',
                html: true,
                content: errorPopHtml,
                delay: { show: showDelay, hide: 0 },
            }"
            :class="{
                'all-ontologies': isAllOntos,
                invalid: isShowValidity && !isValid(selectedClass),
                'no-selection': isBlockSelection && !isSuggesterOn,
                'suggester-enabled': isSuggesterOn,
                'suggester-disabled': !isSuggesterOn,
            }"
            :placeholder="placeholder"
            :isHideApproximations="isHideApproximations"
            :approximate-title="approximateTitle(isSuggesterOn)"
            :matched-title="matchedTitle(isSuggesterOn)"
            :isAutofocus="false"
            :isGotoOnHit="false"
            :suggestion-min-chars="minChars"
            :suggestion-max-matches="suggestionMax"
            :fetchSuggestions="fetchTypeaheadList"
            :performAction="addTypeahead"
            @hit="
                (!isBlockSelection || isSuggesterOn) && onTypeaheadHit($event)
            "
            @change="onQueryChange"
            @focusout.native="onFieldBlur">
            <template v-if="relationshipType === 'mappings'" slot="prepend">
                <onto-filter-v2
                    filter-title="Select an ontology"
                    @selected:ontologies="filterMappingsRequest" />
            </template>
            <template slot="append" slot-scope="typeahead">
                <b-button
                    class="add-btn"
                    variant="info"
                    size="sm"
                    ref="addBtn"
                    @click="$refs.typeahead.onAction(typeahead.query)"
                    :id="buildTestClass('btn--addProperty')">
                    <font-awesome-icon icon="plus" class="align-middle" />
                </b-button>
            </template>

            <template slot="inset" slot-scope="typeahead">
                <info-icon
                    variant="secondary"
                    class="inset"
                    help-hint="This input is able handle lists pasted into it."
                    v-show="!typeahead.query.length && canPaste" />
                <b-button
                    class="clear-btn"
                    variant="link"
                    tabindex="-1"
                    v-show="typeahead.query.length"
                    @mousedown.prevent="$refs.typeahead.clear()">
                    &times;
                </b-button>
            </template>

            <template v-if="isSuggesterOn" slot="view-all">
                <b-button
                    class="view-all-btn rounded-0 border-0"
                    variant="outline-primary"
                    @mousedown.prevent="isFullRecommendations = true">
                    <small>View all recommendations</small>
                </b-button>
            </template>
        </class-typeahead>

        <b-modal
            v-if="isFullRecommendations"
            id="recommendations-modal"
            no-fade
            centered
            scrollable
            header-bg-variant="primary"
            header-text-variant="light"
            footer-class="border-0 pt-0"
            v-model="isFullRecommendations"
            :title="`${recCount} recommended ${$pluralize(
                placeholder.toLowerCase(),
                recCount
            )}`">
            <!-- Full list of recommendations -->
            <transition-group name="fade" class="fade-list" tag="div">
                <!-- Add button for recommended value -->
                <router-link
                    v-for="recommendation in syncedSuggestions(suggesterList)"
                    class="d-block fade-item"
                    :id="
                        recommendation.shortFormIDs.length
                            ? `${global_randomID}-modal-${recommendation.id}`
                            : false
                    "
                    :key="recommendation.primaryID"
                    :to="{
                        name: 'class',
                        params: {
                            ontologyID,
                            primaryID: recommendation.primaryID,
                        },
                    }">
                    <b-button
                        class="w-100 text-left d-flex"
                        variant="link"
                        @click.prevent.stop="onTypeaheadHit(recommendation)">
                        <span class="recommendation-label flex-fill">
                            <span class="term-label">{{
                                recommendation.primaryLabel
                            }}</span>
                            {{ termID(recommendation) }}
                        </span>
                        <font-awesome-icon
                            class="add-hint text-info"
                            icon="plus-circle"
                            size="lg" />
                    </b-button>
                </router-link>
            </transition-group>

            <!-- Close recommendations list -->
            <div slot="modal-footer" class="w-100 pt-3">
                <b-btn
                    class="float-right"
                    size="sm"
                    variant="light"
                    @click="isFullRecommendations = false">
                    Cancel
                </b-btn>
            </div>

            <!-- Hover-on class summary -->
            <b-popover
                v-for="(recommendation, index) in syncedSuggestions(
                    suggesterList
                )"
                v-if="recommendation.shortFormIDs.length"
                placement="bottom"
                triggers="hover"
                boundary="window"
                :title="recommendation.primaryID"
                :key="`recommendation-${index}`"
                :target="`${global_randomID}-modal-${recommendation.id}`"
                :delay="{ show: showDelay, hide: 0 }">
                <class-summary v-bind="recommendation" />
            </b-popover>
        </b-modal>
    </span>
</template>

<script>
import { find, intersectionBy, isEmpty, map, unionBy, without } from 'lodash';

import InfoIcon from '@/components/ui/InfoIcon';
import ClassTypeahead from '@/components/ui/ClassTypeahead';
import ClassSummary from '@/components/ui/ClassSummary';
import ApiSearch from '@/api/search';
import OntoFilterV2 from '@/components/ui/OntoFilterV2';

export default {
    name: 'RelationTypeahead',
    components: {
        ClassTypeahead,
        ClassSummary,
        InfoIcon,
        OntoFilterV2,
    },
    props: {
        ontologyID: {
            type: String,
            required: true,
        },

        term: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        canPaste: {
            type: Boolean,
            default: false,
        },

        // IDs for classes that should never be included in any list of suggestions
        blackListedPrimaryIDs: {
            type: Array,
            default: function () {
                return [];
            },
        },

        // Data for classes that should always be added to any list of suggestions
        additionalSuggestions: {
            type: Array,
            default: function () {
                return [];
            },
        },

        // Updated classes whose data should be replaced in the suggestions
        updatedSuggestions: {
            type: Array,
            default: function () {
                return [];
            },
        },

        // Searches for classes in all loaded ontologies except the current one.
        isInverseOntos: {
            type: Boolean,
            default: false,
        },

        // Searches for classes in all loaded ontologies without restriction.
        isAllOntos: {
            type: Boolean,
            default: false,
        },

        // Stops the non-matches list from showing
        isHideApproximations: {
            type: Boolean,
            default: false,
        },

        // Suggestions provided when the suggestor is off are read-only.
        isBlockSelection: {
            type: Boolean,
            default: false,
        },

        // Whether the suggester functionality should be available.
        isRecommendations: {
            type: Boolean,
            default: true,
        },

        // Whether the suggester should be on by default.
        isAutoSuggester: {
            type: Boolean,
            default: false,
        },

        // Automatically matches the query in the typeahead with any suggested class label.
        isAutoMatch: {
            type: Boolean,
            default: true,
        },

        // Name of the property for the relationship which new suggestions are being provided for.
        relationshipType: {
            type: String,
            default: '',
        },

        suggestionMax: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SUGGESTION_MAX),
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        invalidMsg: {
            type: String,
            default: 'Please select one of the suggested classes.',
        },

        // Test to determine if the typed in text is valid. Default is to expect any non-empty value or a selection.
        isValid: {
            type: Function,
            default: function () {
                return (
                    !!this.$refs.typeahead.query || !isEmpty(this.selectedClass)
                );
            },
        },

        matchedTitle: {
            type: Function,
            default: (isSuggesterOn) => 'MATCHED RELATIONSHIPS',
        },

        approximateTitle: {
            type: Function,
            default: (isSuggesterOn) => 'APPROXIMATE RELATIONSHIPS',
        },

        suggesterTooltip: {
            type: Function,
            default: (isSuggesterOn) => {
                return (isSuggesterOn ? 'Hide' : 'Show') + ' recommendations';
            },
        },
    },

    data() {
        return {
            // Chosen class entry in the list of suggestions.
            selectedClass: {},

            // Whether validation feedback for the current contents of the typeahead should be shown.
            isShowValidity: false,

            // Whether the suggester's entries should be shown.
            isSuggesterOn: false,

            // List of classes from the suggester service.
            suggesterList: [],

            // Number of effective recommendations, after syncing with data changes from outside.
            recCount: 0,

            // Flags if the full list of recommendations should be on display.
            isFullRecommendations: false,

            // Typeahead's lower character threshold for suggestions to be offered.
            minChars: 1,

            // Data for the last request required for its cancellation
            lastSuggesterCall: null,

            loading: false,

            // Used for checking if the typehead input has been pasted into
            hasPasted: false,

            // Used for storing the list of selected ontologies from the OntoFilterV2
            selectedOntologies: [],
        };
    },

    computed: {
        errorPopHtml: function () {
            let html = '';

            if (this.isShowValidity && !this.isValid(this.selectedClass)) {
                html = `<span class="text-danger">${this.invalidMsg}</span>`;
            }

            return html;
        },
    },

    watch: {
        // Updates any existing list of suggestions whenever the blacklist or additional/updated classes set changes.
        blackListedPrimaryIDs: function () {
            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
            this.recCount = this.syncedSuggestions(this.suggesterList).length;
        },
        additionalSuggestions: function () {
            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
        },
        updatedSuggestions: function () {
            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
            this.recCount = this.syncedSuggestions(this.suggesterList).length;
        },
    },

    mounted() {
        const typeahead = this.$refs.typeahead;

        this.bindPasteToInput();

        // Updates the selected class by default after the typeahead's query has changed.
        this.$watch('$refs.typeahead.matchedItems', (matched) => {
            // Multiple exact matches and at least one has the exact label.
            if (
                !this.isBlockSelection &&
                typeahead.isExactMatch &&
                matched &&
                matched.length
            ) {
                this.selectedClass = find(map(matched, 'data'), {
                    primaryLabel: this.$refs.typeahead.query,
                });

                // No exact matches => no default selection.
            } else {
                this.selectedClass = {};
            }
        });
    },

    methods: {
        onQueryChange(newQuery, oldQuery) {
            let sanitisedNew;
            let sanitisedOld;

            sanitisedNew = newQuery.replace(/\s{2,}/g, ' ').trim();
            sanitisedOld = oldQuery.replace(/\s{2,}/g, ' ').trim();

            if (sanitisedNew !== sanitisedOld) {
                this.isShowValidity = false;
            }
        },

        filterMappingsRequest(ontologies) {
            this.selectedOntologies = ontologies.map(
                (ontologies) => ontologies.ontologyUniqueID
            );

            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
        },

        /**
         * Makes the typeahead show suggestions without input only when the suggester is on.
         * @param {boolean} isOn - True if the suggester service is enabled.
         * @param {boolean} [isAutoFocus = true] - True if the field should be automatically focused.
         */
        onSuggesterChange(isOn, isAutoFocus = true) {
            this.isSuggesterOn = isOn;

            // Allows any number of chars to trigger the typeahead menu when the suggester is on.
            if (isOn) {
                this.minChars = 0;

                if (isAutoFocus) {
                    this.$refs.typeahead.$el.querySelector('input').focus();
                }
            } else {
                this.minChars = undefined;
            }

            // Refreshes the list of suggestions
            this.$nextTick(() => {
                this.$refs.typeahead && this.$refs.typeahead.checkFetch();
            });
        },

        onFieldBlur() {
            this.isShowValidity = this.$refs.typeahead.query.length;
        },

        /**
         * Proxy method for the typeahead component to fetch the list of suggestions for a given class query. If the suggester
         * is enabled, the list is drawn from an already loaded list. Also, the typeahead can be restricted to loaded ontologies
         * different to the current one or just across all loaded ontologies.
         * @param {string} classQuery - Class name or identifier typed into the breadcrumb's typeahead field.
         */
        fetchTypeaheadList(classQuery) {
            let ontologies = this.selectedOntologies;
            let fetched;

            if (this.isSuggesterOn) {
                fetched = Promise.resolve({
                    data: { elements: this.suggesterList },
                });
            } else {
                if (!this.selectedOntologies.length) {
                    if (this.isInverseOntos) {
                        ontologies = without(
                            this.$store.getters.ontoIDs,
                            this.ontologyID
                        );
                    } else if (!this.isAllOntos) {
                        ontologies = [this.ontologyID];
                    }
                }

                fetched = ApiSearch.suggestions({
                    query: classQuery,
                    ontologies,
                });
            }

            return fetched.then((response) => {
                response.data.elements = this.syncedSuggestions(
                    response.data.elements,
                    !this.isSuggesterOn
                );
                return response;
            });
        },

        /**
         * Updates the existing list of suggestions according to the state of the list items elsewhere. The blacklist is applied in all
         * cases while the additional classes are appended provided the suggestor is off since such classes might not
         * be related to the current child class at all.
         * @param {Object[]} suggestions - List of suggested items.
         * @param {boolean} [isSyncNew = false] - True if new classes must be added to the suggestions.
         */
        syncedSuggestions(suggestions, isSyncNew = false) {
            const blackList = this.blackListedPrimaryIDs;
            const addList = this.additionalSuggestions;
            const updateList = this.updatedSuggestions;
            let synced = suggestions;
            let union;

            // Tacks the addition classes onto whatever suggestions there are.
            // NOTE: new classes are added at the top of the suggestions to make sure they are available.
            if (isSyncNew) {
                synced = addList.concat(synced);
            }

            // Replaces any suggessted classes with the most up-to-date version.
            // NOTE: a union alone would add any updated classes not in the list as opposed to merging only the common classes.
            union = unionBy(updateList, synced, 'id');
            synced = intersectionBy(union, synced, 'id');

            // Only now that the list's composition is as expected can suggestions be blacklisted.
            synced = synced.filter((element) => {
                return blackList.indexOf(element.primaryID) === -1;
            });

            return synced;
        },

        /**
         * Proxy method for the typeahead component to serve the corresponding primaryID for a given class query and add it
         * to the existing list.
         * @param {string} classQuery - Class name or identifier typed into the breadcrumb's typeahead field.
         * @param {Object[]} [rawSuggestions=[]] - List of class datasets matched by the server.
         */
        addTypeahead(classQuery, rawSuggestions = []) {
            let newAddition = this.isAutoMatch ? this.selectedClass : {};

            this.isShowValidity = true;

            if (this.isValid(newAddition)) {
                // If the query doesn't coincide with any suggested entries, it falls back on a stub class synthesised.
                // NOTE: the ultimate effect is to add just the contents of the typeahead instead of a primaryID.
                if (isEmpty(newAddition) && classQuery.length) {
                    newAddition = {
                        primaryID: classQuery,
                        primaryLabel: classQuery,
                    };
                }

                this.$emit('add', newAddition);
                this.$refs.typeahead.clear();
            }

            this.$refs.typeahead.$el.querySelector('input').focus();
        },

        /**
         * Copies the selected class' representative text over to the typeahead field. This ensures that the field
         * is updated even if the current class is the same as the one selected. It then proceeds to trigger the class' addition.
         * @param {Object} hitClass - Data for the selected class.
         */
        onTypeaheadHit(hitClass) {
            this.$refs.typeahead.onAutocomplete(
                `${hitClass.primaryLabel} ${this.termID(hitClass)}`
            );
            this.selectedClass = hitClass;
            this.$emit('add', this.selectedClass);
            this.$refs.typeahead.clear();
        },

        /**
         * Finds the type ahead and binds a paste event to the input
         * Overrides the hanldeInput to check if has been pasted so input can be cleared after paste
         */
        bindPasteToInput() {
            const typeahead = this.$refs.typeahead;

            if (!typeahead || !this.canPaste) return;

            const input = typeahead.$el.querySelector('input.search-field');
            input.addEventListener('paste', this.onPaste);

            // Overrides the handleInput function in the vuetypeahead to check if the content has been pasted
            const oldhandleInput =
                this.$refs.typeahead.$refs.typeahead.handleInput;
            this.$refs.typeahead.$refs.typeahead.handleInput = (newValue) => {
                oldhandleInput.call(
                    this.$refs.typeahead.$refs.typeahead,
                    newValue
                );

                if (!this.hasPasted) return;

                this.$refs.typeahead.clear();
                this.hasPasted = false;
            };
        },

        /**
         * Takes in the paste event and formats the data.
         * Finally sends a event of pasted with the formatted content to be added
         * @param {Event} event - paste event
         */
        onPaste(event) {
            let paste = (event.clipboardData || window.clipboardData).getData(
                'text'
            );

            if (paste === '') return;

            paste = paste.split('\n');

            if (!paste.length) return;

            paste = paste.filter((word) => {
                word = word.trim();
                if (word.length !== 0) {
                    return word;
                }
            });

            this.$emit('pasted', paste);
            this.hasPasted = true;
        },
    },

    destroyed() {
        if (this.lastSuggesterCall) {
            this.lastSuggesterCall.cancel();
        }
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

::v-deep .search-field {
    min-width: 20rem;
    height: inherit;
    padding: 0.2em 1.5em 0.2em 0.4em;
    line-height: 1.2em;
    box-shadow: none !important;

    &:focus {
        border-color: rgba($info, 0.6);
    }

    .invalid & {
        border-color: rgba($danger, 0.6);
    }
}

.typeahead-wrapper {
    &.suggester-enabled ::v-deep .suggestion-entry {
        pointer-events: none;
    }

    &.suggester-enabled ::v-deep .list-group {
        &:before {
            color: #fff !important;
            background: lighten($primary, 10%) !important;
            border-bottom-color: rgba($primary, 0.4) !important;
        }
    }

    &.no-selection ::v-deep .list-group-item {
        pointer-events: none;
    }

    &:not(.all-ontologies) ::v-deep .ontology-btn {
        display: none;
    }

    &:focus-within .suggester-btn {
        border-color: rgba($info, 0.6) !important;
    }

    &.invalid .suggester-btn {
        border-color: rgba($danger, 0.6) !important;
    }

    .suggester-btn:not(.disabled):hover {
        color: $warning;
    }

    ::v-deep .list-group {
        z-index: $zindex-sticky;

        @media (max-width: 575px) {
            position: absolute !important;
        }
    }
}

.suggester-link {
    font-size: inherit;
    color: inherit;
    text-decoration: none !important;

    &:not(:hover) {
        color: $info;
    }
}

.inset {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5rem;
}

.clear-btn {
    position: absolute;
    top: 0;
    right: 1.8rem !important;
    padding: 0 0.5rem 0.18rem 0.4rem !important;
    font-size: 1.1rem !important;
    text-decoration: none !important;
    opacity: 0.6;

    &:not(:hover) {
        color: $text-muted;
    }
}

.view-all-btn:not(:hover) {
    color: $link-hover-color;
    background: $white;
}

::v-deep #recommendations-modal {
    top: 100px;
    height: 80%;

    .modal-body {
        min-height: 3.4rem;
    }

    .btn-link {
        text-decoration: none;

        .add-hint {
            opacity: 0;
            transition: opacity 0.2s ease-in;
        }

        &:not(:hover) .term-label {
            color: $secondary;
        }

        &:hover {
            background: $light;

            .add-hint {
                opacity: 0.5;
            }
        }
    }
}

.invalid .add-btn {
    border-color: $danger;
    background: $danger;
}

.fade-list {
    position: relative;

    .fade-item {
        width: 100%;
        transition: opacity 0.4s ease-out;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .fade-leave-active {
        .add-hint {
            opacity: 0;
        }
    }

    &:before {
        content: 'All recommendations have been added.';
        z-index: -1;
        position: absolute;
        top: 0.7rem;
        left: 0;
        right: 0;
        color: $text-muted;
        text-align: center;
        opacity: 0;
    }

    &:empty {
        &:before {
            z-index: auto;
            opacity: 1;
        }
    }
}

.loading-pulse {
    border-color: transparent !important;
    @include pulse-bg-animation;
}
@include pulse-bg-keyframes($info);
</style>
