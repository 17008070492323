<template>
    <div class="class-item mx-3 mb-2 mt-3">
        <!-- HEADING -->
        <dt class="item-heading text-center">
            <class-name
                ref="classWrapper"
                class="d-inline"
                :isNew="isNew"
                :isObsolete="isObsolete"
                :ontologyID="selectedData.sourceUniqueID"
                :primaryID="selectedData.primaryID"
                :initClass="selectedData"
                :isSummary="false"
                :test-id="0"
                :class="buildTestClass('class-name')">
                <!-- Label area -->
                <editable-text
                    :isSaving="isLabelSaving"
                    :isSuccess="isLabelSuccess"
                    :isError="isLabelError"
                    :text="primaryLabel"
                    :contentEditable="isEditable"
                    :isErrorMsg="true"
                    :isNormalise="true"
                    :isRequired="true"
                    :placeholder="'Class label...'"
                    @focusout.native="
                        onEditableBlur(
                            'primaryLabel',
                            $event.target.textContent,
                            false,
                            $event.target.textContent
                        )
                    "
                    @dblclick.native="$emit('text:dblclick')" />

                <!-- Buttons area -->
                <text-actions
                    slot="actions"
                    :copyTargetName="humanReadable('shortFormIDs')"
                    :copyTargetEl="
                        () =>
                            $refs.classWrapper &&
                            $refs.classWrapper.$refs.shortID[0]
                    "
                    :searchTargetName="humanReadable('primaryLabel')"
                    :searchTargetEl="
                        () =>
                            $refs.classWrapper &&
                            $refs.classWrapper.$refs.termName[0]
                    ">
                    <!-- Edit-specific buttonry -->
                    <template v-if="isEditable">
                        <font-awesome-icon
                            class="action-btn edit-btn add-btn"
                            icon="plus"
                            v-b-tooltip.hover="{
                                title: 'Add a new child to this class',
                                delay: { show: showDelay, hide: 0 },
                            }"
                            @click.stop.prevent="$emit('add')" />
                        <font-awesome-icon
                            class="action-btn edit-btn del-btn"
                            icon="trash-alt"
                            v-b-tooltip.hover="{
                                title: 'Set this class as obsolete',
                                delay: { show: showDelay, hide: 0 },
                            }"
                            @click.stop.prevent="onClassObsolete"
                            @mouseenter.stop="
                                $refs.classWrapper.$el.classList.add(
                                    'del-hovered'
                                )
                            "
                            @mouseleave.stop="
                                $refs.classWrapper.$el.classList.remove(
                                    'del-hovered'
                                )
                            " />
                        <font-awesome-icon
                            class="action-btn edit-btn termite-btn"
                            :class="{ active: isIgnoredByTermite }"
                            icon="remove-format"
                            v-b-tooltip.hover="{
                                title: isIgnoredByTermite
                                    ? 'Set this class discoverable by termite'
                                    : 'Set this class to be ignored by termite',
                                delay: { show: showDelay, hide: 0 },
                            }"
                            @click.stop.prevent="onSetIgnoreByTermite" />
                    </template>
                    <template v-slot:append v-if="!isEditable">
                        <font-awesome-icon
                            class="action-btn edit-btn termite-btn outside-edit"
                            :class="{ active: isIgnoredByTermite }"
                            icon="remove-format"
                            v-b-tooltip.hover="{
                                title: isIgnoredByTermite
                                    ? 'This class is ignored by termite'
                                    : 'This class is discoverable by termite',
                                delay: { show: showDelay, hide: 0 },
                            }" />
                    </template>
                </text-actions>
            </class-name>
        </dt>

        <!-- PRIMARY ID -->
        <class-summary
            ref="primaryID"
            class="primary-container mb-3"
            :primaryID="selectedData.primaryID"
            :serialPropNames="['primaryID']"
            :propLengthMax="[0]"
            :highlightProps="['primaryID']"
            :isShowBadges="false"
            :test-id="0">
            <text-actions
                class="ml-1"
                :copyTargetName="humanReadable('primaryID')"
                :copyTargetEl="
                    () => $refs.primaryID && $refs.primaryID.$refs.propValue[0]
                "
                :url="selectedData.primaryID"
                :searchTargetName="humanReadable('primaryID')"
                :searchTargetEl="
                    () => $refs.primaryID && $refs.primaryID.$refs.propValue[0]
                " />
        </class-summary>

        <!-- TABS -->
        <b-tabs v-model="currTabIndex" content-class="pt-3">
            <b-tab
                v-for="(classTab, tabIndex) in classTabs"
                title-link-class="property-tab py-1"
                :title-item-class="buildTestClass(classTab.title.toLowerCase())"
                :key="tabIndex"
                :lazy="classTabs[tabIndex].lazy">
                <template slot="title">
                    <small :class="`tab-title ${classTab.title}`">
                        {{ upperFirst(classTab.title) }}
                    </small>

                    <template v-if="isEditable && classTab.properties">
                        <!-- Edit counts -->
                        <span
                            class="class-count-badge"
                            :id="`count-badge-${tabIndex}`"
                            :class="{
                                'd-none':
                                    !hasError(classTab.properties).length &&
                                    !hasEdit(classTab.properties).length,
                            }">
                            <b-badge
                                :class="{
                                    'bg-danger': hasError(classTab.properties)
                                        .length,
                                    'bg-info': hasEdit(classTab.properties)
                                        .length,
                                }"
                                pill>
                                {{
                                    hasError(classTab.properties).length ||
                                    hasEdit(classTab.properties).length
                                }}
                            </b-badge>
                        </span>

                        <!-- Edit summary -->
                        <b-popover
                            placement="bottom"
                            triggers="hover"
                            :target="`count-badge-${tabIndex}`"
                            :delay="{ show: showDelay, hide: 0 }">
                            <template
                                v-if="hasError(classTab.properties).length">
                                <div
                                    slot="title"
                                    class="text-danger text-center">
                                    Properties with errors
                                </div>
                                <div
                                    v-for="propName in hasError(
                                        classTab.properties
                                    )"
                                    :key="'haserror-' + propName">
                                    <strong>{{
                                        upperFirst(humanReadable(propName))
                                    }}</strong
                                    >:
                                    {{ addFullStop(editState[propName].error) }}
                                </div>
                            </template>
                            <template
                                v-else-if="hasEdit(classTab.properties).length">
                                <div slot="title" class="text-info text-center">
                                    Properties pending save
                                </div>
                                {{
                                    upperFirst(
                                        humanReadable(
                                            hasEdit(classTab.properties)
                                        ).join(', ')
                                    )
                                }}.
                            </template>
                        </b-popover>
                    </template>
                </template>

                <class-view-history
                    v-if="editHistory && isChangesTab(tabIndex)"
                    :history="editHistory" />

                <!-- PROPERTY TABS -->
                <template v-if="isPropertyTab(tabIndex)">
                    <template v-if="isEditMode">
                        <input
                            type="text"
                            class="form-control properties-filter mb-3"
                            :class="
                                buildTestClass('properties-filter--annotations')
                            "
                            placeholder="Start typing a property name"
                            v-if="tabIndex === 0"
                            v-model="annotationPropertiesFilter" />

                        <input
                            type="text"
                            class="form-control properties-filter mb-3"
                            :class="
                                buildTestClass('properties-filter--relations')
                            "
                            placeholder="Start typing a property name"
                            v-if="tabIndex === 1"
                            v-model="relationalPropertiesFilter" />
                    </template>

                    <!-- The class has been made obsolete -->
                    <div v-show="isObsolete" class="empty-hint text-center">
                        <h4 class="text-muted">Obsolete class</h4>
                        <p class="m-0">
                            The properties of this class will only be shown
                            during obsolete search.
                        </p>
                    </div>

                    <!-- All properties are blank -->
                    <div
                        v-if="!isEditMode && isEmptyTab(tabIndex)"
                        v-show="!isObsolete"
                        class="empty-hint text-center">
                        <h4
                            class="text-muted"
                            :class="
                                buildTestClass(
                                    `no-${$pluralize(
                                        classTabs[tabIndex].title
                                    ).toLowerCase()}-found`
                                )
                            ">
                            No
                            {{
                                $pluralize(
                                    classTabs[tabIndex].title
                                ).toLowerCase()
                            }}
                            found
                        </h4>

                        <tree-edit-button
                            test-id="0"
                            :ontologyID="selectedData.sourceUniqueID"
                            @click.native="$emit('text:dblclick')" />
                    </div>

                    <!-- Properties -->
                    <template v-else>
                        <add-property-button
                            v-if="showAddPropertyButton(tabIndex)"
                            :property-type="
                                tabIndex === 0 ? 'annotation' : 'relational'
                            " />
                        <transition-group
                            v-show="!isObsolete"
                            name="class-properties"
                            tag="dd"
                            class="mb-0">
                            <template v-if="isSchemaVersion2 && tabIndex === 0">
                                <template
                                    v-for="(
                                        properties, propertyName
                                    ) in classProperties">
                                    <property-list-v2
                                        v-if="
                                            showPropertyList(
                                                tabIndex,
                                                propertyName,
                                                properties
                                            )
                                        "
                                        :property="properties"
                                        :key="propertyName"
                                        :testId="propertyName"
                                        :hasRelationships="
                                            hasRelationships(propertyName)
                                        "
                                        :property-name="propertyName"
                                        :isEditable="isEditable"
                                        :propName="propertyName"
                                        @dblclick.native="
                                            $emit('text:dblclick')
                                        "
                                        @editable:blur="onEditableBlur"
                                        @class:resolved="onClassResolved"
                                        @class:jump="
                                            nodeSelectByID($event, 'id')
                                        " />
                                </template>
                            </template>
                            <template v-else>
                                <template
                                    v-for="(
                                        propertyValue, propName
                                    ) in relationalProperties">
                                    <property-list-v1
                                        v-if="
                                            showPropertyListV1(
                                                tabIndex,
                                                propName
                                            )
                                        "
                                        :key="propName"
                                        :testId="propName"
                                        :hasRelationships="true"
                                        :propValue="propertyValue"
                                        :isEditable="isEditable"
                                        :propName="propName"
                                        :propertyValuesByName="
                                            propertyAnnotations
                                        "
                                        @dblclick.native="
                                            $emit('text:dblclick')
                                        "
                                        @editable:blur="onEditableBlur"
                                        @class:resolved="onClassResolved"
                                        @class:jump="
                                            nodeSelectByID($event, 'id')
                                        " />
                                </template>
                            </template>
                        </transition-group>
                    </template>

                    <template v-if="tabIndex === 1 && !isEditMode">
                        <template v-if="parsedAnonymousSuperClasses.length">
                            <!-- @todo Turn this into a component and use it for all class properties headers -->
                            <div
                                class="stacked-heading d-flex pt-1 px-2 position-relative">
                                <strong class="stacked-name mr-auto"
                                    >Anonymous Super Classes</strong
                                >
                            </div>
                            <dd
                                v-for="(
                                    parsedAxiom, index
                                ) in parsedAnonymousSuperClasses"
                                :key="index"
                                class="axioms-container mb-4"
                                :class="
                                    buildTestClass(
                                        'axioms--anonymous-super-classes'
                                    )
                                ">
                                <div
                                    class="axioms-expression"
                                    v-html="parsedAxiom"></div>
                            </dd>
                        </template>

                        <template
                            v-if="parsedAnonymousEquivalentClasses.length">
                            <!-- @todo Turn this into a component and use it for all class properties headers -->
                            <div
                                class="stacked-heading d-flex pt-1 px-2 position-relative">
                                <strong class="stacked-name mr-auto"
                                    >Anonymous Equivalent Classes</strong
                                >
                            </div>
                            <dd
                                v-for="(
                                    parsedAxiom, index
                                ) in parsedAnonymousEquivalentClasses"
                                :key="index"
                                class="axioms-container"
                                :class="
                                    buildTestClass(
                                        'axioms--anonymous-equivalent-classes'
                                    )
                                ">
                                <div
                                    class="axioms-expression"
                                    v-html="parsedAxiom"></div>
                            </dd>
                        </template>
                        <template v-if="instances.length">
                            <PropertyListV1
                                :prop-value="instances"
                                prop-name="instances"
                                :is-instance="true" />

                            <div v-if="instances.length < totalInstances">
                                <b-button
                                    class="btn-instance-load-more"
                                    @click="loadInstances(instances.length, 10)"
                                    >Load More</b-button
                                >
                            </div>
                        </template>
                    </template>
                </template>

                <!-- OTHER TABS -->
                <class-graph
                    v-else-if="
                        graphWidth && graphHeight && !isChangesTab(tabIndex)
                    "
                    :class="graphPaddingClass"
                    :width="graphWidth"
                    :height="graphHeight"
                    :classData="selectedData"
                    :relationshipProps="flatRelationshipProps"
                    :annotationProps="annotationProps"
                    :resolvedClasses="classCache"
                    :newClasses="newClasses"
                    :isVisible="isGraphVisible"
                    :isEditMode="isEditMode"
                    @graph:select="nodeSelectByID($event, 'primaryID')"
                    @graph:reset="resetCache"
                    @graph:remove="removeCachedClass"
                    @graph:rootPath="addPathCache" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import _ from 'lodash';
import ApiOntology from '@/api/ontology.js';
import ClassName from '@/components/ui/ClassName';
import ClassViewHistory from '@/components/ui/ClassViewHistory';
import EditableText from '@/components/ui/EditableText';
import TextActions from '@/components/ui/TextActions';
import ClassSummary from '@/components/ui/ClassSummary';
import TreeEditButton from '@/components/ui/TreeEditButton';
import PropertyListV2 from '@/components/ui/PropertyListV2';
import PropertyListV1 from '@/components/ui/PropertyListV1';
import AddPropertyButton from '@/components/ui/ClassItem/AddPropertyButton';

import ClassGraph from '@/components/ui/ClassGraph';
import EditMixin from '@/mixins/EditMixin';
import AxiomsParser from '../../utils/AxiomsParser';
import ClientDebugger from '../../utils/ClientDebugger';
import PropertyValuesMixin from '../../mixins/PropertyValuesMixin';
import { ANNOTATION_PROPS, CLASS_TABS, RELATIONSHIP_PROPS } from '@/config';
import {
    useClassTree,
    useClassView,
    useEdits,
    useObservers,
    useOntology,
    usePropertyList,
    usePropertyValues,
} from '@/compositions';
import { propValuesDifference } from '@/utils';
import PropertyValue from '@/components/ui/PropertyValue.vue';
import InstanceList from '@/components/ui/SearchList/InstanceList.vue';
import { getInstancesForClass } from '@/api-v2';

export default {
    name: 'ClassItem',
    components: {
        InstanceList,
        PropertyValue,
        PropertyListV2,
        ClassName,
        EditableText,
        TextActions,
        ClassSummary,
        TreeEditButton,
        PropertyListV1,
        ClassGraph,
        ClassViewHistory,
        AddPropertyButton,
    },
    inheritAttrs: false,
    props: {
        newClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        /**
         * (Former $attrs) Current ontology data or current class data or undefined and probably boolean in some cases.
         * @type {definitions["OntologyMetadataEntry"]|ClassDataExtended|undefined}
         * @deprecated from v2.0 use useClassView().getOntologyData and useClassView().getClassData instead
         */
        selectedData: {
            type: Object,
            default: useClassView().getClassData.value,
        },

        changedClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        obsoleteClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        // Maximum character length for truncated property values
        propLengthMax: {
            type: Number,
            default: 999999,
        },

        isEditMode: {
            type: Boolean,
            default: false,
        },

        // True if tree data has been cleared before change
        isTreeUnload: {
            type: Boolean,
            default: true,
        },

        noRelBadgeProps: {
            type: Array,
            default: function () {
                return ['superClasses'];
            },
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        annotationWrapper: {
            type: String,
            default: process.env.VUE_APP_ANNOTATION_WRAPPER,
        },

        relationshipWrapper: {
            type: String,
            default: process.env.VUE_APP_RELATIONAL_WRAPPER,
        },

        stdAnnotationProps: {
            type: Array,
            default: function () {
                return ANNOTATION_PROPS;
            },
        },

        findClass: {
            type: Function,
            default: function () {
                return (primaryID) => {};
            },
        },

        nodeSelectByID: {
            type: Function,
            default: function () {
                return (classID, isSelect) => {};
            },
        },

        getMergedPaths: {
            type: Function,
            default: function () {
                return () => [];
            },
        },

        selectionMounted: {
            type: Function,
            default: function () {
                return () => true;
            },
        },

        setClassObsolete: {
            type: Function,
            default: function () {
                return (classID) => {};
            },
        },
    },

    data() {
        return {
            primaryLabel: this.selectedData.primaryLabel,
            // Ordering index of the currently open tab
            currTabIndex: 0,

            // Map of class data for primary IDs already resolved
            classCache: {},

            // Resize-aware dimensions for the class graph area
            graphWidth: 0,
            graphHeight: 0,

            // Resize-aware padding for the graph area to offset any fixed horizontal scrollbar
            graphPaddingClass: 'mb-2',

            editHistory: [],
            instances: [],

            annotationPropertiesFilter: '',
            relationalPropertiesFilter: '',
            parsedAxioms: {
                anonymousEquivalentClasses: [],
                anonymousSuperClasses: [],
            },
            classTabs: CLASS_TABS,
            totalInstances: 0,
        };
    },

    computed: {
        /**
         * The new annotation property array.
         * @return {boolean}
         */
        isIgnoredByTermite: function () {
            const propertyBlackList = usePropertyList().propertyBlackList;
            const isIgnored = propertyBlackList.value.filter(
                (property) =>
                    property.value.toLowerCase().trim() === 'true' &&
                    (property.iri === 'http://scibite.com/skos/config#ignore' ||
                        property.iri ===
                            'http://ontology.scibite.com/ontology/termite/ignore')
            );
            return !!isIgnored.length;
        },

        classProperties: function () {
            const propertyList = this.isEditMode
                ? usePropertyList().getFilteredAnnotations.value
                : usePropertyList().getPropertyList.value;

            if (!this.annotationPropertiesFilter) return propertyList;

            const propertyListFiltered = {};

            Object.entries(propertyList).forEach(
                ([propertyGroupName, properties]) => {
                    if (
                        propertyGroupName.indexOf(
                            this.annotationPropertiesFilter
                        ) === -1
                    )
                        return;
                    propertyListFiltered[propertyGroupName] = properties;
                }
            );

            return propertyListFiltered;
        },

        relationalProperties: function () {
            return useClassView().getRelationalProperties.value;
        },

        mustShowRelationalProps: function () {
            return useClassView().getMustShowRelationalProperties.value;
        },

        /*
        allProperties: function () {
            const relationalProperties =
                useOntology().getDefaultRelationalProperties.value || [];
            const annotationProperties =
                useOntology().currentAnnotationProperties.value || [];

            let labelProperties;

            const annotationPropertiesOrdered = annotationProperties.filter(
                (prop) => {
                    if (prop.name !== 'labels') return true;

                    labelProperties = prop;

                    return false;
                }
            );

            if (labelProperties) {
                annotationPropertiesOrdered.unshift(labelProperties);
            }

            return [...annotationPropertiesOrdered, ...relationalProperties];
        },
        */
        /**
         * Request and other info about each property edited in the current class.
         */
        editState: function () {
            return this.selectedData.editState || {};
        },

        /**
         * Flattened names for standard and custom annotation properties. Initialises annotations tab to
         * all annotation properties if none defined.
         * NOTE: the order of the array determines the rendering order.
         * PERFORMANCE TEST: fast
         */
        annotationProps: function () {
            const customProps = this.flattenProps(
                this.annotationWrapper,
                this.selectedData
            );

            const props = this.stdAnnotationProps.concat(customProps);
            const annoTab = _.find(this.classTabs, { title: 'annotations' });

            if (annoTab && annoTab.properties && !annoTab.properties.length) {
                annoTab.properties = props;
            }

            return [...props, ...this.propertyValuesNames];
        },

        /**
         * Collects and returns all axiom strings from the anonymousSuperClasses property.
         * @returns {Array} The axioms string collection.
         */
        anonymousSuperClasses: function () {
            return this.getAxioms('anonymousSuperClasses');
        },

        /**
         * Collects and returns all axiom strings from the anonymousEquivalentClasses property.
         * @returns {Array} The axioms string collection.
         */
        anonymousEquivalentClasses: function () {
            return this.getAxioms('anonymousEquivalentClasses');
        },

        /**
         * A shortcut to parsedAxioms['anonymousSuperClasses'].
         * @returns {Array} The anonymousSuperClasses string collection.
         */
        parsedAnonymousSuperClasses: function () {
            return this.parsedAxioms['anonymousSuperClasses'];
        },

        /**
         * A shortcut to parsedAxioms['anonymousEquivalentClasses'].
         * @returns {Array} The anonymousEquivalentClasses string collection.
         */
        parsedAnonymousEquivalentClasses: function () {
            return this.parsedAxioms['anonymousEquivalentClasses'];
        },

        /**
         * Flattened names for standard and custom relational properties. Initialises relationships tab to
         * all relationship properties if none defined.
         * NOTE: the order of the array determines the rendering order.
         */
        flatRelationshipProps: function () {
            const customProps = this.flattenProps(
                this.relationshipWrapper,
                this.selectedData
            );
            const props = this.relationshipProps.concat(customProps);
            const relsTab = _.find(this.classTabs, { title: 'relationships' });

            if (relsTab && relsTab.properties && !relsTab.properties.length) {
                relsTab.properties = props;
            }

            return props;
        },

        experimentalEnabled: function () {
            return this.$store.getters.experimental;
        },

        isSchemaVersion2: function () {
            return useClassView().whichSchemaVersion.value >= 2;
        },

        /**
         * Determines the properties that should be rendered as a vertical list. Empty properties are
         * only rendered if they are about to be edited.
         */
        classPropNames: function () {
            const props = this.sortedAnnotationProps.concat(
                this.sortedRelationalProps
            );
            const experimentalEnabled = this.$store.getters.experimental;

            if (this.isEditable) {
                return useClassView().whichSchemaVersion.value >= 2
                    ? props.concat(this.propertyValuesNames)
                    : props;
            } else {
                return props.filter((propName) => {
                    const propValue = this.getPropValue(
                        propName,
                        this.selectedData
                    );
                    const propValueAnnotations = experimentalEnabled
                        ? this.getPropValueAnnotations(propName)
                        : undefined;

                    return (
                        (propValue && propValue.length) || propValueAnnotations
                    );
                });
            }
        },

        /**
         * Computes an array that contains both custom and default relational props.
         */
        allRelationalProps: function () {
            const customProps = this.flattenProps(
                this.relationshipWrapper,
                this.selectedData
            );
            return this.relationshipProps.concat(customProps);
        },

        /**
         * Tests if a given class is brand new.
         * NOTE: Right after inserting the new node in the tree, its label is set.
         */
        isNew: function () {
            return (
                _.findIndex(this.newClasses, {
                    primaryID: this.selectedData.primaryID,
                }) !== -1
            );
        },

        /**
         * Tests if a given class has been made obsolete.
         */
        isObsolete: function () {
            return (
                _.findIndex(this.obsoleteClasses, {
                    primaryID: this.selectedData.primaryID,
                }) !== -1
            );
        },

        /**
         * Indicates when the item's properties can be edited. Obsolete classes will be treated as committed
         * and removed.
         */
        isEditable: function () {
            return this.isEditMode && !this.isObsolete;
        },

        isLabelSaving: function () {
            return (
                this.isEditable &&
                this.editState['primaryLabel'] &&
                this.editState['primaryLabel'].isSaving === true
            );
        },

        isLabelSuccess: function () {
            const editState = this.isEditable && this.editState['primaryLabel'];
            return (
                editState && !editState.error && editState.isSaving === false
            );
        },

        isLabelError: function () {
            const editState = this.isEditable && this.editState['primaryLabel'];
            return editState && !editState.isSaving && editState.error;
        },

        isGraphVisible: function () {
            return this.classTabs[this.currTabIndex].title === 'graph';
        },

        /**
         * Sorts the relational properties putting those with content on top and filtering by typed property name search.
         * @returns {Array} Sorted and filtered relational properties.
         */
        sortedRelationalProps: function () {
            return this.sortedProps(
                this.relationalPropertiesFilter,
                this.flatRelationshipProps
            );
        },

        /**
         * Sorts the annotation properties putting those with content on top and filtering by typed property name search.
         * @returns {Array} Sorted and filtered annotation properties.
         */
        sortedAnnotationProps: function () {
            return this.sortedProps(
                this.annotationPropertiesFilter,
                this.annotationProps
            );
        },
    },

    watch: {
        currTabIndex(newTab) {
            if (newTab === 2) {
                this.resolveRelationalClasses();
            }
        },

        // Resets scroll on class change except for the graph
        // NOTE: Scroll is assummed to be set on the parent container
        // TODO: See edit state for why the findClassCached is called here only on edit mode.
        'selectedData.id'(newID, prevID) {
            this.$nextTick(() => {
                this.addPropertyValuesToAnnotationsTab();

                if (this.isEditMode) {
                    this.findClassCached(this.selectedData.primaryID);
                } else {
                    this.loadAxioms();
                }
                if (this.classTabs[this.currTabIndex].title !== 'graph') {
                    this.$el.parentElement.scrollTop = 0;
                    window.getSelection().removeAllRanges();
                }
            });

            // When a new class is selected; query the history
            this.getClassHistory();
        },

        // Initialises resolved primary IDs in the cache precisely after all information, including edit state,
        // is available. Especially when the information is served asynchronously such as a page loading directly.
        // NOTE: this is especially useful when trying to infer locality from availability of edit state.
        // TODO: limited to outside edit mode because it breaks the ability to strip a class from all its relationships and make it a root.
        editState: {
            handler() {
                if (!this.isEditMode) {
                    this.$nextTick(() => {
                        Object.keys(this.classCache).forEach((primaryID) =>
                            this.findClassCached(primaryID)
                        );
                        this.findClassCached(this.selectedData.primaryID);
                    });
                }
            },
            immediate: true,
        },

        // Updates the class cache every time the path-from-root tree changes.
        // NOTE: classes may occur in different paths. This tries to cater for those cases.
        isTreeUnload(isUnloadedNow, isUnloadedBefore) {
            if (isUnloadedBefore && !isUnloadedNow) {
                this.$nextTick(() => {
                    Object.keys(this.classCache).forEach((primaryID) => {
                        this.findClassCached(primaryID);
                    });
                });
            }
        },

        isGraphVisible(isGraph) {
            if (isGraph) {
                this.$nextTick(() => {
                    this.updateGraphDims();
                });
            }
        },

        classCache(classCache) {
            useClassView().setClassCache(classCache);
        },
    },
    async mounted() {
        await this.loadInstances(0, 10);

        this.$set(
            this.classCache,
            useClassView().getClassData.value.primaryID,
            useClassView().getClassData.value
        );

        useObservers().registerObserver('PRIMARY_LABEL_UPDATED', {
            observer: 'UPDATE_PRIMARY_LABEL_IN_CLASS_ITEM',
            args: { classItemData: this },
        });

        this.addPropertyValuesToAnnotationsTab();

        this.updateGraphDims();

        // TODO: When horizontal scroll bar is on, the right margin is lost
        this.$eventBus.$on('window:resize', this.updateGraphDims);

        // Loads the history when class is loaded
        this.getClassHistory();

        if (!this.isEditMode) {
            this.loadAxioms();
        }
    },

    async updated() {
        if (useEdits().getIsRecoveryMode()) {
            await useEdits().countClassChanges();
        }
    },

    methods: {
        loadInstances: async function (from, size) {
            if (
                this.newClasses.some((newClass) => {
                    return newClass.id === this.selectedData.id;
                })
            )
                return;

            const instanceData = await getInstancesForClass(
                {
                    ontologyId: this.selectedData.sourceUniqueID,
                    classId: this.selectedData.id,
                },
                {
                    from: from,
                    size: size,
                }
            );

            if (instanceData && instanceData.elements.length) {
                this.totalInstances = instanceData.total;
                for (const instance of instanceData.elements) {
                    this.instances.push({
                        primaryLabel: instance.primaryLabel,
                        id: instance.id,
                        primaryID: instance.primaryID,
                    });
                }
            }
        },

        resolveRelationalClasses: function () {
            Object.keys(this.relationalProperties).forEach((key) => {
                if (this.relationalProperties[key].length) {
                    this.relationalProperties[key].forEach((primId) => {
                        if (!this.classCache[primId]) {
                            const val =
                                useClassTree().searchClassInTree(primId);
                            if (val) this.$set(this.classCache, primId, val);
                        }
                    });
                }
            });
        },

        /**
         * Adds properties belonging to the `propertyValues` attribute to the properties to show in the
         * annotations tab in order to display them alongside other properties.
         */
        addPropertyValuesToAnnotationsTab: function () {
            /**
             * Properties to show in the annotation tab.
             * @type Array<String>
             */
            const propertiesInAnnotationsTab = this.classTabs[0].properties;

            this.propertyValuesNames.forEach((propertyValue) => {
                propertiesInAnnotationsTab.push(propertyValue);
            });
        },

        /**
         * Checks if a property group is empty or has empty values.
         * @param classPropName
         * @param properties
         * @return {boolean}
         */
        isPropertyEmpty: function (classPropName, properties) {
            const propertiesEntries = Object.values(properties);

            if (propertiesEntries.length > 1) return false;
            if (!propertiesEntries[0] || propertiesEntries[0].length > 1)
                return false;
            if (
                !propertiesEntries[0][0] ||
                propertiesEntries[0][0].value === ''
            )
                return true;
        },

        /**
         * Determines if the "Add property" button should be displayed.
         * @param {number} tabIndex
         * @return {boolean}
         */
        showAddPropertyButton: function (tabIndex) {
            if (tabIndex > 1 || !this.isEditMode) return false;

            return true;

            //TODO Fix add annotation property button by allowing multiple custom relational values shown and counted in the initial hide calculation
            // if (tabIndex === 0) {
            //     const annotationProperties = useOntology().currentAnnotationProperties.value;
            //     const maxAnnotationPropertiesThreshold = usePropertyList().getMaxPropertiesToShow('annotation');
            //     return annotationProperties.length > maxAnnotationPropertiesThreshold;
            // }
            //
            // if (tabIndex === 1) {
            //     const relationalProperties = useClassView().getRelationalProperties.value;
            //     const maxRelationalPropertiesThreshold = usePropertyList().getMaxPropertiesToShow('relational');
            //     return Object.keys(relationalProperties).length > maxRelationalPropertiesThreshold;
            // }

            // return true;
        },

        /**
         * Determines if the property list should be displayed or hidden.
         * @param {String} tabIndex The tab name
         * @param {String} classPropName the property to display or hide.
         * @returns {boolean}
         */
        showPropertyList: function (tabIndex, classPropName, properties) {
            const propertiesEntries = Object.values(properties);

            if (!this.isEditMode && propertiesEntries.length === 0)
                return false;
            if (
                !this.isEditMode &&
                this.isPropertyEmpty(classPropName, properties)
            )
                return false;
            if (
                useClassView().whichSchemaVersion.value === 1 &&
                classPropName === 'labels'
            )
                return false;

            return true;
        },

        showPropertyListV1: function (tabIndex, classPropName) {
            const relationalProperties =
                useClassView().getRelationalProperties.value;

            const maxRelationalPropertiesThreshold =
                usePropertyList().getMaxPropertiesToShow('relational');
            if (
                this.isEditMode &&
                Object.keys(relationalProperties).length <=
                    maxRelationalPropertiesThreshold
            )
                return true;
            if (
                this.isEditMode &&
                this.mustShowRelationalProps.includes(classPropName)
            )
                return true;

            return (
                relationalProperties[classPropName] &&
                relationalProperties[classPropName].length > 0
            );
        },

        /**
         * Collects and returns all axiom strings for the requested axiom type.
         * @param {('anonymousSuperClasses'|'anonymousEquivalentClasses')} type
         * @returns {Array<string>}
         */
        getAxioms: function (type) {
            if (!this.selectedData[type] || !this.selectedData[type].length)
                return;

            const axioms = this.selectedData[type];
            const classExpressions = [];

            axioms.forEach((axiom) => {
                if (axiom.classExpression) {
                    classExpressions.push(axiom.classExpression);
                }
            });

            return classExpressions;
        },

        /**
         * Resets current parsed axioms collections.
         */
        resetAxioms() {
            this.parsedAxioms.anonymousEquivalentClasses = [];
            this.parsedAxioms.anonymousSuperClasses = [];
        },

        /**
         * Loops through anonymousEquivalentClasses and anonymousSuperClasses
         * builds the parsedAxioms collection of formatted class expressions.
         * @returns {Promise<undefined>}
         */
        async loadAxioms() {
            this.resetAxioms();
            await this.loadAxiomsByType('anonymousEquivalentClasses');
            return this.loadAxiomsByType('anonymousSuperClasses');
        },

        /**
         * Loops through a complex axiom type and builds
         * builds the parsedAxioms collection of formatted class expressions.
         * @param {('anonymousEquivalentClasses'|'anonymousSuperClasses')} type The axiom type to load.
         * @returns {Promise<undefined>}
         */
        async loadAxiomsByType(type) {
            return new Promise(async (resolve) => {
                if (!this.selectedData[type]) return resolve();

                for (const axiom of this.selectedData[type]) {
                    const parsedAxiom = await AxiomsParser.parse(
                        this.selectedData.sourceUniqueID,
                        axiom.classExpression.trim()
                    );
                    this.parsedAxioms[type].push(parsedAxiom);
                }

                resolve();
            });
        },

        /**
         * Copies the dimensions of a given DOM element over to the graph area.
         * NOTE: the padding class is a crude workaround to avoid the tabs from showing if
         * the graph area has been scrolled all the way down. While resizing, the rendering
         * of the horizontal scrolling bar when a minimum width has been surpassed
         * skews height calculations.
         * @params {Object} refEl - Object for the element being used as a layout reference.
         */
        updateGraphDims(refEl = this.$el) {
            this.graphWidth = refEl.clientWidth;
            this.graphHeight = refEl.clientHeight - 200; //TODO remove 10%
            this.graphPaddingClass = this.graphWidth >= 386 ? 'mb-2' : 'pb-2';
        },

        /**
         * Determines if the current tab contains properties.
         * @param {Number} tabIndex
         * @return {boolean}
         */
        isPropertyTab(tabIndex) {
            return this.classTabs[tabIndex].hasOwnProperty('properties');
        },

        /**
         * Determines if the current tab contains changes.
         * @param {Number} tabIndex
         * @return {boolean}
         */
        isChangesTab(tabIndex) {
            return this.classTabs[tabIndex].hasOwnProperty('changes');
        },

        /**
         * Determines if there is at least one property going into the current tab with a non-empty value.
         * @see {@link classPropNames}
         * @param {Number} tabIndex
         * @return {boolean}
         */
        isEmptyTab(tabIndex) {
            if (useClassView().isSchemaVersion1.value || tabIndex > 1) {
                return !_.intersection(
                    this.classTabs[tabIndex].properties,
                    this.classPropNames
                ).length;
            }

            if (tabIndex === 0) {
                const propertyValues =
                    useClassView().getClassData.value.propertyValues;

                return (
                    !propertyValues ||
                    propertyValues.length < 1 ||
                    propertyValues.every((property) => !property.value)
                );
            }

            if (tabIndex === 1) {
                const relationalProperties =
                    useClassView().getRelationalProperties.value;
                return Object.values(relationalProperties).every(
                    (propertyValues) => {
                        return (
                            propertyValues.length + this.instances.length < 1
                        );
                    }
                );
            }
        },

        /**
         * Determines if any of the class' edited data properties have errors.
         * @param {Array} properties - Set of properties to search for errors in.
         * @returns {Array} Properties with error.
         */
        hasError(properties) {
            const editedProps = Object.keys(this.editState);
            const surveyProps = _.intersection(
                editedProps,
                properties || editedProps
            );

            return surveyProps.filter((property) => {
                return (
                    !this.editState[property].isSaving &&
                    this.editState[property].error
                );
            });
        },

        /**
         * Determines if any of the class' edited data properties has been edited successfully.
         * @param {Array} properties - Set of properties to search for errors in.
         * @returns {Array} Properties edited so far.
         * @todo Fix edits count for annotation properties schema version 2
         */
        hasEdit(properties) {
            const editedProps = Object.keys(this.editState);

            const surveyProps = _.intersection(
                editedProps,
                properties || editedProps
            );

            return surveyProps.filter((property) => {
                return (
                    !this.editState[property].error &&
                    this.editState[property].isSaving === false
                );
            });
        },

        onClassObsolete() {
            this.setClassObsolete().catch((childCount) => {
                this.$eventBus.$emit(
                    'notification:show',
                    'warning',
                    `Only childless classes can be marked as obsolete. Please unlink each child from its parent first.`,
                    `${childCount} child nodes detected`,
                    [],
                    'rightTop'
                );
            });
        },

        onSetIgnoreByTermite() {
            const oldTermiteIgnoreIri = 'http://scibite.com/skos/config#ignore';
            const newTermiteIgnoreIri =
                'http://ontology.scibite.com/ontology/termite/ignore';
            let bodyParams = {};

            if (!this.isNewIgnoredByTermiteIri() && this.isIgnoredByTermite) {
                bodyParams = {
                    iri: oldTermiteIgnoreIri,
                    name: 'true',
                    searchable: false,
                };
                usePropertyList().removeItemFromPropList(
                    'true',
                    bodyParams,
                    bodyParams.iri
                );
                return;
            }

            bodyParams = {
                iri: newTermiteIgnoreIri,
                name: 'true',
                searchable: false,
            };

            if (this.isIgnoredByTermite) {
                usePropertyList().removeItemFromPropList(
                    'true',
                    bodyParams,
                    bodyParams.iri
                );
            } else {
                usePropertyList().addItemToPropList(
                    'true',
                    bodyParams,
                    bodyParams.iri
                );
            }
        },

        isNewIgnoredByTermiteIri() {
            const propertyBlackList = usePropertyList().propertyBlackList;
            const ignoredByTermiteIri = propertyBlackList.value.filter(
                (property) =>
                    property.iri === 'http://scibite.com/skos/config#ignore' ||
                    property.iri ===
                        'http://ontology.scibite.com/ontology/termite/ignore'
            );
            if (
                ignoredByTermiteIri.length &&
                ignoredByTermiteIri[0].iri ===
                    'http://ontology.scibite.com/ontology/termite/ignore'
            ) {
                return true;
            } else {
                return false;
            }
        },

        /**
         * Notifies of a change when a certain property has been edited to a new non-empty value. If the format of the new value
         * does not match the API's, it converts everything to strings internally to allow comparison.
         * @param {string} propName - Name of the property just edited.
         * @param {string | Array} propValue - New value of the property.
         * @param {boolean} [isSilent = false] - If true, the class property is changed but the edit is not notified.
         */
        onEditableBlur(
            propNameOrIri,
            propValue,
            isSilent = false,
            newSingleValue
        ) {
            const schemaVersion = useClassView().whichSchemaVersion.value;
            const useSchemaVersion1 =
                schemaVersion === 1 ||
                useClassView().isRelationshipProperty(propNameOrIri);

            const propName = useSchemaVersion1 ? propNameOrIri : '';
            const propIri = !useSchemaVersion1 ? propNameOrIri : '';

            let newFormattedValue = propValue;
            let oldFormattedValue = [];
            let difference = [];

            if (useSchemaVersion1) {
                oldFormattedValue = this.getPropValue(
                    propName,
                    this.selectedData
                );
            } else {
                oldFormattedValue =
                    usePropertyValues().getPropertyValuesByIri(propIri);
            }

            // If new array expected but string provided, converts the new value..
            if (Array.isArray(oldFormattedValue) && !Array.isArray(propValue)) {
                newFormattedValue = _.uniqBy(
                    propValue.split(/\s*,\s*/).filter(Boolean)
                );
            }

            difference = propValuesDifference(
                newFormattedValue,
                oldFormattedValue
            );

            // If the value has changed, it signals that to the outside world.
            if (difference.length) {
                if (!isSilent) {
                    this.$emit(
                        'edit',
                        propName || propIri,
                        newFormattedValue,
                        oldFormattedValue,
                        undefined,
                        newSingleValue
                    );
                }

                // Nudges the app into updating the current view.
                // customPropMatch = isCustomProperty(propName);
                if (useOntology().isCustomAnnotationProperty(propName)) {
                    this.selectedData.annotationProperties[propName] =
                        newFormattedValue;
                }

                if (useOntology().isCustomRelationalProperty(propName)) {
                    this.selectedData.relationalProperties[propName] =
                        newFormattedValue;
                }
            }
        },

        /**
         * Tries to locally find the class data corresponding to a given primary ID, be that from the
         * outside world or the cache. The former has priority over the latter, updating the cache if applicable.
         * @param {string} primaryID - ID for the class whose data is being searched for
         * @deprecated from v2.0, use compositions/useClassView().findClass() instead.
         */
        findClassCached(primaryID) {
            let resolvedClass = this.findClass(primaryID);

            // Class was found in the tree => updates cache to capture any new local data
            if (!_.isEmpty(resolvedClass) && !_.isFunction(resolvedClass)) {
                ClientDebugger.log(
                    'resolvedClass is not empty: caching from the existing tree classes'
                );

                this.$set(this.classCache, primaryID, resolvedClass);
                // Class not found externally => tries the cache and marks the class as not found locally
            } else if (this.classCache[primaryID]) {
                ClientDebugger.log(
                    'tree classes cache not available: tries the cache and marks the class as not found locally'
                );
                resolvedClass = this.classCache[primaryID];
                delete resolvedClass.editState;
            }

            return resolvedClass;
        },

        /**
         * Updates the cache with any classes within the present ontology and resolved remotely.
         * @param {Object[]} classList - Class data for each item resultion yielded.
         */
        onClassResolved(classList) {
            const localClasses = _.filter(classList, {
                sourceUniqueID: this.selectedData.sourceUniqueID,
            });

            localClasses.forEach((classData) => {
                if (!this.classCache.hasOwnProperty(classData.primaryID)) {
                    this.$set(this.classCache, classData.primaryID, classData);
                }
            });
        },

        /**
         * Resets the cache to the current class and any other related classes.
         */
        resetCache() {
            const classPrimaryIDs = [this.selectedData.primaryID];

            ClientDebugger.log('classPrimaryID', this.selectedData.primaryID);

            for (const primaryIDs of Object.values(
                this.selectedData.relationalProperties
            )) {
                if (!primaryIDs.length) continue;

                classPrimaryIDs.push(...primaryIDs);
            }

            for (const propName of RELATIONSHIP_PROPS) {
                const primaryIDs = this.selectedData[propName];

                if (!primaryIDs.length) continue;

                classPrimaryIDs.push(...primaryIDs);
            }

            ClientDebugger.log('Class cache has been reset');

            this.classCache = _.pick(this.classCache, classPrimaryIDs);

            ClientDebugger.log('classCache', this.classCache);
        },

        /**
         * Adds all the unique classes in all paths from the selected class to the root.
         */
        addPathCache() {
            this.getMergedPaths().then((path) => {
                const cacheEntries = _.without(
                    path,
                    ..._.values(this.classCache)
                );

                cacheEntries.forEach((classData) => {
                    this.$set(this.classCache, classData.primaryID, classData);
                });
            });
        },

        /**
         * Resets the cache to all the classes different to a given set.
         * @param {string[]} primaryIDs - IDs for the classes whose cache entries are being removed.
         */
        removeCachedClass(primaryIDs) {
            this.classCache = _.omit(this.classCache, primaryIDs);
        },

        async getClassHistory() {
            const history = await ApiOntology.getHistory(
                this.selectedData.id,
                this.selectedData.ontologyID
            );

            if (!history.data.hasOwnProperty('editsHistory')) {
                return;
            }

            this.editHistory = history.data.editsHistory;
        },

        /**
         * Bubbles relationship lists with entries up, ahead of empty ones.
         * TODO: do not reorder when propValues change. Maintain it while editing and only reoder when out of edit mode.
         */
        sortedProps: function (propertiesFilter, props) {
            props = Object.keys(usePropertyList().getPropertyList.value);

            const sorted = _.sortBy(props, (propName) => {
                const iris = useOntology().getIrisByPropName(propName);
                const propValue = this.getPropValue(
                    propName,
                    this.selectedData,
                    iris
                );

                return propValue && propValue.length === 0;
            }).filter((propName) => {
                // Removing values annotations if in edit mode.

                if (
                    this.isEditMode &&
                    this.propertyValuesNames.includes(propName)
                )
                    return false;

                if (propName.includes('.')) {
                    propName = propName.split('.')[1];
                }

                return propertiesFilter
                    ? propName
                          .toLowerCase()
                          .includes(propertiesFilter.toLowerCase())
                    : true;
            });

            return sorted;
        },

        /**
         * Checks if a property has relationships
         * @param propertyName
         * @return {boolean}
         * PERFORMANCE TEST: fast
         */
        hasRelationships: function (propertyName) {
            return (
                this.flatRelationshipProps.indexOf(propertyName) !== -1 ||
                propertyName === 'mappings'
            );
        },
    },
    mixins: [EditMixin, PropertyValuesMixin],
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';
::v-deep .btn-instance-load-more {
    background-color: transparent;
    margin-left: 1.75rem;
    color: black;
    margin-bottom: 2rem;
    scale: 80%;
}

.termite-btn.active {
    opacity: 1 !important;
    &:hover:not(.outside-edit) {
        opacity: 0.5 !important;
    }
}
.termite-btn.outside-edit {
    cursor: auto !important;
    &:hover {
        opacity: auto !important;
    }
}

.item-heading {
    margin-bottom: 0.35rem;
    font-size: 1.25rem;
    font-weight: 500;

    .edit-btn {
        color: $info;
    }

    .del-hovered ::v-deep .name-wrapper {
        .name-label,
        .name-termid {
            color: $gray-600 !important;
        }

        .name-termid {
            text-decoration: line-through;
        }
    }

    ::v-deep .name-label {
        color: $secondary;
    }

    ::v-deep .name-termid {
        color: $primary;
    }

    ::v-deep .edit-error [contentEditable] {
        background: rgba($danger, 0.08);
    }

    ::v-deep .edit-success [contentEditable] {
        background: rgba($info, 0.08);
    }
}

.primary-container {
    ::v-deep .summary-property {
        display: inline;
    }

    .text-actions {
        opacity: 0.3;
    }

    &:hover .text-actions {
        opacity: 1;
    }
}

::v-deep .nav-item:not(:first-child) {
    margin-left: 0.5rem;
}

::v-deep .property-tab {
    background: rgba($secondary, 0.1);
    border-bottom: 1px solid $gray-300;
    outline: none;

    &.active,
    &:hover {
        background: transparent;
    }

    .tab-title {
        font-weight: 500;
        color: $secondary;
    }

    .class-count-badge {
        display: inline-block;
        line-height: 1em;
        vertical-align: middle;
        cursor: default;

        .badge {
            min-width: 1.6em;
            line-height: 1.6em;
            padding: 0 0.26em;
        }
    }
}

.edit-enabled.class-properties-move {
    transition: transform 0.3s ease-in;
}

.empty-hint {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

::v-deep .stacked-heading {
    border-bottom: 1px solid $gray-300;
    background-color: $light-gray;

    .stacked-name {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
    }
}

.axioms-container {
    background-color: $light-gray;

    .axioms-expression {
        padding: 0.75rem;
    }

    .instance-expression {
        padding: 0.75rem;
    }

    .axiom {
        ::v-deep &--k {
            color: $axiom-k;
        }

        ::v-deep &--q {
            color: $axiom-q;
        }

        ::v-deep &--q {
            color: $axiom-q;
        }

        ::v-deep &--op,
        ::v-deep &--dp {
            font-style: italic;
        }

        ::v-deep &--c.obsolete,
        ::v-deep &--c.obsolete:hover {
            color: #212529;
            text-decoration: line-through !important;
        }
    }
}
</style>
