import {
    difference,
    extend,
    filter,
    forOwn,
    isEmpty,
    map,
    set,
    sortBy,
    values,
} from 'lodash';

import ApiEdit from '@/api/edit';
import edit from '@/api/edit';
import { ANNOTATION_PROPS, RELATIONSHIP_PROPS } from '@/config';
import {
    useClassView,
    useEdits,
    useEventBus,
    useObservers,
    useOntology,
    usePropertyList,
    usePropertyValues,
} from '@/compositions';
import { propValuesDifference } from '@/utils';

export default {
    props: {
        relationshipProps: {
            type: Array,
            default: function () {
                return RELATIONSHIP_PROPS;
            },
        },
    },

    data() {
        return {
            // UUID used to identify chained changes (current edit unit)
            edit_transactionID: '',

            // Counter of property errors in total.
            edit_errorCount: 0,

            // Central register for changes made to classes and errors incurred in.
            edit_changes: {},
            edit_errors: {},

            // Flags if the view is to allow changes to properties (on edit mode).
            edit_isActive: false,

            // Comment for the edit currently active.
            edit_comment: '',
        };
    },

    computed: {
        // Flags if the changes ultimately involve class relationships.
        edit_isRelChanges: function () {
            return useEdits().getRelationsHaveChanged.value;
        },
        // Data objects for each of the classes that have an entry in the error log.
        $_edit_erroredClasses: function () {
            return map(values(this.edit_errors), 'live');
        },

        /**
         * Gets only non-cancelling class edit actions (ie: those that have a net effect).
         * @todo Find where `history` is set
         * @return {Record<string, {
         *   isNew: boolean;
         *   isObsolete: boolean;
         *   last: definitions["OntologyEdit"];
         *   history: unknown
         * }>[]}
         * @deprecated from v2.0, use useEdits().getNonCancellingEdits instead
         */
        $_edit_netEdits: function () {
            return filter(
                this.edit_changes,
                (entry) => !isEmpty(entry.history)
            );
        },

        // Number of classes edited with a net effect.
        $_edit_hasEdits: function () {
            if (useClassView().isSchemaVersion1.value)
                return this.$_edit_netEdits.length;

            if (useEdits().hasMergeEditChanges.value)
                return useEdits().getMergeEditChanges.value.length;
            return useEdits().countEditedClasses.value;
        },

        // Gets the current property values for each of the validly edited classes with net changes and sorted by latest edit.
        // NOTE: this is a user-friendly version of the collection of net edits.
        $_edit_editedClasses: function () {
            const liveData = map(this.$_edit_netEdits, 'live');
            const errorFree = liveData.filter(
                (classData) => !this.edit_errors[classData.id]
            );
            const latestFirst = sortBy(errorFree, (classData) => {
                const lastEdit = this.edit_changes[classData.id].last || {
                    creationDate: new Date().toISOString(),
                };
                return lastEdit.creationDate;
            });
            return latestFirst;
        },

        /**
         * Gets edit log entries for only obsolete classes.
         * @return {ClassEdit[]}
         * @deprecated from v2.0 use useEdits().getObsoleteClasses instead.
         */
        $_edit_obsoleteClasses: function () {
            const obsClassEntries = this.$_edit_netEdits.filter(
                (entry) => entry.isObsolete
            );
            return map(obsClassEntries, 'live');
        },

        /**
         * Gets edit log entries for only brand new classes.
         * @return {ClassEdit[]}
         * @deprecated from v2.0 use useEdits().getNewClasses instead.
         */
        $_edit_newClasses: function () {
            const newClassEntries = this.$_edit_netEdits.filter(
                (entry) => entry.isNew
            );
            return map(newClassEntries, 'live');
        },

        /**
         * Gets edit log entries for only already existing classes that are not obsolete and have been changed.
         * @return {ClassEdit[]}
         * @deprecated from v2.0, use instead useEdits().getChangedClasses
         * TODO change with useEdits().getChangedClasses, which seem to be working just fine
         */
        $_edit_changedClasses: function () {
            const changedClassEntries = this.$_edit_netEdits.filter(
                (entry) => !entry.isNew && !entry.isObsolete
            );
            return map(changedClassEntries, 'live');
        },

        // Auto-generated comment using info on existing class changes.
        $_edit_autoComment: function () {
            if (this.$_edit_hasEdits) {
                return (
                    'Classes: ' +
                    map(this.$_edit_netEdits, 'live.primaryLabel').join(', ')
                );
            } else {
                return '';
            }
        },
    },

    methods: {
        /**
         * Clears all logs and any associated related state-tracking.
         */
        $_edit_reset() {
            useEdits().setTransactionId('');
            useEdits().setRelationsHaveChanged(false);
            usePropertyValues().initialisePropertyValuesByIri();

            this.edit_transactionID = '';
            this.edit_comment = '';
            this.edit_changes = {};
            this.edit_errors = {};
            this.edit_errorCount = 0;
            this.edit_isActive = false;
            window.onbeforeunload = null;
        },

        /**
         * Handles and edit start action. Intended to be triggered only when the user clicks on the edit button,
         * before any specific ontology class is selected.
         * @param {string|null} transactionID
         */
        $_edit_start(transactionID) {
            useEdits().setTransactionId(transactionID);
            this.edit_transactionID = transactionID;
            this.edit_isActive = true;
            window.onbeforeunload = function () {
                return true;
            };
        },

        /**
         * Adds or removes an entry in the error log. When removing, it deletes the copy of the original property values
         * before the error was triggered.
         * @param {string} propName - Name of the changed property.
         * @param {string|string[]} previousValue - Value of the property before change.
         * @param {Object} classData - Reactive data descriptive of the class just changed.
         * @param {string} [classID = ''] - Optional ID for the class whose error is gonna be cleared, in case it has changed.
         * NOTE: new classes have a locally-generated temporary ID which any transaction is logged with.
         */
        $_edit_trackError({
            propName,
            previousValue = '',
            classData = {},
            classID = '',
        }) {
            const id = classID || classData.id;
            const log = this.edit_errors;

            // New error entry
            if (arguments[0].hasOwnProperty('previousValue')) {
                // Avoids counting the same error twice (eg: consecutive label clashes for a new class)
                if (!log[id] || !log[id].previous.hasOwnProperty(propName)) {
                    this.edit_errorCount++;
                }

                this.$_edit_trackChange(
                    propName,
                    previousValue,
                    classData,
                    log,
                    true
                );

                // The error has been corrected => removes it from the log
            } else if (
                log.hasOwnProperty(id) &&
                log[id].previous.hasOwnProperty(propName)
            ) {
                this.edit_errorCount--;

                // No previous value recorded => first edit attempt was an error => treat value before error as legit.
                if (!this.edit_changes.hasOwnProperty(id)) {
                    this.$_edit_trackChange(
                        propName,
                        log[id].previous[propName],
                        classData,
                        this.edit_changes
                    );
                }

                delete log[id].previous[propName];
                if (isEmpty(log[id].previous)) {
                    delete log[id];
                }
            }
        },

        /**
         * Logs a given property change, recording enough information to allow committing all edits on bulk if so wished.
         * @param {string} propName - Name of the changed property.
         * @param {string|string[]} previousValue - Value of the property before change.
         * @param {Object} classData - Reactive data descriptive of the class just changed.
         * @param {Object} log - Where the change is registered.
         * @param {boolean} [isOverwrite = false] - True if previously logged property values can be overwritten.
         * @param {boolean} [isHistory = false] - True if the difference with the previous value is to be tracked.
         * @param {string|string[]} newValue - Value of the property after edit.
         * @returns True if the change does not cancel out an existing one.
         */
        $_edit_trackChange(
            propName,
            previousValue,
            classData,
            log,
            isOverwrite = false,
            isHistory = false,
            newValue
        ) {
            let isMeaningfulChange = false;
            const id = classData.id;

            if (useOntology().isCustomAnnotationProperty(propName)) {
                propName = `annotationProperties.${propName}}`;
            }

            if (useOntology().isCustomRelationalProperty(propName)) {
                propName = `relationalProperties.${propName}`;
            }

            if (!log.hasOwnProperty(id)) {
                this.$set(log, id, { live: classData });
                this.$set(log[id], 'previous', {});
                this.$set(log[id], 'history', {});
            }

            if (!log[id].live) {
                this.$set(log[id], 'live', classData);
            }

            if (!log[id].previous) {
                this.$set(log[id], 'previous', {});
            }

            if (isHistory && !log[id].history) {
                this.$set(log[id], 'history', { [propName]: [] });
            }

            if (isHistory && !log[id].history.hasOwnProperty(propName)) {
                this.$set(log[id].history, propName, []);
            }

            // Original value for the property in question if not overwritten.
            if (isOverwrite || !log[id].previous.hasOwnProperty(propName)) {
                log[id].previous[propName] = previousValue;
            }

            if (isHistory) {
                // Determines the overall change when the current value is compared with the original one.
                const historyPropName = propValuesDifference(
                    newValue,
                    log[id].previous[propName] || previousValue
                );

                log[id].history[propName] = historyPropName.filter(
                    (prop) => prop.length > 0
                );

                // Keeps track of precisely which items have been updated.
                // TODO: this should actually happen within the classItem view so that saving and error states are also limited to the edited array item.
                isMeaningfulChange = log[id].history[propName].length;

                if (isMeaningfulChange) {
                    if (classData.hasOwnProperty('editState')) {
                        this.$set(
                            classData.editState[propName],
                            'history',
                            log[id].history[propName]
                        );

                        if (
                            !classData.editState[propName].hasOwnProperty(
                                'original'
                            ) &&
                            previousValue
                        ) {
                            this.$set(
                                classData.editState[propName],
                                'original',
                                previousValue
                            );
                        }
                    } else {
                        console.warn(
                            `The class "${classData.primaryLabel}" with ID ${id} has not been assigned any edit state. Skipping history update.`
                        );
                    }

                    // New edit action is complementary to a previous one => the two cancel each other out and removes the prev action from history instead.
                } else {
                    this.$delete(log[id].history, propName);

                    if (typeof classData.editState === 'undefined') return;
                    this.$delete(classData.editState, propName);
                }
            }

            return isMeaningfulChange;
        },

        /**
         * Registers the stage at which a given property change is at within the scope of a class.
         * @param {string} propNameOrIri - Name of the changed property.
         * @param {Object} classData - Reactive data descriptive of the class just changed.
         * @param {boolean} isNew - True if the change consists of a newly created class.
         * @param {boolean} isObs - True if the change is actually the obsolescence of a class.
         * @param {Promise<Object>} classEdited - Promise from committing the edit corresponding to the class change.
         */
        $_edit_trackState(
            propNameOrIri,
            classData,
            isNew,
            isObs,
            classEdited = Promise.resolve(),
            isClassEditResolved = false
        ) {
            let newEditState = { isSaving: true };
            let isEffectiveNew;

            const isCustomAnnotationProperty =
                useOntology().isCustomAnnotationProperty(propNameOrIri);
            const isDefaultRelationalProperty =
                RELATIONSHIP_PROPS.includes(propNameOrIri);
            const isDefaultAnnotationProperty =
                ANNOTATION_PROPS.includes(propNameOrIri);
            const isPrimaryLabel = propNameOrIri === 'primaryLabel';
            const isClassId = propNameOrIri === 'id';
            const isPrimaryId = propNameOrIri === 'primaryID';
            const isSourceUniqueId = propNameOrIri === 'sourceUniqueID';

            if (isCustomAnnotationProperty) {
                propNameOrIri = `annotationProperties.${propNameOrIri}`;
            }

            if (
                !isCustomAnnotationProperty &&
                !isDefaultRelationalProperty &&
                !isDefaultAnnotationProperty &&
                !isPrimaryLabel &&
                !isClassId &&
                !isPrimaryId &&
                !isSourceUniqueId
            ) {
                propNameOrIri = `relationalProperties.${propNameOrIri}`;
            }

            // Only notifies state change if there is a tree to notify to.
            // NOTE: in some cases, a class may not have a path-from-root tree.
            if (classData.hasOwnProperty('editState')) {
                if (classData.editState.hasOwnProperty(propNameOrIri)) {
                    newEditState = classData.editState[propNameOrIri];
                } else {
                    // @todo ... Apply the editState to the classData (formerly $attrs)?? Do something better.
                    this.$set(classData.editState, propNameOrIri, newEditState);
                }
            }

            // Obsolescence takes precedence over newness and the latter over mere changes.
            isEffectiveNew =
                !isObs &&
                (isNew ||
                    (this.edit_changes[classData.id] &&
                        this.edit_changes[classData.id].isNew));
            //This check is only needed when the class api request has already been resolved, see the bulk add function
            if (!this.edit_changes[classData.id]) {
                this.$set(this.edit_changes, classData.id, {});
            }

            if (isClassEditResolved) {
                this.$delete(newEditState, 'error');
                this.$set(newEditState, 'isNew', isEffectiveNew);
                this.$set(newEditState, 'isObsolete', isObs);

                // Declares state at the class global level
                this.$set(
                    this.edit_changes[classData.id],
                    'isNew',
                    isEffectiveNew
                );
                this.$set(this.edit_changes[classData.id], 'isObsolete', isObs);
                this.$set(this.edit_changes[classData.id], 'last', classData);
                newEditState.isSaving = false;
            } else {
                classEdited
                    .then((response) => {
                        if (!response) return;
                        // Clears any previous error and declares any state at the local property level.
                        // TODO: remove these and rely only on the top-level states below.
                        this.$delete(newEditState, 'error');
                        this.$set(newEditState, 'isNew', isEffectiveNew);
                        this.$set(newEditState, 'isObsolete', isObs);

                        // Declares state at the class global level
                        this.$set(
                            this.edit_changes[classData.id],
                            'isNew',
                            isEffectiveNew
                        );
                        this.$set(
                            this.edit_changes[classData.id],
                            'isObsolete',
                            isObs
                        );
                        this.$set(
                            this.edit_changes[classData.id],
                            'last',
                            response.data
                        );
                    })
                    .finally(() => {
                        newEditState.isSaving = false;
                    });
            }

            return newEditState;
        },

        /**
         * This is a hack to get around the promise the classEdited used for adding and editing classes
         * This is only needed if you need to await the endpoint first before setting the tracking up
         * @param {string} propNameOrIri
         * @param {Object} classData
         */
        $_set_editState_Change(propNameOrIri, classData) {
            let newEditState = { isSaving: true };
            if (useOntology().isCustomAnnotationProperty(propNameOrIri)) {
                propNameOrIri = `annotationProperties.${propNameOrIri}`;
            }

            // Only notifies state change if there is a tree to notify to.
            // NOTE: in some cases, a class may not have a path-from-root tree.
            if (classData.hasOwnProperty('editState')) {
                if (classData.editState.hasOwnProperty(propNameOrIri)) {
                    newEditState = classData.editState[propNameOrIri];
                } else {
                    // @todo ... Apply the editState to the classData (formerly $attrs)?? Do something better.
                    this.$set(classData.editState, propNameOrIri, newEditState);
                }
            }
        },

        /**
         * Adds entries to the edit log according to stored edit data and applies it to a specific class.
         * @param {string} ontologyID - Unique identifier for the ontology whose current version is being compared against.
         * @param {Object} editData - Data entry for the edit event with all its details.
         * @param {Object} targetClass - Representation of the class on which the edits are to be applied.
         * @param {Object[]} suggests - List of suggest events within the edit data.
         */
        $_edit_addToLog(ontologyID, editData, targetClass, suggests) {
            const lastEdit = editData.lastOntologyEdit;
            const suggestEvents = map(
                lastEdit.ontologyEditActionSet.filter(
                    (action) => action.editAction === 'SUGGEST'
                ),
                'ontologyEvent'
            );
            const changedClass = lastEdit.ontologyJSONBean;
            const isObs =
                editData.actions[editData.actions.length - 1] === 'OBSOLETE';
            const isNew = !isObs && editData.newClass;

            // Keep tabs of all suggestion actions for comment extraction.
            suggests.push(...suggestEvents);

            // Makes sure that the class object from the tree has the latest version of the class properties.
            extend(targetClass, changedClass);

            // Finds out which properties have changed.
            return ApiEdit.compare({ ontologyID, editID: lastEdit.id }).then(
                (comparison) => {
                    // NOTE: the primary label is used as a proxy property when the class itself is the subject of the change.
                    if (isObs || isNew) {
                        comparison.data.propNames.push('primaryLabel');
                    }
                    // Creates an edit log entry and a state object for the property changes.
                    comparison.data.changesArray.forEach((change) => {
                        const propName = change.fieldName;
                        let newValue = this.getPropValue(propName, targetClass);

                        // If a new class is made obsolete, the previous value is taken from the current class' data
                        let prevValue = this.getPropValue(
                            propName,
                            comparison.data.comparedWith || targetClass
                        );

                        // NOTE: clearance of past of future primary label is used to signal newness/obsolescence in terms of history data.
                        if (isObs) {
                            newValue = '';
                        } else if (isNew) {
                            prevValue = '';
                        }

                        this.$_edit_trackState(
                            propName,
                            targetClass,
                            isNew,
                            isObs,
                            Promise.resolve({ data: lastEdit })
                        );
                        this.$_edit_trackChange(
                            propName,
                            prevValue,
                            targetClass,
                            this.edit_changes,
                            false,
                            true,
                            newValue
                        );
                    });
                }
            );
        },

        /**
         * Retrieves the string of the value that is being removed.
         * @param {string[]} oldValue
         * @param {string[]} newValue
         * @return {string}
         */
        $_edit_getRemovedValue(oldValue, newValue) {
            const diff = difference(oldValue, newValue);

            return diff[0];
        },

        $_edit_onLabelChange(
            propName,
            newValue,
            oldValue,
            classData = this.selectedData,
            newSingleValue = ''
        ) {
            let classEdited;
            const pathParams = {
                classId: classData.id,
                ontologyId: classData.sourceUniqueID,
            };

            // Delete label
            if (newValue.length < oldValue.length) {
                const propertyIri =
                    usePropertyValues().getPropIriByName(propName);
                const propertyValue = this.$_edit_getRemovedValue(
                    oldValue,
                    newValue
                );
                const transactionId = useEdits().getTransactionId.value;

                const bodyParams = {
                    propertyIri,
                    propertyValue,
                    transactionId,
                };

                classEdited = ApiEdit.deleteV2(pathParams, bodyParams);
            }

            // add label
            if (newValue.length > oldValue.length) {
                const v2BodyParams = {
                    newValue: newSingleValue,
                    transactionId: useEdits().getTransactionId.value,
                };

                classEdited = ApiEdit.editLabelV2(pathParams, v2BodyParams);
            }

            return classEdited;
        },

        $_edit_onClassChangeV2(
            iri,
            newValue,
            oldValue,
            newSingleValue = '',
            classData = undefined
        ) {
            classData = classData || useClassView().getClassData.value;
            oldValue = oldValue || [];
            const propNameOrIri = usePropertyValues().getPropertyGroupName(iri);
            let isNewOp = false;
            let isObsOp = false;
            let classEdited;

            if (oldValue.length < newValue.length) {
                isNewOp = true;
                isObsOp = false;
            } else if (!newValue.length) {
                isObsOp = true;
            }

            const classId = useClassView().getClassElasticSearchId.value;
            const ontologyId = useClassView().getClassOntologyId.value;
            const pathParams = { classId, ontologyId };

            // Editing an existing property
            if (newValue.length === oldValue.length) {
                const valueDifference = propValuesDifference(
                    newValue,
                    oldValue
                );
                const propertyValue = valueDifference[1];
                const lang = usePropertyValues().getPropertyLanguage(
                    iri,
                    propertyValue
                );

                const bodyParams = {
                    propertyIri: iri,
                    propertyValue,
                    newPropertyValue: newSingleValue,
                    lang,
                    transactionId: useEdits().getTransactionId.value,
                };

                classEdited = edit.editPropertyValue(pathParams, bodyParams);
            }

            // Adding a new property
            if (newValue.length > oldValue.length) {
                const bodyParams = {
                    propertyIri: iri,
                    propertyValue: newSingleValue,
                    transactionId: useEdits().getTransactionId.value,
                };

                classEdited = edit.createPropertyValue(pathParams, bodyParams);
            }

            classEdited
                .then((response) /*: definitions["OntologyEdit"] */ => {
                    useEdits()
                        .refreshCurrentTransaction()
                        .then(async () => {
                            // Clears the entry for any error from a previous edit
                            this.$_edit_trackError({
                                propName: propNameOrIri,
                                classData,
                                classID: classId,
                            });

                            // Transitions any new stub class into a real one (e.g. real ID).
                            if (isNewOp) {
                                extend(
                                    classData,
                                    response.data.ontologyJSONBean
                                );
                                classData.id = response.data.ontologyClassId;
                            }

                            await useEdits().trackChanges(
                                iri,
                                propNameOrIri,
                                newSingleValue,
                                oldValue,
                                newValue,
                                false,
                                true
                            );
                        });

                    // Extracts error message from the response, if possible.
                })
                .catch((error) => {
                    this.$_edit_trackError({
                        propName: propNameOrIri,
                        previousValue: oldValue,
                        classData,
                    });
                });

            if (useClassView().whichSchemaVersion.value >= 2) {
                this.$_edit_trackState(
                    iri,
                    classData,
                    isNewOp,
                    isObsOp,
                    classEdited
                );
            } else {
                this.$_edit_trackState(
                    propNameOrIri,
                    classData,
                    isNewOp,
                    isObsOp,
                    classEdited
                );
            }

            return classEdited;
        },

        /**
         * Registers with the backend the editing action performed on a given class property. Additionally, it logs
         * which properties and what values have been successfully/wrongly edited in the class. If the class is a new stub
         * or it's being made obsolete, it logs the edit as label change. Error recovery and notification is done locally.
         * NOTE: Editing is primarily allowed on the selected node(s) only.
         * @param {string} propNameOrIri - Name of the property just edited.
         * @param {string|string[]} newValue - Value of the property after edit.
         * @param {string|string[]} oldValue - Value of the property before edit.
         * @param {Object} classData - Reactive data descriptive of the class being edited.
         * @return {Promise<definitions["OntologyEdit"]>>}
         */ $_edit_onClassChange(
            propNameOrIri,
            newValue,
            oldValue,
            classData = this.selectedData,
            newSingleValue = '',
            propertyIri = ''
        ) {
            const isRelationshipProperty =
                useClassView().isRelationshipProperty(propNameOrIri);
            const useSchemaVersion2 =
                useClassView().whichSchemaVersion.value >= 2 &&
                !isRelationshipProperty;

            const params = {
                newValue: newValue,
                propName: propNameOrIri,
                ontologyID: classData.sourceUniqueID,
                classID: classData.id,
                parentID: classData.superClasses[0],
                transactionID: this.edit_transactionID,
            };

            /**
             * If it's a new operation.
             * @type {boolean|string}
             */
            let isNewOp = false;

            /**
             * if it's an obsolete operation.
             * @type {boolean|string}
             */
            let isObsOp = false;
            let classEdited;

            if (propNameOrIri === 'labels') {
                classEdited = this.$_edit_onLabelChange(
                    propNameOrIri,
                    newValue,
                    oldValue,
                    (classData = this.selectedData),
                    (newSingleValue = '')
                );
            } else if (useSchemaVersion2 && propNameOrIri !== 'primaryLabel') {
                return this.$_edit_onClassChangeV2(
                    propNameOrIri,
                    newValue,
                    oldValue,
                    newSingleValue,
                    classData
                );
            } else {
                // Change involves disallowed past or future empty labels => class addition or obsolescence
                // NOTE: failed new classes will have a previous non-empty label => treats label change as new class creation.
                if (propNameOrIri === 'primaryLabel') {
                    if (!oldValue.length || /\S+-NEW$/.test(classData.id)) {
                        isNewOp = true;
                        isObsOp = false;
                    } else if (!newValue.length) {
                        isNewOp = false;
                        isObsOp = true;
                    }
                }

                // NOTE: primary label operations signify a relationship change if the original or new value is empty.
                if (!this.edit_isRelChanges) {
                    const involvesRelationsChange =
                        isNewOp ||
                        isObsOp ||
                        this.relationshipProps.indexOf(propNameOrIri) !== -1;

                    useEdits().setRelationsHaveChanged(involvesRelationsChange);
                }

                if (isNewOp) classEdited = ApiEdit.new(params);
                // definitions["OntologyEdit"]
                else if (isObsOp) classEdited = ApiEdit.obsolete(params);
                // definitions["OntologyEdit"]
                else classEdited = ApiEdit.edit(params); // definitions["OntologyEdit"]
            }

            classEdited
                .then((response) /*: definitions["OntologyEdit"] */ => {
                    if (response.headers['warning']) {
                        useEventBus().getEventBus.value.$emit(
                            'notification:show',
                            'info',
                            response.headers['warning'] +
                                '\n Do you wish to continue?',
                            'Recent changes',
                            [
                                {
                                    text: 'I understand.',
                                    action: (toast) => {
                                        useEventBus().getEventBus.value.$emit(
                                            'notification:close',
                                            toast
                                        );
                                    },
                                },
                            ],
                            'centerTop',
                            {
                                timeout: 0,
                            }
                        );
                    }

                    // Clears the entry for any error from a previous edit
                    this.$_edit_trackError({
                        propName: propNameOrIri,
                        classData,
                        classID: params.classID,
                    });

                    // Transitions any new stub class into a real one (e.g. real ID).
                    if (isNewOp) {
                        extend(classData, response.data.ontologyJSONBean);
                        classData.id = response.data.ontologyClassId;
                    }
                    this.$_edit_trackChange(
                        propNameOrIri,
                        oldValue,
                        classData,
                        this.edit_changes,
                        false,
                        true,
                        newValue
                    );

                    // Extracts error message from the response, if possible.
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        useEventBus().getEventBus.value.$emit(
                            'notification:show',
                            'error',
                            error.response.data.detail,
                            'Recent changes',
                            [
                                {
                                    text: 'I understand.',
                                    action: (toast) => {
                                        useEventBus().getEventBus.value.$emit(
                                            'notification:close',
                                            toast
                                        );
                                    },
                                },
                            ],
                            'centerTop',
                            {
                                timeout: 0,
                            }
                        );
                    } else {
                        console.error(error);
                        this.$_edit_trackError({
                            propName: propNameOrIri,
                            previousValue: oldValue,
                            classData,
                        });
                    }
                });

            this.$_edit_trackState(
                propNameOrIri,
                classData,
                isNewOp,
                isObsOp,
                classEdited
            );

            if (propNameOrIri === 'primaryLabel') {
                useObservers().notifyObservers('PRIMARY_LABEL_UPDATED', {
                    oldValue,
                    newValue,
                });
            }

            return classEdited;
        },

        /**
         * This is only needed and will only work with an array of new classes added
         * This is created entirely to get around the non-batching way of submitting edits
         * @param {array} bulkClasses
         */
        async $_edit_onBulkClassChange(bulkClasses) {
            const processedNodes = [];
            const apiClassNodes = [];

            for (const nodeClass of bulkClasses) {
                const processedNode = {
                    newValue: nodeClass.newClassLabel,
                    propName: 'primaryLabel',
                    ontologyID: nodeClass.newNode.data.sourceUniqueID,
                    classID: nodeClass.newNode.data.id,
                    parentID: nodeClass.newNode.data.superClasses[0],
                    transactionID: this.edit_transactionID,
                };
                const apiNode = {
                    label: nodeClass.newClassLabel,
                    transactionId: this.edit_transactionID,
                };

                if (nodeClass.newNode.data.superClasses[0]) {
                    apiNode.subClassOf = nodeClass.newNode.data.superClasses[0];
                }

                useEdits().setRelationsHaveChanged(true);
                processedNodes.push(processedNode);
                apiClassNodes.push(apiNode);
            }

            //dispatches then loops through each new class added to merge the FE and BE objects together
            const dispatchedClasses = await ApiEdit.bulkNewClasses(
                apiClassNodes,
                bulkClasses[0].newNode.data.sourceUniqueID
            );

            useEdits().countClassChanges();

            for (const i in bulkClasses) {
                this.$_edit_trackError({
                    propName: 'primaryLabel',
                    classData: bulkClasses[i].newNode.data,
                    classID: bulkClasses[i].newNode.data.id,
                });

                bulkClasses[i].newNode.id =
                    dispatchedClasses.data[i].ontologyClassId;
                extend(
                    bulkClasses[i].newNode.data,
                    dispatchedClasses.data[i].ontologyJSONBean
                );

                //This editstate change is only needed because you need to await the bulknewClasses first
                this.$_set_editState_Change(
                    'primaryLabel',
                    bulkClasses[i].newNode.data
                );
                this.$_edit_trackChange(
                    'primaryLabel',
                    '',
                    bulkClasses[i].newNode.data,
                    this.edit_changes,
                    false,
                    true,
                    bulkClasses[i].newClassLabel
                );
                this.$_edit_trackState(
                    'primaryLabel',
                    bulkClasses[i].newNode.data,
                    true,
                    false,
                    dispatchedClasses.data[i],
                    true
                );
            }
        },

        /**
         * Reverts a given log back to its default value, allowing the resetting of any affected tree data in the process.
         * @param {Object} log - Registry any valid/invalid changes due to edits.
         * @param {boolean} [isDataReset = true] - If true, it rolls back any changes in the tree too.
         */
        $_edit_rollbackLog(log, isDataReset = true) {
            forOwn(log, (classSnapshot) => {
                // Restores the previous value for each property one by one, regardless of nesting level.
                if (isDataReset) {
                    Object.keys(classSnapshot.previous).forEach((propName) => {
                        const path =
                            this.isCustomProperty(propName) || propName;
                        const origValue = classSnapshot.previous[propName];

                        set(classSnapshot.live, path, origValue);
                    });
                }

                // Resets the edit progress indicator.

                if (classSnapshot.live) {
                    classSnapshot.live.editState = {};
                }
            });
        },

        /**
         * Requests an edit by ontologyID and transactionID and checks if ontologyJSONBean is empty.
         * @param {String} ontologyID The ontology ID
         * @param {String} transactionID The transaction ID
         * @returns {Promise<boolean>}
         */
        async $_edit_is_transaction_empty(ontologyID, transactionID) {
            const edits = await ApiEdit.transaction({
                ontologyID,
                transactionID,
            });
            const nonEmptyEdits = edits.data.filter((edit) => {
                const hasNoData = !edit.lastOntologyEdit.ontologyJSONBean;
                const isNew = edit.newClass;
                const isEmpty = hasNoData && isNew;

                return !isEmpty;
            });

            return !nonEmptyEdits.length;
        },

        /**
         * Saves all edits to the server, giving feedback during the process and in the event of an error.
         * @param {string} ontologyID - Unique identifier for the ontology to which changes are being saved.
         * @param {string} saveAction - Possible values can be 'suggest', 'commit' and 'reject'.
         * @param {string} progressMsg - Text displayed while save operation is in progress.
         */
        async $_edit_save(
            ontologyID,
            saveAction,
            progressMsg,
            createReverseMappings
        ) {
            let saved;
            // Issues a commit request for the whole set of edits
            if (this.edit_transactionID) {
                /**
                 * The merge classes functionality bypasses the usual register edit system, so instead we use the useEdits composition
                 * The ternary is used to determine if a merge class edit has been edited.
                 * This registering is done in the MergeClassesModal.vue file
                 * @type {string|String}
                 */
                const transactionId = useEdits().hasMergeEditChanges.value
                    ? useEdits().getTransactionId.value
                    : this.edit_transactionID;

                if (
                    await this.$_edit_is_transaction_empty(
                        ontologyID,
                        transactionId
                    )
                ) {
                    const error = new Error();
                    error.errorMessage =
                        'There are no active changes to submit';
                    throw error;
                }

                saved = ApiEdit.transaction({
                    ontologyID,
                    transactionID: transactionId,
                    comment: this.edit_comment,
                    verb: saveAction,
                    createReverseMappings: createReverseMappings,
                });
            } else {
                saved = Promise.resolve();
            }

            // Disables the default exception message
            this.$store.isPreventGlobalError = true;

            saved
                .catch((error) => {
                    console.dir(error);
                })
                .finally(() => {
                    this.$store.isPreventGlobalError = false;
                    useEdits().resetChangesMadeCount();
                });

            if (createReverseMappings) {
                setTimeout(() => {
                    if (this.$store.isPreventGlobalError) {
                        this.$eventBus.$emit(
                            'notification:progress',
                            'Saving Mappings...',
                            saved
                        );
                    }
                }, 500);
            } else {
                // Blocks the screen while the commit is in progress.
                // NOTE: feedback is only shown when the commit takes more than half a second to complete.
                setTimeout(() => {
                    if (this.$store.isPreventGlobalError) {
                        this.$eventBus.$emit(
                            'notification:progress',
                            progressMsg,
                            saved
                        );
                    }
                }, 500);
            }
            usePropertyList().buildPropertyList();
            return saved;
        },
    },
};
