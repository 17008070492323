class ClientDebugger {
    /**
     * Contains an instance of the class.
     * @type {ClientDebugger}
     */
    static instance;

    /**
     * If debugging mode is on.
     * @type {boolean}
     */
    debug = false;

    constructor() {
        this.debug = !!this.getDebugQueryParam();
    }

    /**
     * Gets the debug property.
     * @returns {boolean}
     */
    get debugMode() {
        return this.debug;
    }

    /**
     * Retrieves the `debug` query parameter from the url.
     * @returns {string}
     */
    getDebugQueryParam = () => {
        const urlParams = new URLSearchParams(window.location.search);

        return urlParams.get('debug');
    };

    /**
     * If debug mode is on logs a message to the console.
     * @param {String} message
     */
    static log = (...message) => {
        const self = ClientDebugger.getInstance();

        if (self.debugMode) console.log(...message);
    };

    /**
     * Instantiates the class implementing a sort of singleton.
     * @returns {*}
     */
    static getInstance = () => {
        if (ClientDebugger.instance) return ClientDebugger.instance;

        ClientDebugger.instance = new ClientDebugger();

        return ClientDebugger.instance;
    };
}

export default ClientDebugger;
