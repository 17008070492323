<template>
    <div class="class-tree" :class="{ 'roots-capped': isRootsCapped }">
        <hierarchy-tree
            ref="hierarchy"
            :nodes="treeData"
            :currPrimaryID="term.primaryID"
            :editLog="editLog"
            :isEditable="isEditable"
            :hasToolbar="true"
            :isPresetNodes="!isReplayTree"
            :ontologyID="ontologyID"
            :nodeUpdated="nodeUpdated"
            :pageNumberOffset="pageOffset"
            @tree:rendered="onTreeRendered"
            @tree:collapse="collapseRoots"
            v-on="$listeners" />
    </div>
</template>

<script>
import { flatten, map, reject, uniqBy } from 'lodash';
import HierarchyTree from '@/components/ui/HierarchyTree';

export default {
    name: 'classTree',
    components: { HierarchyTree },

    props: {
        term: {
            type: Object,
            required: true,
        },

        pageOffset: {
            type: Number,
        },

        treeData: {
            type: Object,
            required: true,
        },

        editLog: {
            type: Object,
            default: function () {
                return {};
            },
        },

        isEditable: {
            type: Boolean,
            default: false,
        },

        isReplayTree: {
            type: Boolean,
            default: false,
        },

        maxRootClasses: {
            type: Number,
            default: parseInt(process.env.VUE_APP_ROOT_SIZE),
        },

        ontologyID: {
            type: String,
            required: true,
        },

        nodeUpdated: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isRootsCapped: false,
        };
    },

    watch: {
        'treeData.total': {
            handler(total) {
                this.isRootsCapped =
                    this.treeData.leaves && total > this.treeData.leaves.length;

                // Shows an explainer for capped roots only once
                if (this.isRootsCapped) {
                    if (!this.$store.getters.hasRootMax) {
                        this.$eventBus.$emit(
                            'notification:show',
                            'info',
                            `The ontology has more root classes than the maximum ${this.maxRootClasses} renderable. You may access the remaining roots through the class breadcrumb above.`,
                            'Some root classes are not shown',
                            [],
                            'leftBottom',
                            { timeout: 0 }
                        );
                    }
                    this.$store.dispatch('remember', 'rootMax');
                }
            },
        },
    },

    mounted() {
        this.$eventBus.$on('window:scroll', this.setToolbarTop);

        // Shows an explainer for dotted arrows only once
        if (!this.$store.getters.hasViewedClass) {
            this.$eventBus.$emit(
                'notification:show',
                'info',
                'Expanded nodes with a dotted arrow may have missing child nodes. Re-expanding the node will retrieve the full set of children.',
                'Dotted arrows',
                [],
                'leftBottom',
                { timeout: 0 }
            );
        }
        this.$store.dispatch('remember', 'viewedclass');
    },

    methods: {
        /**
         * Sets the sticky top for the tree toolbar so that it stays just below the navigation bar after scrolling.
         */
        setToolbarTop() {
            this.$el.querySelector('.tree-toolbar').style.top =
                this.$store.headerHeight + 'px';
        },

        /**
         * Scrolls an already rendered node to the top and sets focus on it.
         * NOTE: the node area has to be forced into focus mode by setting its tabindex.
         * @param {Object} nodeEl - DOM element for the node.
         */
        scrollToNode(nodeEl) {
            nodeEl.firstElementChild.setAttribute('tabindex', '-1');
            nodeEl.firstElementChild.focus();
            nodeEl.scrollIntoView({ behavior: 'smooth' });
        },

        /**
         * Reveals the first node representing a certain class, selects it and scrolls it into view.
         * NOTE: the class is assumed to be present in the tree (be it mounted or not).
         * @param {string} classID - Identifier for the class whose instance node is to be searched for.
         * @param {string} [idField = 'primaryID'] - Name of the property containing the class identifier.
         * @param {boolean} [isSelect = true] - False if the node found is to be rendered but not selected.
         */
        nodeSelectByID(classID, idProp = 'id', isSelect = true) {
            const tree = this.$refs.hierarchy.$refs.tree;
            let treeNodes;
            let mountedNode;

            // If the class details are on display, its node(s) are already mounted and selected.
            if (classID !== this.term.id) {
                // Finds the first instance node of the class, preferably mounted.
                // TODO: keep track of nodes that are within the window to avoid jumping unnecessarily to the first one at the top.
                treeNodes = tree.findAll(
                    (node) => node.data[idProp] === classID
                );

                // If tree does indeed contain the searched node.
                if (treeNodes) {
                    mountedNode =
                        treeNodes.find((node) => node.isMounted) ||
                        treeNodes[0];

                    // If necessary, mounts the first instance found for that class before showing it.
                    this.$refs.hierarchy.mountAll([mountedNode]).then(() => {
                        if (isSelect) {
                            mountedNode.select();
                        }
                        this.scrollToNode(mountedNode.vm.$el);
                    });
                }
            }
        },

        /**
         * Traverses the tree in search of the first rendered node representative of a given class. If the node is not rendered,
         * falls back on whatever node is found first matching the given id.
         * @param {string} identifier - Designates the class whose node is being searched for.
         * @param {string} [idField = 'primaryID'] - Name of the property containing the identifier.
         * @return {ClassDataExtended|{}|null} - The class data, an empty object if not found, null if the tree has not been initialised.
         */
        findClass(identifier, idProp = 'primaryID') {
            const tree = this?.$refs?.hierarchy?.$refs?.tree;
            if (!tree) return null;
            let firstSameID = null;
            let firstRenderedID = null;

            // While searching for the first rendered node with same ID, save the first one found irrespective of rendering state.
            firstRenderedID = tree.find((node) => {
                if (node.data[idProp] === identifier) {
                    if (!firstSameID) {
                        firstSameID = firstRenderedID;
                    }

                    return node.hasOwnProperty('vm');
                }

                return false;
            });

            // Tree data ready
            if (tree.model && tree.model.length) {
                if (firstRenderedID) {
                    return firstRenderedID[0].data;
                } else if (firstSameID) {
                    return firstSameID.data;
                } else {
                    return {};
                }

                // Tree data has probably not been retrieved yet.
            } else {
                return null;
            }
        },

        /**
         * Adds a child node to all parent nodes representing the currently selected class.
         */
        addChildClass() {
            const treeModel = this.$refs.hierarchy.$refs.tree.tree;

            this.scrollToNode(treeModel.primeSelector.vm.$el);
            this.$refs.hierarchy.onNodeNew(treeModel.primeSelector);
        },

        /**
         * Gets all root paths for the currently selected class and merges them into a list of unique classes.
         */
        getMergedPaths() {
            const treeModel = this.$refs.hierarchy.$refs.tree.tree;
            const pathNodes = [];

            return this.selectionMounted(false).then(() => {
                let uniqPathNodes = [];

                // Grabs all paths from root class.
                treeModel.selectedNodes.forEach((node) => {
                    pathNodes.push(node.getPath());
                });

                uniqPathNodes = uniqBy(flatten(pathNodes), 'data.primaryID');
                return map(uniqPathNodes, 'data');
            });
        },

        /**
         * Marks the currently selected class as obsolete by removing all its instances in the tree and turning it
         * into a root class. If the node has descendants, it will be expanded automatically and be made obsolete only
         * once the children count is known. The edit will be signalled by a change to an empty label.
         * NOTE: Obsolescence is represented as an empty label.
         */
        setClassObsolete() {
            const tree = this.$refs.hierarchy.$refs.tree;
            const primeNode = tree.tree.primeSelector;
            const setObsoleteFn = () => {
                // The obsolescence status should be available before the resulting root node is rendered.
                this.$emit(
                    'node:edit',
                    'primaryLabel',
                    '',
                    primeNode.data.primaryLabel,
                    primeNode.data
                );

                // All nodes must be mounted before removing them so that past rendering info is not wiped out (eg: batchability)
                this.selectionMounted(false).then(() => {
                    this.$refs.hierarchy
                        .sameClassNodes(primeNode, true, true)
                        .forEach((node) => node.remove());
                });
            };

            // Node could have children
            if (primeNode.hasChildren()) {
                // Node whose children have not been retrieved yet => fecth children first to find out if it can be made obsolete
                if (primeNode.isBatch && !primeNode.expanded()) {
                    return new Promise((resolve, reject) => {
                        tree.$once('tree:data:received', (parentNode) => {
                            if (parentNode.numberOfChildren) {
                                reject(parentNode.numberOfChildren);
                            } else {
                                setObsoleteFn();
                                resolve(0);
                            }
                        });
                        this.$refs.hierarchy.grow([primeNode]);
                    });

                    // Node whose children have been retrieved or clearly have some already rendered.
                } else {
                    return Promise.reject(
                        primeNode.numberOfChildren || primeNode.children.length
                    );
                }

                // Node definitely has no children.
            } else {
                setObsoleteFn();
                return Promise.resolve(0);
            }
        },

        /**
         * Takes the current set of selected nodes and renders them all, expanding their parents in the process to
         * guarantee they are visible.
         * @param {boolean} isNotification - Display a message after selection set is forced to be visible.
         */
        selectionMounted(isNotification = true) {
            const treeModel = this.$refs.hierarchy.$refs.tree.tree;
            let allMounted = Promise.resolve();

            if (treeModel.selectedNodes.length) {
                allMounted = this.$refs.hierarchy.mountAll(
                    treeModel.selectedNodes
                );

                if (
                    treeModel.selectedNodes.some((node) => !node.isMounted) &&
                    isNotification
                ) {
                    allMounted.then(() => {
                        this.$eventBus.$emit(
                            'notification:show',
                            'info',
                            'Some edits require all selected nodes to be visible. Those still hidden have been automatically exposed.',
                            'Hidden nodes exposed',
                            [],
                            'leftBottom',
                            { timeout: 0 }
                        );
                    });
                }
            }

            return allMounted;
        },

        /**
         * Makes sure all root class nodes different from the one in the path-from-root are each probed
         * for their total number of children.
         */
        onTreeRendered() {
            const rootNodes = this.$refs.hierarchy.$refs.tree.tree.model;
            const collapsedRoots = reject(rootNodes, ['states.expanded', true]);

            this.$refs.hierarchy.grow(collapsedRoots);
        },

        /**
         * Collapses only the root classes of the tree.
         */
        collapseRoots() {
            const rootNodes = this.$refs.hierarchy.$refs.tree.tree.model;

            if (rootNodes) {
                rootNodes.forEach((node) => node.collapse());
            }
        },

        /**
         * Brings the view back to the initial state when the tree for the current class was loaded.
         * @param {boolean} isNotification - True if the user should be prompted for confirmation.
         */
        resetTree(isNotification) {
            return new Promise((resolve, reject) => {
                if (isNotification) {
                    this.$eventBus.$emit(
                        'notification:show',
                        'critical',
                        'Please note that any additional classes retrieved while navigating the tree will be lost and all filter settings will be reset. Do you wish to continue?',
                        'Reload class tree',
                        [
                            {
                                text: 'Cancel',
                                action: (toast) => {
                                    this.$eventBus.$emit(
                                        'notification:close',
                                        toast
                                    );
                                    this.$nextTick(reject);
                                },
                            },
                            {
                                text: 'Reload',
                                action: (toast) => {
                                    this.$eventBus.$emit(
                                        'notification:close',
                                        toast
                                    );
                                    this.$refs.hierarchy.resetFilter();
                                    this.$refs.hierarchy.resetData();
                                    this.$nextTick(resolve);
                                },
                            },
                        ],
                        'leftTop'
                    );
                } else {
                    this.$refs.hierarchy.resetFilter();
                    this.$refs.hierarchy.resetData();
                    resolve();
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

.class-tree {
    border: 2px solid $gray-300;
    border-radius: $border-radius;

    ::v-deep .tree-toolbar {
        position: sticky;
    }

    &.roots-capped ::v-deep .tree-container:not(.filtered) .tree-root:before {
        content: '\24D8 Please note that only the first 20 root classes are shown; keep scrolling to load more.';
        display: block;
        padding-left: 0.5rem;
        color: white;
        background: #1e88e5;
        text-align: center;
    }

    @media (min-width: 1024px) {
        border-radius: 0;

        ::v-deep .tree-toolbar {
            top: 0 !important;
        }
    }
}
</style>
