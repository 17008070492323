var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"property-value d-flex flex-row-reverse align-items-center"},[(
            (_vm.isEditable && _vm.value && _vm.propName !== 'labels') ||
            (_vm.isEditable &&
                _vm.value &&
                _vm.propName === 'labels' &&
                !_vm.isPrimaryLabel)
        )?_c('font-awesome-icon',{staticClass:"stacked-remove ml-2",attrs:{"icon":"times-circle","id":this.buildTestClass('icon')},on:{"click":function($event){return _vm.$emit(
                'value:delete',
                _vm.currentValue || _vm.value,
                _vm.property,
                _vm.previousValue
            )}}}):_vm._e(),[(_vm.value)?_c('span',{staticClass:"value flex-grow-1 d-inline-block stacked-item-name tags-header",class:{ 'word-break': _vm.isLink }},[(_vm.isInitClass && _vm.isClassJump)?_c('child-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    title: 'Show this class on the tree',
                    delay: { show: _vm.showDelay, hide: 0 },
                }),expression:"{\n                    title: 'Show this class on the tree',\n                    delay: { show: showDelay, hide: 0 },\n                }",modifiers:{"hover":true}}],staticClass:"class-jump flex-shrink-0 mr-2",nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('class:jump', _vm.initClass.id)}}}):_vm._e(),_c('font-awesome-icon',{staticClass:"tags-icon",class:_vm.headerIconClass,attrs:{"icon":_vm.headerIcon},on:{"click":_vm.toggleCollapsed}}),(_vm.displayedChips && !_vm.isEditMode)?_c('ul',{staticClass:"chip-list"},_vm._l((_vm.displayedChips),function(match,index){return _c('Chip',{key:index,attrs:{"text":match.shortDisplayName,"primaryID":match.primaryID,"sourceUniqueID":match.sourceUniqueID,"testId":'Chip' + index,"label":match.primaryLabel,"body":_vm.checkInternalMatch(match),"synonyms":match.synonyms,"hiddenChips":match.hiddenChips}})}),1):_vm._e(),_c('editable-text',{ref:"editText",attrs:{"id":("" + _vm.global_randomID),"isSaving":true,"ontologyID":_vm.propOntoID,"primaryID":_vm.primID,"isSuccess":_vm.isEditSuccess && !_vm.isUnchanged,"isError":_vm.isEditError && !_vm.isUnchanged,"contentEditable":_vm.isEditable,"text":_vm.value,"label":_vm.label,"isLink":_vm.isClickableLink(_vm.value),"isInternalLink":_vm.isInternalLink,"isNormalise":true,"maxLength":_vm.maxLength,"placeholder":_vm.$pluralize.singular(
                        _vm.upperFirst(_vm.humanReadable(_vm.propName))
                    ) + '...'},on:{"valueUpdated":function($event){return _vm.handleValueUpdated($event, _vm.property)}}}),_c('text-actions',{attrs:{"copyTargetName":_vm.$pluralize.singular(_vm.humanReadable(_vm.propName)),"copyTargetEl":function () { return _vm.$refs.editText.containerEl; },"copyFullText":_vm.value,"url":_vm.isLink || _vm.isLink ? _vm.value : '',"searchTargetName":_vm.$pluralize.singular(_vm.humanReadable(_vm.propName)),"searchTargetEl":function () { return _vm.$refs.editText.containerEl; },"isSearchActions":_vm.isTextSearch}}),(
                    typeof _vm.internalMatches[0] !== 'undefined' && !_vm.isEditMode
                )?_c('PropertyValuePopup',{attrs:{"primaryID":_vm.internalMatches[0].primaryID,"primaryLabel":_vm.internalMatches[0].primaryLabel,"description":_vm.getTextualDefinitions,"synonyms":_vm.internalMatches[0].synonyms,"target":("" + _vm.global_randomID)}}):_vm._e(),(_vm.isSchemaVersion2)?_c('b-collapse',{attrs:{"visible":_vm.showTags,"isEditable":_vm.isEditable}},[_c('property-value-tags',{attrs:{"tags":_vm.tagsWithValue,"isEditable":_vm.isEditable,"property":_vm.property,"isSavingDialogue":_vm.isSavingDialogue,"propertyValue":_vm.value}})],1):_vm._e()],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }