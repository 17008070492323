import { render, staticRenderFns } from "./PropertyListV2.vue?vue&type=template&id=408eff8a&scoped=true&"
import script from "./PropertyListV2.vue?vue&type=script&lang=ts&"
export * from "./PropertyListV2.vue?vue&type=script&lang=ts&"
import style0 from "./PropertyListV2.vue?vue&type=style&index=0&id=408eff8a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408eff8a",
  null
  
)

export default component.exports