<template>
    <ul class="class-list pl-0 mb-0">
        <!-- <li class="class-item" v-for="editedClass in editedClasses"
            :key="editedClass.id"
            :class="{
                'new-class': isNewClass(editedClass),
                'obsolete-class': isObsoleteClass(editedClass)
            }">
            <span class="review-btn edited-class-name p-1 rounded"
                  v-b-tooltip.hover="{
                      boundary: 'window',
                      placement: 'top',
                      title: 'Back to changing this class',
                      delay: {show: showDelay, hide: 0}
                  }"
                  @click="onClassReview(editedClass.id)">
                <span :class="{
                        'text-secondary mr-1': !isNewClass(editedClass),
                        'badge badge-pill badge-info': isNewClass(editedClass)
                      }">{{ editedClass.primaryLabel }}</span>
                <span class="text-primary">{{ termID(editedClass) }}</span>
                <font-awesome-icon class="review-hint text-info ml-1" icon="pen" />
            </span> -->
        <edit-list @class:review="onClassReview($event)" />
        <!-- <ul v-if="!isObsoleteClass(editedClass)" class="prop-list">
                <li v-for="editedProp in getSortedPropertyNamesOrIris(editedClass)"
                    :class="`pb-2 ${editedProp}-list`"
                    :key="editedClass.id + '-' + editedProp">

                    <strong :class="{'show-relationship-badge': isNonHierarchyRel(editedProp)}"
                            :data-relationship="editedProp.toUpperCase()[0]">
                        {{ upperFirst(humanReadable(editedProp)) }}
                    </strong>

                    <ul class="history-list">
                        <li
                            v-for="editedValue in getEditedValues(editedClass, editedProp)" class="history-item"
                            :class="{'item-deleted': isPastValue(editedClass.id, editedProp, editedValue)}"
                            :key="historyKey(editedValue)"
                        >
                            <property-value
                                :key="editedValue.propertyValue"
                                class="py-1"
                                :isEditHistory="true"
                                :is-saving-dialogue="true"
                                :propName="editedProp"
                                :propOntoID="editedClass.sourceUniqueID || editedProp.editedClass.sourceUniqueID"
                                :propPrimID="editedClass.primaryID || editedProp.editedClass.primaryID"
                                :value="editedValue.propertyValue || editedValue"
                                :maxLength="propLengthMax"
                                :newClasses="newClasses"
                                :obsoleteClasses="obsoleteClasses"
                                :bulletCharacter="isPastValue(editedClass.id, editedProp, editedValue) ? '–' : '+'"
                                :isNoClassLink="true"
                                :isClassJump="false"
                                :isClass="containsClasses(editedProp)"
                                :isGlobalClassScope="editedProp === 'mappings'"
                                :initClass="containsClasses(editedProp) ? findClass(editedValue) : {primaryID: editedValue}"
                                :isEditable="false"
                                :isTextSearch="editedProp !== 'textualDefinitions'"
                            />
                                <property-value-tags
                                    v-if="editedValue.addedTags"
                                    :edited-tags="editedValue.addedTags"
                                    :property="{ iri: editedProp, name: 'pallo' }"
                                    :isSavingDialogue="true"
                                    :propertyValue="editedValue.propertyValue"
                                    mark-as="added"
                                />

                                <property-value-tags
                                    v-if="editedValue.removedTags"
                                    :edited-tags="editedValue.removedTags"
                                    :property="{ iri: editedProp, name: 'pallo' }"
                                    :isSavingDialogue="true"
                                    :propertyValue="editedValue.propertyValue"
                                    mark-as="removed"
                                />
                        </li>
                    </ul>
                </li>
            </ul> -->
        <!-- </li> -->
    </ul>
</template>

<script>
import {
    difference,
    findIndex,
    intersection,
    sortBy,
    uniqueId,
    without,
} from 'lodash';

import PropertyValue from '@/components/ui/PropertyValue';
import { ANNOTATION_PROPS, RELATIONSHIP_PROPS } from '@/config';
import { useClassView, useEdits } from '@/compositions';
import PropertyValueTags from '@/components/ui/PropertyValueTags';
import EditList from '@/components/ui/EditList';
import { base64Encode } from '@/utils';

const ANNOTATION_WRAPPER = process.env.VUE_APP_ANNOTATION_WRAPPER;
const RELATIONAL_WRAPPER = process.env.VUE_APP_RELATIONAL_WRAPPER;

export default {
    name: 'EditHistory',
    components: { PropertyValue, PropertyValueTags, EditList },
    props: {
        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        propLengthMax: {
            type: Number,
            default: 999999,
        },

        editedClasses: {
            type: Array,
            required: true,
        },

        newClasses: {
            type: Array,
            required: true,
        },

        obsoleteClasses: {
            type: Array,
            required: true,
        },

        editLog: {
            type: Object,
            required: true,
        },

        annotationProps: {
            type: Array,
            default: function () {
                return ANNOTATION_PROPS;
            },
        },

        relationshipProps: {
            type: Array,
            default: function () {
                return RELATIONSHIP_PROPS;
            },
        },

        findClass: {
            type: Function,
            default: function () {
                return (primaryID) => {};
            },
        },
    },
    data() {
        return {
            alreadyDisplayedEditedTags: [],
        };
    },

    computed: {
        classesWithEditedTags: function () {
            return useEdits().getTagChanges.value;
        },
    },
    methods: {
        tagsByPropValue(type, propertyWithEditedTags) {
            const editedTags = propertyWithEditedTags[type];

            const editedTagsByPropValue = {};

            editedTags.forEach((editedTag) => {
                if (!editedTagsByPropValue[editedTag.propertyValue])
                    editedTagsByPropValue[editedTag.propertyValue] = [];

                editedTagsByPropValue[editedTag.propertyValue].push(editedTag);
            });

            return editedTagsByPropValue;
        },
        /**
         * Generates a unique ID for every item in a property's edit history list.
         * @param {string} propValue - Value for the edited property.
         */
        historyKey(propValue) {
            return uniqueId(propValue);
        },

        /**
         * Determines if a given class is newly created.
         * @param {Object} classData - Data object with all properties representative of the class in question.
         */
        isNewClass(classData) {
            let primaryID = classData;

            if (typeof primaryID !== 'string') {
                primaryID = classData.primaryID;
            }

            return findIndex(this.newClasses, { primaryID }) !== -1;
        },

        /**
         * Determines if a given class has been marked as obsolete
         * @param {Object} classData - Data object with all properties representative of the class in question.
         */
        isObsoleteClass(classData) {
            let primaryID = classData;

            if (typeof primaryID !== 'string') {
                primaryID = classData.primaryID;
            }

            return findIndex(this.obsoleteClasses, { primaryID }) !== -1;
        },

        /**
         * Groups and returns tag edits of a given property grouped by property value.
         * @param {string} classId
         * @param {string} propertyIri
         * @return {Record<string, TagEdit>|undefined} Object with key base64 encription of prop value and value array of edited tags.
         */
        getPropertyValuesOfEditedTags(classId, propertyIri) {
            const classTagEdits = useEdits().getTagChanges.value[classId];
            const propertyTagEdits =
                classTagEdits && classTagEdits[propertyIri];

            if (!classTagEdits || !propertyTagEdits) return;

            const tagEditsByPropertyValue = {};
            const editedClass =
                useEdits().getClassesWithTagEdits.value[classId];
            const editTypes = ['addedTags', 'removedTags'];

            editTypes.forEach((editType) => {
                propertyTagEdits[editType] &&
                    propertyTagEdits[editType].forEach((editedTag) => {
                        const encryptedValue = base64Encode(
                            editedTag.propertyValue
                        );

                        if (
                            !Object.keys(tagEditsByPropertyValue).includes(
                                encryptedValue
                            )
                        ) {
                            tagEditsByPropertyValue[encryptedValue] = {
                                addedTags: [],
                                removedTags: [],
                                editedClass,
                                propertyValue: editedTag.propertyValue,
                            };
                        }

                        tagEditsByPropertyValue[encryptedValue][editType].push(
                            editedTag
                        );
                    });
            });

            return tagEditsByPropertyValue;
        },

        /**
         * Builds an array of edited property value strings or edited tags object
         * @param {ClassDataExtended}
         * @param {string} editedPropNameOrIri
         * @return {Array<string|TagChangeByIri>}
         */
        getEditedValues(editedClass, editedPropNameOrIri) {
            if (!this.editLog[editedClass.id]) {
                return this.getPropertyValuesOfEditedTags(
                    editedClass.id,
                    editedPropNameOrIri
                );
            }

            const propsWithEditedValues =
                this.editLog[editedClass.id].history[editedPropNameOrIri];
            const uniquePropsWithEditedValues = [
                ...new Set(propsWithEditedValues),
            ];

            if (useClassView().isSchemaVersion1.value)
                return uniquePropsWithEditedValues;

            const tagsEdits = useEdits().getTagChanges.value;
            const classTagEdits = tagsEdits[editedClass.id];
            const propsWithEditedTags =
                classTagEdits && classTagEdits[editedPropNameOrIri];

            if (!classTagEdits || !propsWithEditedTags)
                return uniquePropsWithEditedValues;

            this.addTagsToEditedValues(
                uniquePropsWithEditedValues,
                propsWithEditedTags,
                editedClass
            );

            return uniquePropsWithEditedValues;
        },

        /**
         * Replace plain strings with TagEdits objects in a given edited values list.
         * Only properties that have tags edits will be replaced.
         * @param {'addedTags'|'removedTags'} changeType
         */
        addTagsToEditedValues(
            uniquePropsWithEditedValues,
            propsWithEditedTags,
            editedClass
        ) {
            const tagEditsByPropertyValue = {};
            const uniquePropsWithEditedValuesCopy = [
                ...uniquePropsWithEditedValues,
            ];

            ['addedTags', 'removedTags'].forEach((changeType) => {
                if (!propsWithEditedTags[changeType]) return;

                propsWithEditedTags[changeType].forEach((tag) => {
                    if (
                        !uniquePropsWithEditedValuesCopy.includes(
                            tag.propertyValue
                        )
                    )
                        return;

                    const valueIndex = uniquePropsWithEditedValues.indexOf(
                        tag.propertyValue
                    );

                    const encryptedValue = base64Encode(tag.propertyValue);

                    if (!tagEditsByPropertyValue[encryptedValue]) {
                        tagEditsByPropertyValue[encryptedValue] = {
                            editedClass,
                            propertyValue: tag.propertyValue,
                        };
                    }

                    if (!tagEditsByPropertyValue[encryptedValue][changeType]) {
                        tagEditsByPropertyValue[encryptedValue][changeType] = [
                            tag,
                        ];
                    } else {
                        tagEditsByPropertyValue[encryptedValue][
                            changeType
                        ].push(tag);
                    }

                    uniquePropsWithEditedValues[valueIndex] =
                        tagEditsByPropertyValue[encryptedValue];
                });
            });
        },

        /**
         * Determines if a given class property value is a previous one.
         * @param {string} classID - Identifier for the class whose property has been edited.
         * @param {string} propName - Identifier for the edited property.
         * @param {string|Object} propValue - Property value to be checked.
         */
        isPastValue(classID, propName, propValue) {
            if (!this.editLog[classID]) return false;

            if (typeof propValue !== 'string')
                propValue = propValue.propertyValue;

            return (
                this.editLog[classID].previous[propName] &&
                this.editLog[classID].previous[propName].indexOf(propValue) !==
                    -1
            );
        },

        /**
         * Determines whether a given property is concerned with classes.
         * @param {string} propName - Identifier for the class property.
         */
        containsClasses(propName) {
            return (
                propName === 'mappings' ||
                propName.indexOf(RELATIONAL_WRAPPER) > -1 ||
                this.relationshipProps.indexOf(propName) > -1
            );
        },

        /**
         * Tests if a given property deals with relationships without direct tree representation.
         * @param {string} propName - Identifier for the class property.
         */
        isNonHierarchyRel(propName) {
            return (
                without(this.relationshipProps, 'superClasses').indexOf(
                    propName
                ) !== -1
            );
        },

        /**
         * Provides the specific order in which the properties should be displayed, with undefined relationship props the last.
         * @param {Object} classData - Data object with all properties representative of the class in question.
         */
        getSortedPropertyNamesOrIris(classData) {
            const propertiesToFilter = ['sourceUniqueID', 'id', 'primaryID'];
            const customAnnoProps = this.flattenProps(
                ANNOTATION_WRAPPER,
                classData
            );
            const annoProps = ['primaryLabel']
                .concat(this.annotationProps)
                .concat(customAnnoProps);
            const customRelProps = this.flattenProps(
                RELATIONAL_WRAPPER,
                classData
            );
            const relProps = sortBy(
                this.relationshipProps.concat(customRelProps),
                (relationPropName) => {
                    return (
                        classData[relationPropName] &&
                        classData[relationPropName].length === 0
                    );
                }
            );

            // If schema version 2 this will only contain relational property names
            /**
             * @TODO IF this.editLog[classData.id] IS NOT DEFINED CONTAINS
             * ONLY TAGS CHANGES, GETS THE IRIS OF THE PROPERTIES THAT CONTAIN THE TAGS CHANGES
             * @type {unknown[]}
             */
            //#region Schema version 1
            if (useClassView().isSchemaVersion1.value) {
                const propertyChanges = intersection(
                    annoProps.concat(relProps),
                    Object.keys(this.editLog[classData.id].history)
                );
                return propertyChanges.filter(
                    (property) => property !== 'primaryLabel'
                );
            }
            //#endregion Schema version 1

            //#region Schema version 2

            //#region Handles property values edits
            if (this.editLog[classData.id]) {
                const propertyChanges = intersection(
                    annoProps.concat(relProps),
                    Object.keys(this.editLog[classData.id].history)
                );
                const iris = difference(
                    Object.keys(this.editLog[classData.id].history),
                    propertyChanges
                );
                return iris
                    .concat(propertyChanges)
                    .filter(
                        (property) => !propertiesToFilter.includes(property)
                    );
            }

            //#endregion Handles property values edits
            return Object.keys(
                useEdits().getTagChanges.value[classData.id]
            ).filter((property) => !propertiesToFilter.includes(property));
            //#endregion Schema version 2
        },

        hasEditedTags(classId, propertyIri, propertyValue) {
            this.alreadyDisplayedEditedTags.push(propertyIri);

            return useEdits().propertyHasTagEdits(
                classId,
                propertyIri,
                propertyValue
            );
        },

        /**
         * Notifies of the review action to the outside world.
         * @param {string} classID - Identifier for the class whose property has been edited.
         */
        onClassReview(classID) {
            this.$emit('class:review', classID);
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

.class-list,
.prop-list,
.history-list {
    list-style: none;
}

.class-list .class-item {
    .edited-class-name {
        display: inline-block;

        .review-hint {
            opacity: 0.2;
        }

        &:hover {
            background: rgba($info, 0.08);
            cursor: pointer;

            .review-hint {
                opacity: 1;
            }
        }
    }

    &:not(:only-child):not(:last-child) {
        margin-bottom: map-get($spacers, 3);
    }
}

.new-class {
    .text-secondary,
    .text-primary {
        vertical-align: middle;
    }
}

.prop-list:not(:empty) {
    margin-top: map-get($spacers, 1);

    .show-relationship-badge:before {
        @include relationship-bubble;
    }
}

.history-item {
    color: $info;

    ::v-deep .stacked-item-bullet {
        margin-top: -0.3rem;
        color: $info;
    }

    ::v-deep .class-name span .global-badge {
        pointer-events: none;
        border: 1px solid $info;
        color: $info;
    }

    ::v-deep .name-termid {
        color: $primary;
    }

    &.item-deleted {
        color: $gray-600;
        font-weight: normal;

        ::v-deep .stacked-item-bullet {
            margin-top: -0.2rem;
            color: $gray-600;
        }

        ::v-deep .class-name span .global-badge {
            pointer-events: none;
            border-color: $gray-600;
            color: $gray-600;
        }

        ::v-deep .name-termid {
            color: $gray-600;
        }
    }
}

.obsolete-class {
    pointer-events: none;

    .edited-class-name {
        * {
            color: $gray-600 !important;
        }

        .text-primary {
            text-decoration: line-through;
        }
    }

    .review-hint {
        display: none;
    }
}
</style>
