<template>
    <span>
        <b-button
            class="text-uppercase align-middle"
            variant="primary"
            size="sm"
            v-b-tooltip.hover="{
                title: `${upperFirst(submitOp)} all changes made in ${
                    $store.getters.ontoData(ontologyID).ontologyShortDisplayName
                }`,
                delay: { show: showDelay, hide: 0 },
            }"
            @click="onRevisionList"
            :class="this.saveButtonClass || ''">
            {{ upperFirst(submitOp) }} {{ ontologyID }}
        </b-button>

        <!-- The modal is re-rendered every time it's opened to avoid a performance hit from background rendering -->
        <b-modal
            id="save-modal"
            no-fade
            centered
            scrollable
            size="lg"
            header-bg-variant="primary"
            header-text-variant="light"
            footer-class="border-0 pt-0 mt-3"
            v-if="localModalShow"
            v-model="localModalShow"
            :class="{ 'hide-close': isLocked }"
            :noCloseOnBackdrop="isLocked"
            :noCloseOnEsc="isLocked"
            :hide-footer="isSummaryOnly"
            :title="`${
                isSummaryOnly
                    ? 'Changes'
                    : `${upperFirst(submitOp)} all changes`
            } to ${hasEdits} ${$pluralize('class', hasEdits)}`">
            <div>
                <b-tabs content-class="mt-3">
                    <b-tab title="Changes"
                        ><p
                            class="save-stats"
                            v-if="
                                obsoleteClasses.length ||
                                newClasses.length ||
                                changedClasses.length
                            ">
                            <span
                                v-if="obsoleteClasses.length"
                                class="save-stats-item text-muted"
                                >{{ obsoleteClasses.length }}
                                <del>obsolete</del>
                                {{
                                    'class' | pluralize(obsoleteClasses.length)
                                }}</span
                            >

                            <span
                                v-if="newClasses.length"
                                class="save-stats-item">
                                <b-badge
                                    pill
                                    variant="info"
                                    class="align-text-top"
                                    >{{ newClasses.length }} new
                                    {{
                                        'class' | pluralize(newClasses.length)
                                    }}</b-badge
                                >
                            </span>

                            <span
                                v-if="changedClasses.length"
                                class="save-stats-item text-info"
                                >{{ changedClasses.length }} updated
                                {{
                                    'class' | pluralize(changedClasses.length)
                                }}</span
                            >
                        </p>
                        <div border-variant="light" bg-variant="light">
                            <slot name="commit-summary"></slot>
                        </div>

                        <div class="reverse-mappings">
                            <br />
                            <b-form-checkbox
                                v-if="hasReverseMappings"
                                id="checkbox-1"
                                v-model="allowReverseMappings"
                                name="checkbox-1">
                                <strong>Create Reverse Mappings?</strong>
                            </b-form-checkbox>
                            <b-card
                                border-variant="light"
                                bg-variant="light"
                                class="reverse-mapping-summary"
                                v-show="
                                    allowReverseMappings && hasReverseMappings
                                ">
                                <ul>
                                    <li
                                        class="reverse-mapping-item"
                                        :key="mapping.ontologyShortName"
                                        v-for="mapping of mappings">
                                        <ReverseMappingValue
                                            :ontoShortName="
                                                mapping.ontologyShortName
                                            "
                                            :ontoLongName="
                                                mapping.ontologyLongName
                                            "
                                            :ontoID="mapping.ontologyId" />
                                    </li>
                                </ul>
                            </b-card></div
                    ></b-tab>
                    <b-tab
                        v-if="validationEnabled"
                        :title="
                            validationLoading
                                ? 'Validating...'
                                : validationReport.value && !validationPassed
                                ? validationHasErrors
                                    ? 'Validation (Failed)'
                                    : 'Validation (Warnings)'
                                : 'Validation Passed'
                        "
                        :active="!validationPassed"
                        :disabled="validationPassed"
                        id="tab-validation">
                        <div class="validation-report" v-if="!validationPassed">
                            <div class="validation-header mb-2">
                                <br />
                                <h4 class="ml-3">Validation Report:</h4>
                                <span class="ml-3"
                                    >Please resolve any errors to continue
                                    saving:
                                    <ValidationPagination
                                        class="validation-pagination float-right"
                                        v-if="
                                            validationNumPages >=
                                            validationCurrentPage.value
                                        "
                                        :current-page="
                                            validationCurrentPage.value
                                        "
                                        :number-pages="validationNumPages"
                                        @page:change="
                                            changeValidationPage($event)
                                        "
                                /></span>
                            </div>
                            <br />
                            <b-card
                                border-variant="light"
                                bg-variant="light"
                                class="validation-report-error"
                                :class="getErrorColor(error.severity)"
                                v-for="(error, index) of validationReport.value"
                                :key="index">
                                <div class="validation-error">
                                    <b-button
                                        class="btn-review-error ml-2 float-right"
                                        size="sm"
                                        @click="reviewError(error.primaryId)"
                                        >{{
                                            error.severity === 'ERROR'
                                                ? 'Resolve'
                                                : 'Review'
                                        }}
                                        Class</b-button
                                    >
                                    <span class="inline">Error Type:</span>
                                    <strong class="error-type"
                                        >&nbsp;{{
                                            error.errorType.replaceAll('_', ' ')
                                        }}
                                        {{ error.severity }}</strong
                                    >
                                    <br />
                                    <span class="inline">Class:</span>
                                    <strong
                                        >&nbsp;{{ error.primaryLabel }} ({{
                                            error.primaryId
                                        }})</strong
                                    >
                                    <br />
                                    <span class="inline">Property:</span>
                                    <strong
                                        >&nbsp;{{
                                            error.propertyPrimaryId
                                        }}</strong
                                    >
                                    <br />
                                    <b-card-text class="mt-1 font-italic">{{
                                        error.message
                                    }}</b-card-text>
                                </div>
                            </b-card>
                        </div>
                        <h6 v-else>Validation Passed Successfully</h6></b-tab
                    >
                </b-tabs>
            </div>

            <div slot="modal-footer" class="w-100">
                <strong>Comment</strong>
                <slot name="commit-comment"></slot>

                <template v-if="submitOp === 'review'">
                    <strong>Review action</strong>
                    <b-form-radio-group
                        v-model="reviewAction"
                        name="review-options"
                        class="mb-2">
                        <b-form-radio
                            value="comment"
                            :class="this.buildTestClass('radio--comment-only')">
                            <span class="text-primary">Comment only</span>
                            <info-icon
                                class="text-secondary"
                                :help-hint="'Re-submit changes without specific approval.'" />
                        </b-form-radio>
                        <b-form-radio
                            value="approve"
                            class="ml-3"
                            :class="this.buildTestClass('radio--approve')">
                            <span class="text-success">Approve</span>
                            <info-icon
                                class="text-secondary"
                                :help-hint="'Submit your comments and save changes.'" />
                        </b-form-radio>
                        <b-form-radio
                            class="ml-3"
                            value="reject"
                            :class="this.buildTestClass('radio--reject')">
                            <span class="text-danger">Reject</span>
                            <info-icon
                                class="text-secondary"
                                :help-hint="'Comments must be addressed before approval.'" />
                        </b-form-radio>
                    </b-form-radio-group>

                    <b-btn
                        class="ml-2 float-right text-uppercase"
                        size="sm"
                        :variant="reviewActionVariant"
                        :disabled="!validationPassed"
                        @click="
                            localModalShow = false;
                            $emit(
                                'edit:submit',
                                reviewAction,
                                allowReverseMappings
                            );
                        "
                        :class="this.buildTestClass('button--review')">
                        Review
                    </b-btn>
                </template>

                <template v-else>
                    <div>
                        <span class="validator" v-if="validationLoading">
                            Validating...
                            <b-spinner
                                label="Validation Check"
                                class="float-right">
                            </b-spinner>
                        </span>
                        <b-btn
                            v-else
                            :disabled="!validationPassed && validationHasErrors"
                            class="ml-2 float-right text-uppercase"
                            size="sm"
                            variant="primary"
                            @click="
                                localModalShow = false;
                                $emit(
                                    'edit:submit',
                                    submitOp,
                                    allowReverseMappings
                                );
                            "
                            :id="this.buildTestClass('button--commit-save')">
                            {{ submitOp }}
                        </b-btn>

                        <b-btn
                            v-if="isSuggestToo && !validationLoading"
                            class="ml-2 float-right text-uppercase"
                            size="sm"
                            variant="secondary"
                            @click="
                                localModalShow = false;
                                $emit('edit:submit', 'suggest');
                            "
                            :id="this.buildTestClass('button--commit-suggest')">
                            Suggest
                        </b-btn>
                    </div>
                </template>

                <b-btn
                    class="float-right"
                    size="sm"
                    variant="light"
                    @click="localModalShow = false"
                    :id="this.buildTestClass('button--commit-cancel')">
                    Cancel
                </b-btn>
            </div>
        </b-modal>
    </span>
</template>

<script>
import { some } from 'lodash';

import InfoIcon from '@/components/ui/InfoIcon';
import {
    useClassTree,
    useEdits,
    useOntology,
    useValidation,
} from '@/compositions';
import {
    compareEditWithCurrent,
    getOntologiesThatAcceptMappingsFromOntologyIdForUser,
    getSuggestionsForTransaction,
} from '@/api-v2';
import ReverseMappingValue from '@/components/ReverseMappingValue';
import store from '@/store';
import ValidationPagination from '@/components/ui/ValidationPagination';
import { ref } from '@vue/composition-api';

export default {
    name: 'CommitModal',
    components: { ReverseMappingValue, InfoIcon, ValidationPagination },
    props: {
        saveButtonClass: {
            type: String,
            default: '',
        },
        ontologyID: {
            type: String,
            default: '',
        },

        errorCount: {
            type: Number,
            default: 0,
        },

        hasEdits: {
            type: Number,
            default: 0,
        },

        selectedClass: {
            type: Object,
            default: function () {
                return {};
            },
        },

        newClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        changedClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        obsoleteClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        // Name of the submit operation.
        submitOp: {
            type: String,
            default: 'suggest',
        },

        // Toggles the modal on or off.
        isModalShow: {
            type: Boolean,
            default: false,
        },

        // Renders only the list of changes.
        isSummaryOnly: {
            type: Boolean,
            default: false,
        },

        // Locks the modal into whatever initial display state it's in.
        isLocked: {
            type: Boolean,
            default: false,
        },

        // If true, shows the suggest button alongside a save.
        isSuggestToo: {
            type: Boolean,
            default: false,
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    model: {
        prop: 'isModalShow',
        event: 'change',
    },

    data() {
        const validationCurrentPage = ref(1);
        const validationReport = ref([]);

        return {
            localModalShow: this.isModalShow,
            // Chosen review option.
            reviewAction: 'comment',
            allowReverseMappings: false,
            mappings: [],

            validationLoading: false,
            validationPassed: true,
            validationReport,
            validationCurrentPage,
        };
    },

    computed: {
        validationEnabled: function () {
            return useOntology().getCurrentOntology.value.validateOntology;
        },

        validationPageSize: function () {
            return useValidation().getPageSize.value;
        },

        validationHasErrors: function () {
            return useValidation().getNumberOfErrors.value > 0 ? true : false;
        },

        validationNumPages: function () {
            return useValidation().getNumPages(this.validationPageSize);
        },

        hasReverseMappings: function () {
            return !!this.mappings.length;
        },

        reviewActionVariant: function () {
            switch (this.reviewAction) {
                case 'approve':
                    return 'success';
                case 'reject':
                    return 'danger';
                default:
                    return 'primary';
            }
        },
    },

    watch: {
        // Resets the state of the review option on modal's closure and trickles changes down
        isModalShow(isOn, wasOn) {
            this.localModalShow = isOn;
            this.bypassClicked = false;

            if (wasOn && !isOn) {
                this.reviewAction = 'comment';
            }
        },

        // Links the local copy of the modal state with the reactive conterpart upstream.
        localModalShow(isOn) {
            this.$emit('change', isOn);
            this.isAdmin();
        },
    },

    methods: {
        changeValidationPage(page) {
            this.validationReport.value = useValidation().getReportByPage(
                page,
                this.validationPageSize
            );

            this.validationCurrentPage.value = page;
        },

        reviewError(id) {
            const classData = useClassTree().getClassTree().value.findClass(id);

            if (!classData) {
                console.warn('Failed to find class');
                return;
            }

            useClassTree().getClassTree().value.nodeSelectByID(classData.id);
            this.localModalShow = false;
        },

        isAdmin() {
            return store.getters.isAdmin;
        },

        getClassChanges(id) {
            const classData = useClassTree().getClassTree().value.findClass(id);
            return useEdits()
                .getListOfClassChanges()
                .value.filter((editedClass) => {
                    return editedClass.ontologyClassId === classData.id;
                });
        },

        getErrorColor: function (error) {
            switch (error) {
                case 'ERROR':
                    return 'error-red';
                case 'WARNING':
                    return 'error-orange';
                default:
                    return 'error-blue';
            }
        },

        async checkValidation() {
            if (!this.validationEnabled) return false;

            this.validationLoading = true;
            setTimeout(async () => {
                const transactionValid =
                    await useValidation().validateCurrentTransaction();

                if (transactionValid === true) {
                    this.validationPassed = true;
                } else if (typeof transactionValid === 'object') {
                    this.validationPassed = false;
                    this.validationReport.value = transactionValid;
                }

                this.validationLoading = false;
            }, 1000);
        },

        async getReverseMappings() {
            const res =
                await getOntologiesThatAcceptMappingsFromOntologyIdForUser({
                    ontologyId: this.ontologyID,
                });

            return res;
        },

        async onSubmitClick() {
            if (useEdits().getEditIsActive.value) {
                const transactionId = useEdits().getTransactionId.value;
                const editedClasses = await getSuggestionsForTransaction({
                    transactionId,
                });

                const promises = editedClasses.map((editData) => {
                    return compareEditWithCurrent({
                        editId: editData.lastOntologyEdit.id,
                        ontologyId: editData.lastOntologyEdit.ontologyId,
                    });
                });

                const resolvedClassEdits = await Promise.all(promises);

                useEdits().setListOfClassChanges(resolvedClassEdits);
                this.mappings = await this.getReverseMappings();
                await this.checkValidation();
            }
        },
        /**
         * Shows the edit confirmation modal if there have been edits without errors or the edits are in progress.
         * Otherwise, the modal is not even shown.
         */
        onRevisionList() {
            if (
                useEdits().hasMergeEditChanges.value &&
                useEdits().countEditedClasses.value
            ) {
                this.$eventBus.$emit(
                    'notification:show',
                    'error',
                    `You currently have a merge edit and a regular edit in the same transaction. Please perform the merge edit in a standalone transaction.`,
                    `Edit errors found`,
                    [],
                    'rightTop'
                );
                return;
            }

            this.onSubmitClick();
            const isSavingProp = some(this.selectedClass.editState, 'isSaving');

            if (this.errorCount) {
                this.$eventBus.$emit(
                    'notification:show',
                    'warning',
                    `${this.errorCount} property changes have errors. Please review them before saving.`,
                    `Property errors found`,
                    [],
                    'rightTop'
                );
            } else if (this.hasEdits || isSavingProp) {
                this.$emit('change', true);
            } else {
                this.$emit('edit:cancel');
                this.$eventBus.$emit(
                    'notification:show',
                    'info',
                    'No changes have been made to any class in the ontology.',
                    ''
                );
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

#save-modal {
    .save-stats-item {
        vertical-align: middle;

        & ~ .save-stats-item:before {
            content: ', ';
        }
    }

    .card {
        overflow-y: auto;
    }
}

.hide-close ::v-deep .close {
    display: none;
}

::v-deep .modal-body {
    display: flex;
    flex-direction: column;
}

::v-deep .reverse-mapping-summary {
    max-height: 100px;
}

.reverse-mapping-item {
    font-weight: bold;
}

.validator {
    float: right;
}

.validation-report {
    top: -8rem;
}

.validation-report-error {
    margin-bottom: 1.5rem;
    border: 2px solid;

    .btn-review-error {
        background-color: $primary;
        color: black;
        border: none;
    }
}

.validation-pagination {
    margin-top: -0.5rem;
}

.error-red {
    border-color: red !important;
    background-color: rgba(183, 49, 44, 0.1) !important;
}
.error-orange {
    border-color: orange !important;
    background-color: rgba(247, 172, 80, 0.1) !important;
}
.error-blue {
    border-color: blue !important;
    background-color: rgba(51, 134, 193, 0.1) !important;
}
</style>
