var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"onto-breadcrumb"},[_c('b-breadcrumb',{staticClass:"mb-0",on:{"click":_vm.onCrumbClick}},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'ontologies-entry' }}},[_vm._v("Ontologies")]),_c('b-breadcrumb-item',[_c('b-dropdown',{staticClass:"onto-options",attrs:{"id":"dropdown-onto-options","size":"sm","split":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('router-link',{attrs:{"id":"router-link-ontology-id","to":{ name: 'class', params: { ontologyID: _vm.ontologyID } }}},[_vm._v(_vm._s(_vm.$store.getters .ontoData(_vm.ontologyID) .ontologyShortDisplayName.toUpperCase()))])]},proxy:true}])},[_c('b-dropdown-item',{staticClass:"btn--ontology-summary",attrs:{"to":{ name: 'class', params: { ontologyID: _vm.ontologyID } }}},[_vm._v("Ontology Summary")]),_c('b-dropdown-item',{on:{"click":_vm.onClickRules}},[_vm._v("View Design Pattern")]),_c('div',[(_vm.ontologyID.trim() !== '')?_c('ExportOntology',{attrs:{"ontology-id":_vm.ontologyID,"id":'list-button'}}):_vm._e()],1),_c('b-dropdown-item',{attrs:{"to":{
                        name: 'sparql-ontology',
                        params: { ontologyID: _vm.ontologyID },
                    }}},[_vm._v("SPARQL Editor")])],1)],1),(!_vm.isEditModeAltered)?_c('b-breadcrumb-item',[_c('b-dropdown',{staticClass:"type-select",attrs:{"id":"dropdown-type-select","size":"sm","split":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('strong',[_vm._v("Classes")])]},proxy:true}],null,false,3102301427)},[_c('b-dropdown-item',{staticClass:"menu-item",attrs:{"active":""}},[_vm._v("Classes")]),_c('b-dropdown-item',{staticClass:"menu-item",attrs:{"to":{ name: 'properties', params: { ontologyID: _vm.ontologyID } }}},[_vm._v("Properties")]),_c('b-dropdown-item',{staticClass:"menu-item",attrs:{"to":{ name: 'instances', params: { ontologyID: _vm.ontologyID } }}},[_vm._v("Instances")])],1)],1):_vm._e(),_c('b-breadcrumb-item',{staticClass:"breadcrumb--typeahead"},[_c('class-typeahead',{directives:[{name:"show",rawName:"v-show",value:(_vm.isClassTypeahead),expression:"isClassTypeahead"}],ref:"typeahead",attrs:{"placeholder":("Class within " + (_vm.$store.getters.ontoData(_vm.ontologyID)
                        .ontologyShortDisplayName)),"approximate-title":("NO CLASSES FOUND WITHIN " + (_vm.$store.getters.ontoData(_vm.ontologyID)
                        .ontologyShortDisplayName) + ". Did you mean..."),"matched-title":'CLASSES WITHIN ' +
                    _vm.$store.getters.ontoData(_vm.ontologyID)
                        .ontologyShortDisplayName,"disabled":_vm.isReadonly,"isAutofocus":false,"suggestion-max-matches":_vm.suggestionMax,"fetchSuggestions":_vm.fetchTypeaheadList,"performAction":_vm.goTypeahead,"forceClass":true},on:{"hit":_vm.onTypeaheadHit},scopedSlots:_vm._u([{key:"inset",fn:function(typeahead){return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(
                            !_vm.isReadonly &&
                            typeahead.query &&
                            typeahead.query === _vm.currClassID
                        ),expression:"\n                            !isReadonly &&\n                            typeahead.query &&\n                            typeahead.query === currClassID\n                        "}],staticClass:"reset-btn inset-btn",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$emit('breadcrumb:reload')}}},[_c('font-awesome-icon',{attrs:{"icon":"undo-alt","size":"sm"}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(
                            !_vm.isReadonly &&
                            typeahead.query &&
                            typeahead.query !== _vm.currClassID
                        ),expression:"\n                            !isReadonly &&\n                            typeahead.query &&\n                            typeahead.query !== currClassID\n                        "}],staticClass:"inset-btn",attrs:{"variant":"link","disabled":!_vm.isValidClass(
                                typeahead.query,
                                typeahead.results
                            )},on:{"click":function($event){return _vm.$refs.typeahead.onAction(typeahead.query)}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-right","size":"sm"}})],1)]}}])},[_c('template',{slot:"view-all"},[_c('b-button',{staticClass:"view-all-btn rounded-0 border-0",attrs:{"variant":"outline-primary"},on:{"mousedown":function($event){$event.preventDefault();return _vm.onViewSearch(_vm.$refs.typeahead.query)}}},[_c('small',[_vm._v("View all results in "+_vm._s(_vm.$store.getters.ontoData(_vm.ontologyID) .ontologyShortDisplayName))])])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }