var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
        boundary: 'window',
        title: _vm.selectedOntologiesToShortDisplayNames,
        delay: { show: _vm.showDelay, hide: 0 },
    }),expression:"{\n        boundary: 'window',\n        title: selectedOntologiesToShortDisplayNames,\n        delay: { show: showDelay, hide: 0 },\n    }",modifiers:{"hover":true}}],ref:"ontoFilterDropdown",attrs:{"variant":"gray","no-flip":"","id":"onto-filter-toggle","menu-class":"mt-1 py-0 shadow"}},[_c('template',{slot:"button-content"},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"onto-filter"},[_c('b-dropdown-header',{staticClass:"onto-filter-title pr-4"},[_c('span',{staticClass:"text-light filter-title"},[_vm._v(_vm._s(_vm.filterTitle))])]),_c('div',{staticClass:"p-2"},[_c('b-input',{staticClass:"p-2",attrs:{"type":"text","placeholder":"search ontologies..."},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('b-form-checkbox-group',{ref:"ontoFilterList",staticClass:"px-3 pt-2 pb-1 onto-filter-list",attrs:{"stacked":""},model:{value:(_vm.selectedOntologies),callback:function ($$v) {_vm.selectedOntologies=$$v},expression:"selectedOntologies"}},_vm._l((_vm.filteredOntologies),function(ontology){return _c('b-form-checkbox',{key:ontology.ontologyUniqueID,class:_vm.buildTestClass(
                        'checkbox--onto-filter' + ontology.ontologyUniqueID
                    ),attrs:{"value":ontology}},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                        boundary: 'window',
                        title: ontology.ontologyShortDisplayName,
                        delay: { show: _vm.showDelay, hide: 0 },
                    }),expression:"{\n                        boundary: 'window',\n                        title: ontology.ontologyShortDisplayName,\n                        delay: { show: showDelay, hide: 0 },\n                    }",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(ontology.ontologyShortDisplayName)+" ")])])}),1),_c('b-button',{staticClass:"onto-filter-reset m-2",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.clearAllOntologies}},[_vm._v(" Clear all ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }