var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-class-changes-2"},_vm._l((_vm.annotationPropertyChanges),function(classChange){return _c('b-card',{key:classChange.shortFormId,staticClass:"class-edit",class:{ 'obsolete-class': classChange.isObsolete },attrs:{"border-variant":"light","bg-variant":"light"}},[_c('b-button',{staticClass:"btn-review-class ml-2 float-right",attrs:{"size":"sm"},on:{"click":function($event){return _vm.onClickReview(classChange)}}},[_vm._v("Review Class")]),_c('h6',[_vm._v("Class: "+_vm._s(classChange.primaryLabel))]),_vm._v("   "),_c('b',{staticClass:"text-primary shortformid-text"},[_vm._v(" "+_vm._s(classChange.shortFormId)+" ")]),_c('ul',[_vm._l((_vm.relationalPropertyChanges(classChange)),function(relationalProperty,index){return _c('li',{key:(index + "-relational-property")},[_c('h6',{staticClass:"iri"},[_vm._v(" "+_vm._s(_vm.nameFromIri(relationalProperty.value) || relationalProperty.value)+" ")]),_c('ul',[_c('li',{staticClass:"field-name",class:((relationalProperty.fieldName) + "-list")},[_c('span',[_vm._v(" "+_vm._s(_vm.upperFirst( _vm.humanReadable( relationalProperty.fieldName ) ))+" ")])]),_c('ul',[(relationalProperty.primaryLabelNewValue)?_c('li',{staticClass:"add value"},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v(" "+_vm._s(relationalProperty.primaryLabelNewValue)+" ")])],1):_vm._e(),(relationalProperty.primaryLabelOldValue)?_c('li',{staticClass:"remove value"},[_c('font-awesome-icon',{attrs:{"icon":"minus"}}),_c('span',[_vm._v(" "+_vm._s(relationalProperty.primaryLabelOldValue)+" ")])],1):_vm._e()])])])}),_vm._l((classChange.propertyValueChanges),function(propertyValueChange,propertyValueChangeIndex){return _c('li',{key:propertyValueChangeIndex,staticClass:"mt-1",class:{
                    remove:
                        propertyValueChange.typeOfFieldChange === 'REMOVE',
                    add:
                        propertyValueChange.typeOfFieldChange === 'ADD' ||
                        'CHANGE',
                }},[_c('h6',{staticClass:"iri"},[_vm._v(" "+_vm._s(_vm.nameFromIri(propertyValueChange.owner.iri) || propertyValueChange.owner.iri)+" ")]),_c('ul',[_c('li',{staticClass:"mt-1 iri-value"},[_c('font-awesome-icon',{attrs:{"icon":propertyValueChange.typeOfFieldChange ===
                                'REMOVE'
                                    ? 'minus'
                                    : 'plus'}}),_vm._v("   "),_c('span',[_vm._v(" "+_vm._s(propertyValueChange.owner.value)+" ")])],1),(propertyValueChange.addedTags)?_c('ul',_vm._l((propertyValueChange.addedTags),function(tag,tagIndex){return _c('li',{key:(propertyValueChangeIndex + "-addedTags-" + tagIndex),staticClass:"add value"},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_c('span',{staticClass:"tag-type badge"},[_vm._v(" "+_vm._s(tag.type)+" ")]),_vm._v("   "),_c('span',[_vm._v(" "+_vm._s(tag.value)+" ")])],1)}),0):_vm._e(),(propertyValueChange.removedTags)?_c('ul',_vm._l((propertyValueChange.removedTags),function(tag,tagIndex){return _c('li',{key:(propertyValueChangeIndex + "-removedTags-" + tagIndex),staticClass:"remove value"},[_c('font-awesome-icon',{attrs:{"icon":"minus"}}),_vm._v("   "),_c('span',{staticClass:"tag-type badge"},[_vm._v(" "+_vm._s(tag.type)+" ")]),_vm._v("   "),_c('span',[_vm._v(" "+_vm._s(tag.value)+" ")])],1)}),0):_vm._e()])])})],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }