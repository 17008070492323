<template>
    <!-- No class or suggestion was found -->
    <div class="not-found">
        <h4 class="text-secondary d-flex align-items-center">
            <font-awesome-icon icon="info-circle" size="2x" />
            <span class="pl-3">
                Sorry, we couldn't find any
                {{
                    isSuggestionMode
                        ? 'pending suggestion'
                        : 'matching ontology class'
                }}
            </span>
        </h4>

        <!-- The suggestion exists but the required permissions don't -->
        <template
            v-if="
                isSuggestionMode &&
                isActiveSuggestion &&
                !$store.getters.canChange(ontologyID)
            ">
            <h5>
                You have no edit or suggest permissions for
                {{
                    $store.getters.ontoData(ontologyID)
                        .ontologyShortDisplayName
                }}.
            </h5>
            <p>
                Please, contact the administrator if you need your permissions
                to be changed.
            </p>
        </template>

        <!-- The suggestion exists but has already been approved or withdrawn -->
        <template
            v-else-if="
                isSuggestionMode && !isActiveSuggestion && hasSuggestion
            ">
            <h5>
                This suggestion appears to have been {{ suggestionStatus }}.
            </h5>
            <p>Please, contact the editor to check the suggestion's state.</p>
        </template>

        <!-- The ontology exists and is loaded -->
        <template
            v-else-if="
                $store.getters.hasOntoIndex &&
                $store.getters.hasOnto(ontologyID)
            ">
            <h5>Troubleshooting tips</h5>
            <ul class="mb-4">
                <li v-if="ontologyID">
                    <span class="d-block"
                        >Check the ontology ID is correct:</span
                    >
                    &quot;<strong>{{ ontologyID }}</strong
                    >&quot;.
                </li>
                <li v-if="!isSuggestionMode">
                    <span class="d-block">Check also the primary ID:</span>
                    &quot;<strong class="word-break">{{ primaryID }}</strong
                    >&quot;
                </li>

                <li>
                    <b-link to="/ontologies">Check</b-link> that
                    <strong>{{ ontologyID }}</strong> is loaded.
                </li>
            </ul>
            <h5>Help us improve {{ appName }}</h5>
            <p>
                <b-link rel="external" target="_blank" :href="helpUrl"
                    >Report</b-link
                >
                <template v-if="!isSuggestionMode">
                    the unmatched class if you think it should be included in
                    the database.
                </template>
                <template v-else>
                    the unmatched suggestion if you think this is a bug.
                </template>
            </p>
        </template>

        <!-- The ontology may not be loaded -->
        <template v-else-if="ontologyID.trim().length">
            <h5>The {{ ontologyID }} ontology has not been loaded yet.</h5>
            <p>
                You can load specific ontologies on the
                <b-link to="/ontologies">ontologies index page</b-link>.
            </p>
        </template>

        <!-- The blank ontology is just a placeholder => suggestion is gone for unknown reasons -->
        <template v-else-if="isSuggestionMode">
            <h5>No entry was found for this suggestion.</h5>
            <p>
                Please, contact the administrator to confirm if the suggestion
                has been removed.
            </p>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ClassError',
    props: {
        isSuggestionMode: {
            type: Boolean,
            required: true,
        },

        hasSuggestion: {
            type: Boolean,
            required: true,
        },

        isActiveSuggestion: {
            type: Boolean,
            required: true,
        },

        suggestionStatus: {
            type: String,
            required: true,
        },

        primaryID: {
            type: String,
            required: true,
        },

        ontologyID: {
            type: String,
            required: true,
        },

        // URL for the helpdesk site
        helpUrl: {
            type: String,
            default: process.env.VUE_APP_HELP_URL,
        },

        appName: {
            type: String,
            default: process.env.VUE_APP_NAME,
        },
    },
};
</script>

<style scoped lang="scss">
.not-found {
    min-width: 300px;
    max-width: 500px;
    width: 60%;
    margin: 0 auto;

    h4 {
        margin: 1rem auto 2rem;
        font-weight: 400;
    }

    a {
        font-weight: 500;
    }

    @media (min-height: 600px) {
        position: fixed;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
