import {
    FlatFileFormats,
    OwlFormats,
    SkosFormats,
    SnapShotFormats,
    TermiteFormats,
} from './ontoexportfileformats';

export interface OntoExportLookup {
    OWL: OwlFormats[];
    SKOS: SkosFormats[];
    TERMITE: TermiteFormats[];
    FLATFILE: FlatFileFormats[];
    SNAPSHOTS: SnapShotFormats[];
}

export interface VocabExportLookup {
    TERMITE: TermiteFormats[];
    SNAPSHOTS: SnapShotFormats[];
}

export const ontoExportLookup: Readonly<OntoExportLookup> = {
    OWL: [
        'JSONLD',
        'MOS',
        'N3',
        'OBO',
        'OWLFUNC',
        'OWLXML',
        'RDFXML',
        'TURTLE',
    ],
    SKOS: ['N3', 'RDFXML', 'TURTLE', 'NTRIPLES', 'RDFXML_ABBREV'],
    TERMITE: [
        'SKOS_TERMITE_VOCAB',
        'SKOS_TERMITE_AUG',
        'TERMITE_VOCAB',
        'TERMITE_AUG',
    ],
    FLATFILE: ['CSV', 'TSV', 'QTT_CSV', 'QTT_CSV_TEMPLATE'],
    SNAPSHOTS: ['SNAPSHOT'],
};

export const vocabExportLookup: Readonly<VocabExportLookup> = {
    TERMITE: ['SKOS_TERMITE_AUG', 'TERMITE_AUG'],
    SNAPSHOTS: ['SNAPSHOT'],
};
