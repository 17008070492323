<template>
    <span>
        <b-input-group class="wiki-textarea">
            <b-button
                class="autofill-btn px-1"
                variant="info"
                size="sm"
                slot="prepend"
                v-b-tooltip.hover="{
                    title: 'Autofill with description from Wikipedia',
                    delay: { show: showDelay, hide: 0 },
                }"
                :class="{ 'loading-pulse': loading }"
                :disabled="!defCount"
                @click.prevent="onAutofill(wikiText)">
                <span class="wikipedia-logo"></span>
            </b-button>
            <b-form-textarea
                ref="textarea"
                class="definition-area"
                placeholder="Definition..."
                rows="2"
                max-rows="10"
                v-model="currText"
                :key="renderCount"
                :class="buildTestClass('textarea')" />
            <b-button
                class="add-btn"
                variant="info"
                size="sm"
                slot="append"
                :class="buildTestClass('add-button')"
                @click.prevent="
                    $emit('add', currText);
                    clear();
                ">
                <font-awesome-icon icon="plus" class="align-middle" />
            </b-button>
        </b-input-group>
        <small class="text-muted">
            <template v-if="loading">
                Loading Wikipedia definitions...
            </template>
            <template v-else-if="defCount">
                <b-button
                    class="definition-link p-0 border-0 text-truncate d-inline-block w-100 text-left"
                    variant="link"
                    size="sm"
                    @click="onAutofill(wikiText)">
                    From Wikipedia: {{ addFullStop(wikiDesc) }}
                </b-button>
            </template>
            <template v-else> No Wikipedia entry found. </template>
        </small>

        <b-modal
            id="definitions-modal"
            no-fade
            centered
            scrollable
            header-bg-variant="primary"
            header-text-variant="light"
            footer-class="border-0 pt-0"
            size="lg"
            v-model="isDefListShown"
            :title="`${defCount} definitions found`">
            <div v-html="definitionsHtml" @click="onDefinitionSelect" />
            <div slot="modal-footer" class="w-100 pt-3">
                <b-btn
                    class="float-right"
                    size="sm"
                    variant="light"
                    @click="isDefListShown = false">
                    Cancel
                </b-btn>
            </div>
        </b-modal>
    </span>
</template>

<script>
import ApiEdit from '@/api/edit';

export default {
    name: 'WikiTextarea',
    props: {
        term: {
            type: String,
            default: '',
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    data() {
        return {
            // Number of definitions found
            defCount: 0,

            // Selected definition from Wikipedia
            wikiText: '',

            // Description of the results from Wikipedia
            wikiDesc: '',

            // Text the text area is currently set to
            currText: '',

            // Flags if the confirm modal is on display before the actual save operation.
            isDefListShown: false,

            // Markup for list of possible definitions
            definitionsHtml: '',

            // Allows re-rendering of the component
            renderCount: 0,

            // Shows feedback while loading if true
            loading: false,
        };
    },

    watch: {
        term: {
            handler: 'onTermChange',
            immediate: true,
        },
    },

    methods: {
        /**
         * Grabs the wikipedia definition and resets the contents of the textarea.
         * @param {string} newTerm - Current value to which the term changed.
         */
        onTermChange(newTerm) {
            this.fetchWikiText(newTerm).then((data) => {
                this.wikiDesc = data.description;
                this.defCount = data.defCount;

                if (this.defCount > 1) {
                    this.wikiText = '';
                    this.definitionsHtml = data.html;
                } else {
                    this.wikiText = this.wikiDesc;
                    this.definitionsHtml = '';
                }
            });
            this.clear();
        },

        /**
         * Grabs the definition available for a given piece of text, sanitising it as needed.
         * @param {string} text - Term to be looked up on Wikipedia.
         */
        fetchWikiText(text) {
            this.loading = true;

            return ApiEdit.wikipedia({ text })
                .then((html) => {
                    const definitionEl = document.createElement('div');
                    let paragraphEls;
                    let defCount = 0;
                    let description = '';

                    if (html) {
                        // Ignores empty or spurious paragraphs, extracting the text of the first valid one.
                        definitionEl.innerHTML = html;
                        paragraphEls = Array.prototype.slice.call(
                            definitionEl.getElementsByTagName('p')
                        );
                        description = paragraphEls.find((el) =>
                            el.textContent.trim()
                        ).textContent;

                        // It's probably a list of possible meanings => count them and show that as a description
                        if (/.+may refer to:/.test(description)) {
                            defCount =
                                definitionEl.querySelectorAll('li').length;
                            description = defCount + ' definitions found';

                            // Removes markup and styling only relevant in the original disambiguation page
                            html = html.replace(
                                /<h2>.*id="see_also"[\s\S]*/i,
                                ''
                            );
                            html = html.replace(/(<i>|<\/i>)/g, '');

                            // Only one definition available
                        } else {
                            defCount = 1;

                            // Flattens any list immediately following the description and tacks it onto the latter.
                            if (
                                /.+:/.test(description) &&
                                definitionEl.children[1].tagName === 'UL'
                            ) {
                                description +=
                                    definitionEl.children[1].textContent;
                            }
                        }

                        // Removes the brackets surrounding phonetic hints and unnecessary whitespaces.
                        description = description
                            .replace(/\s{2,}/g, ' ')
                            .replace('()', '')
                            .trim();
                    }

                    return { description, html, defCount };
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Fill in the textarea with whatever wikipedia definition has been found. It also
         * forces resizing of the container.
         * @param {string} definition - Text to be copied to the textarea.
         */
        onAutofill(definition) {
            // A definition has been selected
            if (definition) {
                this.currText = definition;
                this.renderCount++;

                // Multiple possible definitions
            } else {
                this.isDefListShown = true;
            }
        },

        /**
         * Autofills textarea with any text within any entry in the definitions list that has
         * been clicked on.
         * @param {string} event - DOM object for the click event.
         */
        onDefinitionSelect(event) {
            const targetEl = event.target;

            if (targetEl.tagName === 'LI') {
                this.onAutofill(targetEl.firstChild.nodeValue);
                this.isDefListShown = false;
            }
        },

        clear() {
            this.currText = '';
            this.renderCount++;
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.autofill-btn {
    padding-top: 0.72rem;

    &:not(.disabled):hover {
        color: $warning;
    }

    .wikipedia-logo {
        font-size: 1.2em;
    }
}

.definition-area {
    padding: 0.35em 0.5em;
    box-shadow: none !important;

    &:focus {
        border-color: rgba($info, 0.6);
    }
}

.definition-link {
    font-size: inherit;
    color: inherit;
    text-decoration: none !important;

    &:not(:hover) {
        color: $info;
    }
}

::v-deep #definitions-modal {
    top: 100px;
    height: 80%;

    h2,
    h3 {
        color: $secondary;
    }

    h2 {
        font-size: 1.4em;
    }

    h3 {
        font-size: 1em;
    }

    li:hover {
        background: $mark-bg;
        cursor: pointer;

        ul {
            background: $white;
        }
    }
}

.loading-pulse {
    border-color: transparent !important;
    @include pulse-bg-animation;
}
@include pulse-bg-keyframes($info);
</style>
