<template>
    <div
        class="graph-container position-relative"
        :class="{
            'node-dragging': isNodeDragging,
            'hide-link-labels': !isLinkLabels,
        }"
        :style="{ width: `${width}px`, height: `${height}px` }">
        <div
            class="top-toolbar graph-toolbar position-absolute d-flex justify-content-between w-100">
            <!-- Relationship settings -->
            <div class="relationship-controls">
                <b-button
                    variant="outline-secondary"
                    size="sm"
                    @click.prevent.stop="isLinkLabels = !isLinkLabels">
                    Relationship labels
                    <font-awesome-icon
                        class="align-text-bottom"
                        :icon="isLinkLabels ? 'toggle-on' : 'toggle-off'" />
                </b-button>

                <b-form-checkbox-group
                    class="relationships-filter-list position-absolute pt-1"
                    stacked
                    v-model="selRelationships">
                    <b-form-checkbox
                        v-for="relType in relationshipTypes"
                        :class="`${relType}-checkbox pt-1`"
                        :value="relType"
                        :key="`${relType}-checkbox`"
                        onclick="return false">
                        <!--                        return false stops the checkbox from being tickable-->
                        {{ upperFirst(humanReadable(relType)) }}
                    </b-form-checkbox>
                </b-form-checkbox-group>
            </div>

            <b-button
                variant="outline-secondary"
                size="sm"
                class="animations-btn"
                @click.prevent.stop="isAnimation = !isAnimation">
                Animations
                <font-awesome-icon
                    class="align-text-bottom"
                    :icon="isAnimation ? 'toggle-on' : 'toggle-off'" />
            </b-button>

            <!-- Zoom -->
            <b-button
                variant="outline-secondary"
                size="sm"
                class="zoom-scroll"
                @click.prevent.stop="isZoomScroll = !isZoomScroll">
                Zoom scroll
                <font-awesome-icon
                    class="align-text-bottom"
                    :icon="isZoomScroll ? 'toggle-on' : 'toggle-off'" />
            </b-button>

            <div class="zoom-controls d-flex flex-column position-absolute">
                <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="zoom-in"
                    v-b-tooltip.hover="{
                        title: 'Zoom in',
                        placement: 'left',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click.prevent.stop="panZoom.zoomIn()">
                    <font-awesome-icon icon="plus" />
                </b-button>

                <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="zoom-reset my-1"
                    v-b-tooltip.hover="{
                        title: 'Reset zoom and panning',
                        placement: 'left',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click.prevent.stop="onResetZoomPan">
                    <font-awesome-icon icon="undo-alt" />
                </b-button>

                <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="zoom-out"
                    v-b-tooltip.hover="{
                        title: 'Zoom out',
                        placement: 'left',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click.prevent.stop="panZoom.zoomOut()">
                    <font-awesome-icon icon="minus" />
                </b-button>
            </div>
        </div>

        <div
            class="bottom-toolbar graph-toolbar position-absolute d-flex justify-content-between w-100">
            <!-- Class controls -->
            <div class="class-controls">
                <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="pinning-btn"
                    :class="{ unpinned: isResetOnFocus }"
                    @click.prevent.stop="isResetOnFocus = !isResetOnFocus">
                    Preserve classes
                    <font-awesome-icon
                        class="align-text-bottom"
                        :icon="isResetOnFocus ? 'toggle-off' : 'toggle-on'" />
                </b-button>

                <b-button
                    variant="outline-secondary"
                    size="sm"
                    v-b-tooltip.hover="{
                        title: 'Removes classes without direct relationship with the selected one',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click.prevent.stop="!isVicinity && onReset()">
                    <font-awesome-icon
                        icon="filter"
                        class="align-text-bottom" />
                    Only vicinity
                </b-button>
            </div>

            <b-button
                variant="outline-secondary"
                size="sm"
                class="path-btn"
                v-b-tooltip.hover="{
                    title: 'Renders all ancestors in the path for the selected class',
                    delay: { show: showDelay, hide: 0 },
                }"
                @click.prevent.stop="$emit('graph:rootPath')">
                Path from root
            </b-button>

            <!-- Other -->
            <b-button
                variant="outline-secondary"
                size="sm"
                class="d-none"
                @click.prevent.stop="onScreenshot">
                <font-awesome-icon icon="camera" class="align-middle" />
                Save as PNG
            </b-button>
        </div>

        <!-- The actual network graph -->
        <graph
            ref="graph"
            :net-nodes="nodes"
            :net-links="links"
            :selection="selected"
            :options="options" />

        <!-- Dynamic styling for graph elements -->
        <svg width="0" height="0" class="position-absolute">
            <defs>
                <!-- Top of the arrow in links -->
                <marker
                    v-for="relationshipType in relationshipTypes"
                    :key="relationshipType"
                    :id="`graph-m-end-${relationshipType}`"
                    :refX="options.nodeSize / 2"
                    refY="3"
                    orient="auto"
                    markerWidth="10"
                    markerHeight="10"
                    markerUnits="strokeWidth">
                    <path d="M0,0 L0,6 L9,3 z"></path>
                </marker>

                <!-- Subtle background for node label legibility -->
                <filter
                    x="0"
                    y="0"
                    width="1"
                    height="1"
                    id="graph-label-background">
                    <feFlood />
                    <feComposite in="SourceGraphic" />
                </filter>

                <!-- Adds a background for root nodes -->
                <filter primitiveUnits="objectBoundingBox" id="graph-root-node">
                    <feImage :href="rootMarkPath" preserveAspectRatio="none" />
                    <feBlend mode="normal" in2="SourceGraphic" />
                    <feComposite operator="in" in2="SourceGraphic" />
                </filter>
            </defs>
        </svg>

        <!-- Class summary for each node -->
        <template v-if="!isBusy">
            <b-popover
                v-for="node in nodes"
                placement="bottom"
                triggers="hover"
                boundary="viewport"
                :key="node.classId"
                :title="node.id"
                :target="`graph-${node.classId}`"
                :delay="{ show: showDelay, hide: 0 }">
                <class-name
                    :isShortID="false"
                    :isSummary="false"
                    :isOntoBadge="true"
                    :isObsolete="isObsolete(resolvedClasses[node.id])"
                    :isNew="isNew(resolvedClasses[node.id])"
                    :ontologyID="resolvedClasses[node.id].sourceUniqueID"
                    :primaryID="resolvedClasses[node.id].primaryID"
                    :initClass="resolvedClasses[node.id]" />

                <class-summary
                    v-bind="resolvedClasses[node.id]"
                    :isNew="isNew(resolvedClasses[node.id])"
                    :isObsolete="isObsolete(resolvedClasses[node.id])" />
            </b-popover>
        </template>
    </div>
</template>

<script>
import {
    compact,
    debounce,
    filter,
    find,
    findIndex,
    flatten,
    get,
    intersection,
    isEmpty,
    isEqual,
    isNumber,
    map,
    pick,
    times,
    union,
    uniqBy,
    values,
} from 'lodash';
import panZoom from 'svg-pan-zoom';

import Graph from 'vue-d3-network';
import ClassName from '@/components/ui/ClassName';
import ClassSummary from '@/components/ui/ClassSummary';
import { ANNOTATION_PROPS, RELATIONSHIP_PROPS } from '@/config';
import { useClassView } from '@/compositions';

export default {
    name: 'ClassGraph',
    components: {
        Graph,
        ClassName,
        ClassSummary,
    },

    props: {
        // Dimensions in pixels for both the graph and its container
        width: {
            type: Number,
            required: true,
        },
        height: {
            type: Number,
            required: true,
        },

        // Char limit for a class' primary label
        maxCharLabel: {
            type: Number,
            default: 20,
        },

        // True if the graph markup is rendered and on display.
        // NOTE: Pan and zoom is glitchy on Firefox while the graph is hidden.
        isVisible: {
            type: Boolean,
            default: false,
        },

        // True if interactive changes to class data are allowed
        isEditMode: {
            type: Boolean,
            default: false,
        },

        // Focused class
        // classData: {
        //     type: Object,
        //     required: true
        // },

        newClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        // Set of primary IDs already resolved to class data each
        resolvedClasses: {
            type: Object,
            default: function () {
                return {};
            },
        },

        // Name of the wrapper class property for all custom relationships
        relationshipWrapper: {
            type: String,
            default: process.env.VUE_APP_RELATIONAL_WRAPPER,
        },

        // Excludes custom relationship property names
        stdRelationshipProps: {
            type: Array,
            default: function () {
                return RELATIONSHIP_PROPS;
            },
        },

        // All relationship property names, including custom ones.
        relationshipProps: {
            type: Array,
            default: function () {
                return this.stdRelationshipProps;
            },
        },

        // All annotation property names, including custom ones.
        annotationProps: {
            type: Array,
            default: function () {
                return ANNOTATION_PROPS;
            },
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    data() {
        return {
            // Previous position of any dragged node
            prevDraggedPos: {},

            // Position delta thresholds for considering mousedown a drag
            deltaDrag: { x: 7, y: 7 },

            // True if the graph nodes and links are being built.
            isBusy: false,

            // True if any node in the graph is in the "move" phase.
            isNodeDragging: false,

            // True if any nodes have been dragged
            isNodesDragged: false,

            // True if difference between initial and final dragging positions is negligible
            isLegitClick: false,

            // Resets graph nodes and links on class focus
            isResetOnFocus: true,

            // True if all stepped values of forces are to be rendered
            isAnimation: true,

            // Labels are always in the DOM but can be hidden
            isLinkLabels: false,

            // True if mouse wheel allowed to trigger zooming
            isZoomScroll: false,

            // Graph setup options: dims set to parent's, no centering force
            options: {
                size: { w: '100%', h: '100%' },
                nodeSize: 26,
                fontSize: 14,
                linkWidth: 3,
                force: 6500,
                canvas: false,
                nodeLabels: true,
                linkLabels: true,
                resizeListener: false,
            },

            // Array of universal IDs and labels for the graph
            nodes: [],

            // Array of links between class nodes
            links: [],

            // Nodes selected
            selected: {},

            // Live collection of SVG text elements
            textEls: [],

            // Watcher handlers for each relationship type of the focused class
            unwatchRelationships: [],

            // Selected relationship types to display
            selRelationships: this.relationshipTypes,

            // Handler for the pan & zoom plugin
            panZoom: null,
        };
    },

    computed: {
        classData() {
            return useClassView().getClassData.value;
        },
        // True if the graph contains only classes related to the selected class directly
        isVicinity() {
            const currPrimaryID = this.classData.primaryID;
            const stdLinkedPrimaryIDs = flatten(
                values(pick(this.classData, this.stdRelationshipProps))
            );
            const customLinkedPrimaryIDs = flatten(
                values(this.classData[this.relationshipWrapper])
            );
            const vicinityPrimaryIDs = union(
                stdLinkedPrimaryIDs,
                customLinkedPrimaryIDs
            );
            const localPrimaryIDs = vicinityPrimaryIDs
                .concat([currPrimaryID])
                .sort();
            const shownPrimaryIDs = Object.keys(this.resolvedClasses).sort();

            return isEqual(shownPrimaryIDs, localPrimaryIDs);
        },

        // Dynamic scan of relationship types present in the graph
        relationshipTypes() {
            return uniqBy(map(this.links, (link) => link.type.split('.')[0]));
        },

        // Finds out the relative path to the root node's static image
        rootMarkPath() {
            return require('../../assets/root.png');
        },
    },

    watch: {
        'classData.primaryID'(primaryID) {
            const selectedNode = find(this.nodes, { id: primaryID });

            if (selectedNode) {
                this.selected = { nodes: {}, links: {} };
                this.selected.nodes[primaryID] = selectedNode;
            }

            if (this.isResetOnFocus) {
                this.onReset();
            }
        },

        // Detects the removal of new classes => removes all links and the classes themselves
        newClasses(currList, prevList) {
            if (currList.length < prevList.length) {
                this.difference(currList, prevList).forEach((classData) => {
                    let totalLinks = 0;

                    this.relationshipProps.forEach((linkType) => {
                        const link = get(classData, linkType) || '';
                        totalLinks += link;
                        this.removeLinks(
                            linkType,
                            get(classData, linkType),
                            classData
                        );
                    });

                    // Not a single link => removes straight away
                    if (!totalLinks) {
                        this.removeNode(classData.primaryID);
                    }
                });
            }
        },

        // Any property of any known class is changed => cummulative update of the whole graph
        resolvedClasses: {
            handler() {
                this.addClasses(Object.keys(this.resolvedClasses));
            },
            deep: true,
        },

        // Fixes nodes as soon as forces are disabled.
        isAnimation(isAnimation) {
            this.nodes.forEach((node) => {
                if (isAnimation) {
                    node.fx = node.fy = null;
                } else {
                    node.fx = node.x;
                    node.fy = node.y;
                }
            });
        },

        // Detects relationship changes during edit mode and resets the graph after editing done.
        isEditMode(isEnabled) {
            if (isEnabled) {
                this.unwatchRelationships = this.trackRelationshipChanges();
            } else {
                this.unwatchRelationships.forEach((unwatch) => unwatch());
                this.$nextTick(this.onReset);
            }
        },

        isZoomScroll(isEnabled) {
            if (isEnabled) {
                this.panZoom.enableMouseWheelZoom();
            } else {
                this.panZoom.disableMouseWheelZoom();
            }
        },

        isVisible: 'initPanZoom',
        width: 'onResize',
        height: 'onResize',
    },

    // Prevents visual noise from constantly updating the graph
    created() {
        this.onResize = debounce(this.onResize, 400);
    },

    mounted() {
        /**
         * Relies on the graph's container dims to dynamically calculate its center. This means that
         * new graph nodes/links will automatically center themselves correctly after resize.
         */
        const component = this;
        Object.defineProperty(this.$refs.graph, 'center', {
            get: function () {
                const width = component.width;
                const height = component.height;

                return {
                    x: width / 2 + width / 200 + this.offset.x,
                    y: height / 2 + height / 200 + this.offset.y,
                };
            },
        });

        /**
         * Pre-calculates a bunch of steps before rendering the graph if animation disabled and
         * updates the pan & zoom reference dimensions is the process.
         * @param {boolean} isInstantMove - True if nodes allowed to move during evaluation while animation off.
         */
        this.$refs.graph.animate = function (isInstantMove = false) {
            let steps;

            this.simulation && this.simulation.stop();
            this.simulation = this.simulate(this.nodes, this.links);
            this.simulation.alpha(1);

            // Updates the pan & zoom bounding dims whenever the graph forces are evaluated
            // NOTE: this allows the zoom controls to behave accurately but may have a performance impact.
            if (!this.simulation.on('tick')) {
                this.simulation.on('tick', () => {
                    if (component.isVisible) {
                        component.panZoom.updateBBox();
                    }
                });
            }

            // Animations on: stops the physics before changing the graph.
            if (component.isAnimation) {
                this.simulation.restart();

                // Animations off: evaluates forces all at once to produce the illusion of a static graph.
                // SOURCE: https://bl.ocks.org/mbostock/1667139
            } else {
                if (isInstantMove) {
                    this.nodes.forEach((node) => {
                        node.fx = null;
                        node.fy = null;
                    });
                }

                // TODO: use a web worker for this.
                steps = Math.ceil(
                    Math.log(this.simulation.alphaMin()) /
                        Math.log(1 - this.simulation.alphaDecay())
                );
                times(steps, this.simulation.tick);

                this.$nextTick(() => {
                    // Fixes any new nodes.
                    this.nodes.forEach((node) => {
                        if (!node.fx || !node.fy || isInstantMove) {
                            node.fx = node.x;
                            node.fy = node.y;
                        }
                    });

                    // NOTE: tick events are not dispatched when ticks are triggered manually.
                    if (component.isVisible) {
                        component.panZoom.updateBBox();
                    }
                });
            }
        };

        this.addClasses(Object.keys(this.resolvedClasses));

        // Helps tell dragging from clicking.
        this.$watch('$refs.graph.dragging', this.onNodeDrag);

        // In case the graph is mounted and the graph is visible straight away.
        this.initPanZoom();
    },

    beforeDestroy() {
        this.$refs.graph && this.$refs.graph.simulation.on('tick', null);
        this.panZoom && this.panZoom.destroy();
        this.unwatchRelationships.forEach((unwatch) => unwatch());
    },

    methods: {
        /**
         * Initialises the pan & zoom infrastructure only when the graph is visible to avoid cross-browser issues
         * NOTE: Firefox refuses to change SVG transforms when the element is hidden.
         * @see {@link https://github.com/ariutta/svg-pan-zoom/issues/105}
         */
        initPanZoom() {
            const svgEl = this.$refs.graph.$el.firstElementChild;

            if (this.isVisible) {
                // First time the graph is on display: centers the graph using forces.
                if (!this.panZoom) {
                    this.panZoom = panZoom(svgEl, {
                        fit: false,
                        center: false,
                        mouseWheelZoomEnabled: this.isZoomScroll,
                    });
                    this.$nextTick(() => {
                        this.$refs.graph.animate();
                    });

                    // Graph was hidden but re-displayed now: updates dims in case some resizing has taken place
                } else if (this.panZoom) {
                    this.panZoom.updateBBox();
                }
            }
        },

        /**
         * Sets up the detection of removal of relationships of the focused class
         * Only changes derived from user interaction are tracked.
         */
        trackRelationshipChanges() {
            return this.$watchAll(
                this.relationshipProps.map(
                    (propName) => 'classData.' + propName
                ),
                function (propName, newParents, oldParents) {
                    const relPropName = propName.replace('classData.', '');
                    const editState = this.classData.editState;
                    const isUserChange =
                        editState && editState.hasOwnProperty(relPropName);

                    if (isUserChange && newParents.length < oldParents.length) {
                        this.removeLinks(
                            relPropName,
                            this.difference(newParents, oldParents)
                        );
                    }
                }
            );
        },

        removeLinks(
            linkType,
            primaryIDs,
            srcPrimaryID = this.classData.primaryID
        ) {
            primaryIDs.forEach((primaryID) => {
                const linksTo = filter(this.links, { tid: primaryID });
                const linksFrom = filter(this.links, { sid: primaryID });
                const staleLinks = filter(linksTo, {
                    sid: srcPrimaryID,
                    tid: primaryID,
                    type: linkType,
                });

                // Removes links of the same type as the relationships removed
                staleLinks.forEach((link) => {
                    const index = this.links.indexOf(link);
                    this.links.splice(index, 1);
                });

                // Removes the target node if it has no other links from or towards.
                if (linksTo.length === staleLinks.length && !linksFrom.length) {
                    this.removeNode(primaryID);
                }
            });
        },

        removeNode(primaryID) {
            const index = findIndex(this.nodes, { id: primaryID });
            this.nodes.splice(index, 1);
            this.$emit('graph:remove', [primaryID]);
        },

        addClasses(
            classNeighbours = this.classData,
            nodes = this.nodes,
            links = this.links
        ) {
            this.isBusy = true;

            if (Array.isArray(classNeighbours)) {
                classNeighbours.forEach((primaryID) =>
                    this.buildNodeVicinity(this.resolvedClasses[primaryID])
                );
            } else if (typeof classNeighbours === 'object') {
                this.buildNodeVicinity(classNeighbours);
            }

            this.selected = { nodes: {}, links: {} };
            this.selected.nodes[this.classData.primaryID] = this.nodes[0];

            this.$nextTick(() => {
                this.setLabelAttrs();
                this.isBusy = false;
            });
        },

        buildNodeVicinity(classData, nodes = this.nodes, links = this.links) {
            if (
                !classData ||
                !nodes ||
                !links ||
                !classData.relationalProperties
            )
                return;
            const newNode = this.parseClass(classData);

            this.union(nodes, [newNode], ['id'], this.onDuplicateNode);

            for (const relationshipKey of RELATIONSHIP_PROPS) {
                const propValue = classData[relationshipKey];
                if (!propValue) continue;

                this.union(
                    nodes,
                    this.getNodes(propValue),
                    ['id'],
                    this.onDuplicateNode
                );
                this.union(
                    links,
                    this.getLinks(
                        relationshipKey,
                        propValue,
                        classData.primaryID
                    ),
                    ['sid', 'tid', 'type'],
                    this.onDuplicateLink
                );
            }
            for (const [relationshipKey, relationshipValue] of Object.entries(
                classData.relationalProperties
            )) {
                if (!relationshipValue) continue;

                this.union(
                    nodes,
                    this.getNodes(relationshipValue),
                    ['id'],
                    this.onDuplicateNode
                );
                this.union(
                    links,
                    this.getLinks(
                        relationshipKey,
                        relationshipValue,
                        classData.primaryID
                    ),
                    ['sid', 'tid', 'type'],
                    this.onDuplicateLink
                );
            }
        },

        union(currList, newList, uniqueProps, onDuplicate = () => {}) {
            newList.forEach((item) => {
                const predicate = {};
                let duplicates = [];

                uniqueProps.forEach((uniqueProp) => {
                    predicate[uniqueProp] = item[uniqueProp];
                });

                duplicates = filter(currList, predicate);

                if (duplicates.length) {
                    duplicates.forEach((existing) => {
                        onDuplicate(existing, item);
                    });
                } else {
                    currList.push(item);
                }
            });
        },

        onDuplicateNode(existing, node) {
            if (existing.name !== node.name) {
                this.$set(existing, 'name', node.name);
            }

            if (existing._cssClass !== node._cssClass) {
                this.$set(existing, '_cssClass', node._cssClass);
            }
        },

        onDuplicateLink(existing, link) {
            if (existing._svgAttrs.type !== link._svgAttrs.type) {
                this.$set(existing._svgAttrs, 'type', link._svgAttrs.type);
            }
        },

        getNodes(primaryIDs) {
            if (!primaryIDs) return;
            const nodes = primaryIDs.map((primaryID) => {
                const resolvedClass = this.resolvedClasses[primaryID];
                if (!isEmpty(resolvedClass)) {
                    return this.parseClass(resolvedClass);
                } else {
                    return null;
                }
            });

            return compact(nodes);
        },

        parseClass(classData) {
            const isRoot =
                classData &&
                classData.superClasses &&
                !classData.superClasses.length;
            const isSelected = classData.primaryID === this.classData.primaryID;
            const nodeAttrs = { id: `graph-${classData.id}` };
            const nodeClasses = [
                this.isNew(classData) ? 'new-node' : '',
                this.isObsolete(classData) ? 'obsolete-node' : '',
                isRoot ? 'root-node' : '',
                isSelected ? 'selected-node' : '',
                classData.editState ? '' : 'outside-tree',
            ];
            let nodeName = this.truncate(
                classData.primaryLabel,
                this.maxCharLabel
            );

            if (isRoot) {
                nodeAttrs.filter = 'url(#graph-root-node)';
                nodeName += ' (ROOT)';
            }

            return {
                id: classData.primaryID,
                classId: classData.id,
                name: nodeName,
                _cssClass: nodeClasses.join(' '),
                _labelClass: nodeClasses.join(' '),
                _svgAttrs: nodeAttrs,
            };
        },

        isNew(classData = {}) {
            return get(classData.editState, 'primaryLabel.isNew');
        },

        isObsolete(classData = {}) {
            return get(classData.editState, 'primaryLabel.isObsolete');
        },

        getLinks(propName, primaryIDs, srcPrimaryID) {
            const resolvedIDs = intersection(
                Object.keys(this.resolvedClasses),
                primaryIDs
            );
            return resolvedIDs.map((primaryID) =>
                this.parseProperty(propName, srcPrimaryID, primaryID)
            );
        },

        parseProperty(propName, sourceID, targetID) {
            //There probably is a inbuilt way of doing this but it might of got messed up with CENtree 2.0
            if (!RELATIONSHIP_PROPS.includes(propName)) {
                propName = `relationalProperties.${propName}`;
            }

            const linkName = this.$pluralize.singular(
                this.humanReadable(propName)
            );
            const isToOutTree =
                this.resolvedClasses[targetID].sourceUniqueID !==
                this.resolvedClasses[sourceID].sourceUniqueID;
            const linkAttr = `${propName.replace('.', ' ')}${
                isToOutTree ? ' outside-onto' : ''
            }`;

            return {
                sid: sourceID,
                tid: targetID,
                name: this.upperFirst(linkName),
                type: propName,
                _svgAttrs: {
                    type: linkAttr,
                    'marker-end': `url(#graph-m-end-${propName.split('.')[0]})`,
                },
            };
        },

        setLabelAttrs(textEl) {
            this.textEls = Array.prototype.slice.call(
                this.$el.getElementsByTagName('text')
            );

            this.textEls.forEach((textEl) => {
                textEl.setAttribute('text-anchor', 'middle');

                if (textEl.classList.contains('node-label')) {
                    textEl.setAttribute('dy', '-1.9em');
                    textEl.setAttribute('dx', '-1.3em');
                    textEl.setAttribute(
                        'filter',
                        'url(#graph-label-background)'
                    );
                }
            });
        },

        /**
         * Trigered before and after the mouse down and mouse up. This includes any click events.
         * @param {number} startNodeIndex - Index for the node that is being initially dragged. Undefined/false if the dragging has ended.
         * @param {number} endNodeIndex - Index for the node that was being dragged. Undefined/false if the dragging has stated.
         */
        onNodeDrag(startNodeIndex, endNodeIndex) {
            const diff = {};

            // Start of the dragging action
            if (isNumber(startNodeIndex)) {
                this.panZoom.disablePan();
                this.isNodeDragging = true;
                this.isLegitClick = false;
                this.prevDraggedPos.x = this.nodes[startNodeIndex].x;
                this.prevDraggedPos.y = this.nodes[startNodeIndex].y;

                // End of the dragging action
            } else if (isNumber(endNodeIndex)) {
                diff.x = Math.abs(
                    this.nodes[endNodeIndex].x - this.prevDraggedPos.x
                );
                diff.y = Math.abs(
                    this.nodes[endNodeIndex].y - this.prevDraggedPos.y
                );
                this.isLegitClick =
                    diff.x < this.deltaDrag.x && diff.y < this.deltaDrag.y;
                this.isNodeDragging = false;
                this.panZoom.enablePan();

                if (!this.isAnimation) {
                    this.nodes[endNodeIndex].fx = this.nodes[endNodeIndex].x;
                    this.nodes[endNodeIndex].fy = this.nodes[endNodeIndex].y;
                }
            }

            // If the node clicked is outside the tree, trigger navigation to its corresponding class.
            if (this.isLegitClick) {
                const node = this.nodes[endNodeIndex];
                const isWithinTree =
                    node._cssClass.indexOf('outside-tree') === -1;

                if (isWithinTree) {
                    this.$emit('graph:select', node.id);
                } else {
                    this.$router.push({
                        name: 'class',
                        params: {
                            ontologyID:
                                this.resolvedClasses[node.id].sourceUniqueID,
                            primaryID: node.id,
                        },
                    });
                }

                // Registers any node dragging that has taken place if animations off.
            } else {
                this.isNodesDragged = !this.isAnimation;
            }
        },

        onReset() {
            this.nodes = [];
            this.links = [];
            this.isNodesDragged = false;
            this.$emit('graph:reset');
        },

        onResetZoomPan() {
            this.isNodesDragged = false;
            this.panZoom.resetZoom();
            this.panZoom.pan({ x: 0, y: 0 });
            this.$refs.graph.animate(true);
        },

        /**
         * Centers the graph out on resize if no zooming, panning or dragging has taken place.
         * NOTE: zooming does alter panning automatically.
         */
        onResize() {
            const pan = this.panZoom && this.panZoom.getPan();

            if ((!pan || (!pan.x && !pan.y)) && !this.isNodesDragged) {
                this.$refs.graph.animate(true);
            }
        },

        // TODO: unreliable, spews out png with just nodes
        onScreenshot() {
            this.$refs.graph.screenShot(null, 'transparent', false, true);
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.graph-toolbar {
    .btn {
        .fa-toggle-on {
            color: $link-hover-color;
        }

        .fa-toggle-off {
            color: $gray-600;
        }

        &:hover .fa-toggle-off {
            color: $gray-300;
        }
    }

    &.top-toolbar {
        .zoom-controls {
            top: 2.2rem;
            right: 0;
        }
    }

    &.bottom-toolbar {
        bottom: 11%;
    }

    .relationships-filter-list .custom-control,
    .btn:not(:hover) {
        background: rgba($white, 0.9);
    }
}

::v-deep .net {
    height: 100%;
    cursor: move;

    .node {
        stroke-linecap: round;
        stroke: $secondary;
        stroke-width: 0.25rem;
        fill: darken($light, 6%);
        cursor: pointer;

        &.outside-tree {
            stroke-dasharray: 8px;
            stroke: $secondary;

            &:hover {
                stroke-dasharray: none;
                stroke: $link-hover-color;
            }
        }

        &.selected,
        &:hover {
            fill: darken($mark-bg, 7%);
        }

        &.root-node {
            stroke: $body-color;
        }

        &.new-node {
            stroke: darken($info, 5%);
        }

        .node-dragging &:not(.selected) {
            fill: darken($light, 6%) !important;
            stroke: $secondary !important;
        }
    }

    .node-label {
        fill: $secondary;
        background: rgba($light, 0.5);

        &.selected-node {
            font-weight: 600;
        }

        &.new-node {
            fill: darken($info, 5%);
        }
    }

    .link {
        stroke-linecap: round;

        &[type*='superClasses'] {
            stroke: rgba(#6c6e68, 0.5);
        }

        &[type*='partOf'] {
            stroke: rgba(#d2a366, 0.5);
        }

        &[type*='derivesFrom'] {
            stroke: rgba(#bb5d41, 0.5);
        }

        &[type*='developsFrom'] {
            stroke: rgba(#69882d, 0.6);
        }

        &[type*='equivalences'] {
            stroke: rgba(#6fa09c, 0.7);
        }

        &[type*='relationalProperties'] {
            stroke: rgba($tertiary, 0.6);
        }

        &[type*='outside-onto'] {
            stroke-dasharray: 10px;
        }
    }
}

[id*='superClasses'] {
    fill: lighten(#6c6e68, 10%);
}

[id*='partOf'] {
    fill: lighten(#d2a366, 10%);
}

[id*='derivesFrom'] {
    fill: lighten(#bb5d41, 10%);
}

[id*='developsFrom'] {
    fill: lighten(#556026, 10%);
}

[id*='equivalences'] {
    fill: lighten(#6fa09c, 7%);
}

[id$='relationalProperties'] {
    fill: lighten($tertiary, 10%);
}

#graph-label-background feFlood {
    flood-color: rgba($white, 0.7);
}

::v-deep .relationships-filter-list {
    .custom-control-label {
        pointer-events: none;
        line-height: 1.7em;
        font-size: 0.875em;
        color: $gray-700;
    }

    .superClasses-checkbox :before {
        background-color: lighten(#6c6e68, 10%) !important;
    }

    .partOf-checkbox :before {
        background-color: lighten(#d2a366, 10%) !important;
    }

    .derivesFrom-checkbox :before {
        background-color: lighten(#bb5d41, 10%) !important;
    }

    .developsFrom-checkbox :before {
        background-color: lighten(#556026, 10%) !important;
    }

    .equivalences-checkbox :before {
        background-color: lighten(#6fa09c, 7%) !important;
    }

    .relationalProperties-checkbox :before {
        background-color: lighten($tertiary, 10%) !important;
    }
}

// Class name within popovers
::v-deep .class-name {
    color: $secondary;
    font-weight: 500;
}

// Labels for relationships in graph
::v-deep #link-labels {
    fill: $gray-700;
}

.hide-link-labels ::v-deep #link-labels {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
