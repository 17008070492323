<template>
    <div class="property-add">
        <rel-typeahead
            v-if="
                propName === 'synonyms' ||
                propName === 'mappings' ||
                isRelationship
            "
            class="d-block"
            :class="`${propName}-add`"
            :isAllOntos="propName === 'mappings'"
            :isHideApproximations="!isRelationship && propName !== 'mappings'"
            :isBlockSelection="!isRelationship && propName !== 'mappings'"
            :isRecommendations="showRecommendations"
            :isAutoSuggester="propName === 'synonyms'"
            :isAutoMatch="propName !== 'mappings'"
            :suggestionMax="5"
            :isValid="!isRelationship ? () => true : undefined"
            :ontologyID="ontologyID"
            :term="primaryLabel"
            :relationshipType="propName"
            :blackListedPrimaryIDs="
                propValue.concat(primaryID).concat(obsPrimaryIDs)
            "
            :additionalSuggestions="newClasses"
            :updatedSuggestions="changedClasses"
            :matchedTitle="typeaheadMatchedTitle"
            :approximateTitle="typeaheadApproxTitle"
            :placeholder="inferredPlaceholder"
            :canPaste="propName === 'synonyms'"
            @add="onItemAdd($event.primaryID)"
            @pasted="onPastedAdd" />

        <wiki-textarea
            v-else-if="propName === 'textualDefinitions'"
            test-id="0"
            class="definition-add"
            :term="primaryLabel"
            @add="onItemAdd" />

        <b-input-group v-else>
            <b-form-input
                class="plain-field"
                v-model="currPlainText"
                :placeholder="placeholder + '...'"
                @keydown.enter.native.prevent="onItemAdd(currPlainText)" />
            <b-input-group-append>
                <b-button
                    variant="info"
                    :id="buildTestClass('btn--add-property')"
                    size="sm"
                    @click="
                        onItemAdd(currPlainText);
                        currPlainText = '';
                    ">
                    <font-awesome-icon icon="plus" class="align-middle" />
                </b-button>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>

<script>
import { map } from 'lodash';

import WikiTextarea from '@/components/ui/WikiTextarea';
import RelTypeahead from '@/components/ui/RelationTypeahead';
import { useClassView, useEdits } from '@/compositions';

export default {
    name: 'PropertyAdd',

    components: {
        WikiTextarea,
        RelTypeahead,
    },

    props: {
        placeholder: {
            type: String,
            default: 'Add',
        },

        propName: {
            type: String,
            required: true,
        },

        propValue: {
            type: Array,
            required: true,
        },

        isRelationship: {
            type: Boolean,
            default: false,
        },
        obsoleteClasses: {
            type: Array,
        },
        iri: {
            type: String,
            default: '',
        },
        property: {
            type: Object,
        },
    },

    data() {
        return {
            currPlainText: '',
        };
    },

    computed: {
        ontologyID: function () {
            const classData = useClassView().getClassData.value;
            const ontologyData = useClassView().getOntologyData.value;

            return ontologyData?.ontologyUniqueID
                ? ontologyData.ontologyUniqueID
                : classData.sourceUniqueID;
        },

        primaryID: function () {
            return useClassView().getPrimaryId.value;
        },

        showRecommendations: function () {
            const relationshipProps =
                useClassView().getFlatRelationshipProps.value;
            return (
                this.propName === 'synonyms' ||
                relationshipProps.indexOf(this.propName) !== -1
            );
        },

        ontologyShortName: function () {
            return useClassView().getShortDisplayName.value;
        },

        primaryLabel: function () {
            return useClassView().getPrimaryLabel.value;
        },

        newClasses: function () {
            return useEdits().getNewClasses.value;
        },

        changedClasses: function () {
            return useEdits().getChangedClasses.value;
        },
        /**
         * Tries to guess the appropiate placeholder for the add field from the property name.
         */
        inferredPlaceholder: function () {
            if (this.isRelationship || this.propName === 'mappings') {
                return 'Class label';
            } else {
                return this.$pluralize.singular(
                    this.upperFirst(this.humanReadable(this.propName))
                );
            }
        },

        /**
         * Gets a list of the primary IDs of all classes that have been marked as obsolete.
         */
        obsPrimaryIDs: function () {
            return map(this.obsoleteClasses, 'primaryID');
        },
    },

    methods: {
        /**
         * Determines the best title for the typeahead's list of suggestions when relevant matches where found.
         * @param {boolean} isSuggesterOn - True if smart recommendations are on.
         */
        typeaheadMatchedTitle(isSuggesterOn) {
            if (isSuggesterOn) {
                return this.suggesterHeading(this.propName);
            } else {
                return this.matchedHeading(
                    this.propName,
                    this.ontologyShortName
                );
            }
        },

        /**
         * Builds the text for the typeahead's list of suggestions when there are no matches.
         * @param {boolean} isSuggesterOn - True if smart recommendations are on.
         */
        typeaheadApproxTitle(isSuggesterOn) {
            const ontologyShortName = this.isAllOntos
                ? ''
                : this.ontologyShortName;

            if (isSuggesterOn) {
                return this.suggesterHeading(this.propName);
            } else {
                return this.approximateHeading(ontologyShortName);
            }
        },

        /**
         * Determines the best heading for the typeahead's list of suggestions when the recommendations are active.
         * @param {string} propName - Name of the class property whose value is typed into the add field.
         */
        suggesterHeading(propName) {
            if (propName === 'synonyms') {
                return 'RECOMMENDED SYNONYMS';
            } else {
                return 'RECOMMENDED RELATIONSHIPS BY CLASS SIMILARITY';
            }
        },

        /**
         * Generates heading-like text for a list of relevant new entries for the current property.
         * @param {string} propName - Name of the class property whose value is typed into the add field.
         * @param {string} ontologyShortName - Abbreviated human-readable case-sensitive ID of the ontology.
         */
        matchedHeading(propName, ontologyShortName) {
            if (propName === 'synonyms') {
                return `AVOID THESE CLASSES ALREADY IN ${ontologyShortName}`;
            } else if (propName === 'mappings') {
                return `classes in any ontology, for specific ontology use ontology dropdown`.toUpperCase();
            } else {
                return `CLASSES WITHIN ${ontologyShortName}`;
            }
        },

        /**
         * Generates heading-like text for a list of new entries for the current property when no relevant ones were found.
         * @param {string} ontologyShortName - Abbreviated human-readable case-sensitive ID of the ontology.
         */
        approximateHeading(ontologyShortName) {
            let heading = 'NO CLASSES FOUND';

            heading += ontologyShortName ? ` WITHIN ${ontologyShortName}` : '';
            return heading + '. Did you mean...';
        },

        /**
         * Notifies the outside world of an addition as long as the field is not empty.
         * @param {string} newItem - Textual representation of the property item to be added.
         */
        onItemAdd(newItem) {
            const trimmed = newItem.trim();

            if (trimmed) {
                this.$emit(
                    'add',
                    trimmed,
                    this.iri,
                    this.propName,
                    this.property
                );
            }
        },

        onPastedAdd(newItems) {
            if (!newItems.length) return;

            this.$emit('add', newItems, this.iri, this.propName, this.property);
        },

        focusAddField() {
            this.$el.querySelector('input, textarea').focus();
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.synonyms-add ::v-deep .suggester-disabled {
    .list-group.matched-list:before {
        color: rgba($danger, 0.8);
    }

    .list-group-item {
        .autocomplete-btn {
            display: none;
        }
    }
}

.plain-field {
    height: inherit;
    padding: 0.35em 0.5em;
    line-height: 1.2em;
    box-shadow: none !important;

    &:focus {
        border-color: rgba($info, 0.6);
    }
}
</style>
