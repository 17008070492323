import { render, staticRenderFns } from "./PropertyListHeaderButton.vue?vue&type=template&id=225e2379&scoped=true&"
import script from "./PropertyListHeaderButton.vue?vue&type=script&lang=ts&"
export * from "./PropertyListHeaderButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225e2379",
  null
  
)

export default component.exports