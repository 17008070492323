<template>
    <b-navbar
        class="class-bar"
        toggleable="false"
        :class="{ 'bg-info': isEditMode }">
        <b-navbar-nav class="location-toolbar d-inline-block col-6">
            <b-button
                v-if="isEditModeAltered"
                class="back-btn align-middle mr-2"
                :variant="isEditMode ? 'outline-light' : 'outline-secondary'"
                size="sm"
                @click="$router.go(-1)">
                Back
            </b-button>
            <onto-breadcrumb
                :ontologyID="ontologyID"
                :isEditModeAltered="isEditModeAltered"
                :isClassTypeahead="!isEditModeAltered && !isEditMode"
                :lastCrumbText="lastBreadCrumbText"
                :currClassID="currShortID"
                :classQuery="selShortID"
                :classToText="termID"
                :isReadonly="isEditMode"
                testId="0"
                v-on="$listeners" />
            <b-badge
                v-if="isSuggestionMode"
                class="update-hint"
                variant="light"
                size="md"
                pill
                >updated</b-badge
            >
            <b-badge
                v-if="isSuggestionMode && isRejectedSuggestion"
                class="rejected-hint"
                variant="danger"
                size="md"
                pill
                >rejected</b-badge
            >
        </b-navbar-nav>
        <b-navbar-nav
            class="edit-toolbar d-inline-flex flex-row justify-content-end col-6 p-0 align-items-center">
            <template v-if="isEditMode">
                <div class="button-wrapper-center flex-row align-items-center">
                    <b-button
                        class="comment-btn border-0 text-white"
                        size="sm"
                        variant="outline-info"
                        v-model="isCommentBox"
                        @click="isCommentBox = !isCommentBox">
                        <font-awesome-icon
                            v-if="comment"
                            class="align-middle"
                            icon="comment-dots"
                            size="lg" />
                        <font-awesome-icon
                            v-else
                            class="align-middle"
                            icon="comment"
                            size="lg" />
                    </b-button>
                </div>
                <span class="edit-counters flex-grow-1 text-center">
                    <b-dropdown
                        ref="changesDropdown"
                        v-if="hasEdits || errorCount"
                        menu-class="mt-1 pt-0 shadow"
                        size="sm"
                        variant="info"
                        right>
                        <template slot="button-content">
                            <span
                                v-show="errorCount"
                                class="edit-count edit-count-errors text-white align-middle">
                                <b-badge variant="danger" pill>{{
                                    errorCount
                                }}</b-badge>
                                <span class="edit-unit-hint">{{
                                    'error' | pluralize(errorCount)
                                }}</span>
                            </span>
                            <span
                                v-show="hasEdits"
                                class="edit-count edit-count-edited text-white align-middle">
                                <b-badge
                                    variant="light"
                                    class="text-info"
                                    pill
                                    >{{ hasEdits }}</b-badge
                                >
                                <span class="edit-unit-hint"
                                    >{{
                                        'class' | pluralize(hasEdits)
                                    }}
                                    changed</span
                                >
                            </span>
                        </template>
                        <template v-if="errorCount">
                            <b-dropdown-header
                                class="dropdown-header-errors dropdown-header-container">
                                <span class="flex-grow-1"
                                    >Changes with errors</span
                                >
                                <b-button-close
                                    class="dropdown-close-btn ml-3"
                                    text-variant="light"
                                    @click="$refs.changesDropdown.hide(true)" />
                            </b-dropdown-header>
                            <b-dropdown-item-button
                                v-for="(erroredClass, index) in erroredClasses"
                                class="text-truncate"
                                :key="'error-' + index"
                                :class="{
                                    active: selTermID === erroredClass.id,
                                }"
                                @click="
                                    $emit('dropdown:class', erroredClass.id)
                                ">
                                <b-badge
                                    v-if="
                                        errorLog.hasOwnProperty(erroredClass.id)
                                    "
                                    variant="danger align-middle"
                                    pill>
                                    {{
                                        _size(
                                            errorLog[erroredClass.id].previous
                                        )
                                    }}
                                </b-badge>
                                <class-name
                                    :isShortID="false"
                                    :isObsolete="
                                        editLog[erroredClass.id] &&
                                        editLog[erroredClass.id].isObsolete
                                    "
                                    :isNew="
                                        editLog[erroredClass.id] &&
                                        editLog[erroredClass.id].isNew
                                    "
                                    :ontologyID="erroredClass.sourceUniqueID"
                                    :primaryID="erroredClass.primaryID"
                                    :initClass="erroredClass" />
                            </b-dropdown-item-button>
                        </template>
                        <template v-if="editedClasses.length">
                            <b-dropdown-header
                                class="dropdown-header-container dropdown-header-changes"
                                :class="{ 'mt-2': hasEdits && errorCount }">
                                <span class="flex-grow-1"
                                    >Changes
                                    {{
                                        isSuggestionMode
                                            ? 'suggested'
                                            : 'so far'
                                    }}</span
                                >
                                <b-button-close
                                    class="dropdown-close-btn ml-3"
                                    text-variant="light"
                                    @click="$refs.changesDropdown.hide(true)" />
                            </b-dropdown-header>
                            <b-dropdown-item-button
                                v-for="(editedClass, index) in editedClasses"
                                class="text-truncate"
                                :key="'edit-' + index"
                                :class="{
                                    active: selTermID === editedClass.id,
                                }"
                                @click="
                                    $emit('dropdown:class', editedClass.id)
                                ">
                                <b-badge
                                    v-if="editLog[editedClass.id]"
                                    variant="info align-middle"
                                    pill>
                                    <template
                                        v-if="
                                            editLog[editedClass.id] &&
                                            editLog[editedClass.id].isNew
                                        "
                                        >New</template
                                    >
                                    <template
                                        v-else-if="
                                            (!editLog[editedClass.id] ||
                                                !editLog[editedClass.id]
                                                    .isObsolete) &&
                                            !errorLog.hasOwnProperty(
                                                editedClass.id
                                            )
                                        ">
                                        {{
                                            _size(
                                                editLog[editedClass.id].previous
                                            )
                                        }}
                                    </template>
                                </b-badge>
                                <class-name
                                    v-if="isSuggestionMode"
                                    :isShortID="false"
                                    :isObsolete="
                                        isObsoleteClass(editedClass.primaryID)
                                    "
                                    :isNew="isNewClass(editedClass.primaryID)"
                                    :ontologyID="editedClass.sourceUniqueID"
                                    :primaryID="editedClass.primaryID"
                                    :initClass="editedClass" />

                                <class-name
                                    v-else
                                    :isShortID="false"
                                    :isObsolete="
                                        editLog[editedClass.id] &&
                                        editLog[editedClass.id].isObsolete
                                    "
                                    :isNew="
                                        editLog[editedClass.id] &&
                                        editLog[editedClass.id].isNew
                                    "
                                    :ontologyID="editedClass.sourceUniqueID"
                                    :primaryID="editedClass.primaryID"
                                    :initClass="editedClass" />
                            </b-dropdown-item-button>
                        </template>
                    </b-dropdown>
                </span>
                <span class="edit-controls">
                    <b-button
                        v-if="!isEditModeAltered"
                        class="cancel-btn align-middle mr-2"
                        variant="outline-light"
                        size="sm"
                        :id="this.buildTestClass('button--cancel')"
                        @click="confirmEditCancel('rightTop')">
                        Cancel
                    </b-button>
                    <b-button
                        v-if="isSuggestionMode"
                        class="withdraw-btn align-middle mr-2"
                        variant="danger"
                        size="sm"
                        :id="this.buildTestClass('button--withdraw')"
                        @click="onSuggestionWithdraw">
                        Withdraw
                    </b-button>

                    <b-button
                        v-if="isRecoveryMode"
                        class="withdraw-btn align-middle mr-2"
                        variant="danger"
                        size="sm"
                        :id="this.buildTestClass('button--discard')"
                        @click="confirmEditCancel('rightTop')">
                        Discard Changes
                    </b-button>
                    <commit-modal
                        v-on="$listeners"
                        v-model="isSaveModal"
                        :ontologyID="ontologyID"
                        :errorCount="errorCount"
                        :hasEdits="hasEdits"
                        :selectedClass="selectedClass"
                        :changedClasses="changedClasses"
                        :newClasses="newClasses"
                        :obsoleteClasses="obsoleteClasses"
                        :submitOp="submitOp"
                        :disabled="isSuggestionMode && !hasEdits"
                        :isSuggestToo="submitOp === 'save'"
                        :testId="0"
                        :saveButtonClass="this.buildTestClass('button--save')">
                        <template slot="commit-summary">
                            <slot name="commit-summary"></slot>
                        </template>
                        <comment-box
                            slot="commit-comment"
                            class="mb-3"
                            v-model="comment"
                            :max-rows="8"
                            :isUserChange.sync="isCommentInteractive"
                            :default="autoComment" />
                    </commit-modal>
                </span>
            </template>
            <slot v-else name="read-mode"></slot>
        </b-navbar-nav>
        <b-collapse
            id="comment-accordion"
            class="w-100 mb-2"
            v-model="isCommentBox">
            <hr class="mt-2 mb-1" />
            <strong class="text-white">Comment</strong>
            <comment-box
                v-model="comment"
                :isUserChange.sync="isCommentInteractive"
                :default="autoComment"
                :isReadonly="isRejectedSuggestion" />
        </b-collapse>
        <ValidationModal />
    </b-navbar>
</template>

<script>
import { size, some } from 'lodash';

import ClassName from '@/components/ui/ClassName';
import OntoBreadcrumb from '@/components/ClassMain/OntoBreadcrumb';
import CommentBox from '@/components/ClassMain/CommentBox';
import CommitModal from '@/components/ClassMain/CommitModal';
import { useEdits, useValidation } from '@/compositions';
import ValidationModal from '@/components/ClassMain/ValidationModal';

export default {
    name: 'ClassBar',
    components: {
        ValidationModal,
        OntoBreadcrumb,
        CommentBox,
        CommitModal,
        ClassName,
    },

    props: {
        ontologyID: {
            type: String,
            default: '',
        },

        // Short-form ID for the anchored class.
        currShortID: {
            type: String,
            default: '',
        },

        // Data object representative of the selected class.
        selectedClass: {
            type: Object,
            default: function () {
                return {};
            },
        },

        // Number of class changes with errors
        errorCount: {
            type: Number,
            default: 0,
        },

        editLog: {
            type: Object,
            default: function () {
                return {};
            },
        },

        errorLog: {
            type: Object,
            default: function () {
                return {};
            },
        },

        erroredClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        hasEdits: {
            type: Number,
            default: 0,
        },

        editedClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        obsoleteClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        newClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        changedClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },

        isRejectedSuggestion: {
            type: Boolean,
            default: false,
        },

        isEditMode: {
            type: Boolean,
            default: false,
        },

        isSuggestionMode: {
            type: Boolean,
            default: false,
        },

        isRecoveryMode: {
            type: Boolean,
            default: false,
        },

        editComment: {
            type: String,
            default: '',
        },

        autoComment: {
            type: String,
            default: '',
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
        designPattern: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            // Contents for comment boxes
            comment: this.editComment,

            // Flags if the comment box has been revealed.
            isCommentBox: false,

            // Flags if the rules box has been revealed.
            isRulesBox: false,

            // Flags if the modal summarising all class changes is on display.
            isSaveModal: false,

            // Flags if the comment has been changed interactively.
            isCommentInteractive: false,
        };
    },

    computed: {
        validationRules: function () {
            if (
                !this.designPattern ||
                !this.designPattern.ontologyDesignPatternsJSON ||
                !this.designPattern.ontologyDesignPatternsJSON
                    .ontologyDesignPatternList
            )
                return;
            return this.designPattern.ontologyDesignPatternsJSON
                .ontologyDesignPatternList;
        },

        selTermID: function () {
            return this.selectedClass.id;
        },

        selShortID: function () {
            return this.termID(this.selectedClass);
        },

        // Determines the type of submission operation when evaluating changes.
        submitOp: function () {
            const isEditor = this.$store.getters.isEditor(this.ontologyID);
            // Reviewing open suggestion as editor => reject/approve
            if (
                isEditor &&
                this.isSuggestionMode &&
                !this.isRejectedSuggestion
            ) {
                return 'review';

                // Reviewing rejected suggestion as editor => only allow re-suggest (no reject/approve or save)
                // NOTE: rejected suggestions are closed to editors who don't own them.
            } else if (isEditor && this.isSuggestionMode) {
                return 'suggest';

                // Making changes as editor (no review)
            } else if (isEditor) {
                return 'save';

                // Suggesting changes as suggestor (no review)
            } else if (!isEditor) {
                return 'suggest';
            }
        },
        /**
         * If the edit mode is altered, ed. suggestion mode or recovery mode.
         * @returns {Boolean}
         */
        isEditModeAltered: function () {
            return this.isSuggestionMode || this.isRecoveryMode;
        },

        /**
         * Displays a different text in the breadcrumb based on which mode we are in.
         * @return {String}
         */
        lastBreadCrumbText: function () {
            if (this.isSuggestionMode) {
                return 'Suggestion';
            }

            if (this.isRecoveryMode) {
                return 'Unsaved Changes';
            }

            if (this.isEditMode) {
                return 'Change ontology...';
            }
        },
    },

    watch: {
        // Resets state if coming out of the edit mode.
        isEditMode(isOn, wasOn) {
            if (wasOn && !isOn) {
                this.isCommentInteractive = false;
                this.isCommentBox = false;
                this.comment = '';
                this.isSaveModal = false;
            }
        },

        // Shows a brief notice only once raising awareness of edits instant impact on server state.
        isSuggestionMode(isOn, wasOn) {
            if (isOn && !wasOn) {
                if (
                    !this.$store.getters.hasReviewed &&
                    !this.isRejectedSuggestion
                ) {
                    this.$eventBus.$emit(
                        'notification:show',
                        'info',
                        `Updates to the suggestion will be auto-saved and not notified. To notify other users explicitly, please ${this.commitName} the suggestion.`,
                        'Instant updates',
                        [],
                        'centerTop',
                        { timeout: 0 }
                    );
                }
                this.$store.dispatch('remember', 'reviewed');
            }
        },

        // Links the local copy of the comment with the reactive conterpart upstream.
        comment(newValue) {
            this.$emit('update:editComment', newValue);
        },

        // Trickles changes down from upstream.
        editComment(newValue) {
            this.comment = newValue;
        },
    },

    methods: {
        useValidation,
        _size: size,
        isObsoleteClass(classId) {
            return useEdits().isObsoleteClass(classId);
        },

        isNewClass(classId) {
            return useEdits().isNewClass(classId);
        },
        /**
         * Indicates visually that the suggestion has been updated.
         */
        onSuggestionUpdate() {
            const navbarEl = this.$el;
            const updateHintEl = navbarEl.querySelector('.update-hint');
            const handler = () => {
                navbarEl.classList.remove('class-edited', 'edit-highlighted');
                updateHintEl.removeEventListener(
                    this.whichTransitionEvent,
                    handler
                );
            };

            // Resets to visible (cancellin any ongoing transitions) and makes sure housekeeping is done after it.
            navbarEl.classList.remove('class-edited', 'edit-highlighted');
            updateHintEl.addEventListener(this.whichTransitionEvent, handler);

            // Triggers the transition effect
            navbarEl.classList.add('class-edited');
            setTimeout(() => navbarEl.classList.add('edit-highlighted'), 1000);
        },

        onSuggestionWithdraw() {
            this.$eventBus.$emit(
                'notification:show',
                'critical',
                'The current suggestion is about to be withdrawn. As a consequence, all changes associated with it will be lost. Do you wish to continue?',
                'Suggestion withdrawal',
                [
                    {
                        text: 'Back to review',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);
                        },
                    },
                    {
                        text: 'Withdraw',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);
                            this.$nextTick(() => {
                                this.$emit('suggestion:withdraw');
                            });
                        },
                    },
                ],
                'rightTop'
            );
        },

        /**
         * If there are pending changes (or in progress), confirmation is sought from the user. Otherwise, it just continues.
         * @param {string} position - Location from which the confirm notification should emerge.
         * @param {*} cancelEvent - Data to be sent together with any triggered cancel event.
         */
        confirmEditCancel(
            position,
            cancelEvent = undefined,
            rejectOnCancel = false
        ) {
            const isSavingProp = some(this.selectedClass.editState, 'isSaving');
            const isRecoveryMode = this.$store.getters.isRecoveryMode;
            return new Promise((resolve, reject) => {
                if (
                    (this.hasEdits || this.edit_errorCount || isSavingProp) &&
                    (!this.isSuggestionMode || isRecoveryMode)
                ) {
                    this.$eventBus.$emit(
                        'notification:show',
                        'critical',
                        'Tree editing is about to be aborted. As a consequence, all changes will be lost. Do you wish to continue?',
                        'Recent changes',
                        [
                            {
                                text: 'Back to editing',
                                action: (toast) => {
                                    this.$eventBus.$emit(
                                        'notification:close',
                                        toast
                                    );
                                    rejectOnCancel &&
                                        reject(
                                            this.hasEdits ||
                                                this.edit_errorCount
                                        );
                                },
                            },
                            {
                                text: 'Discard changes',
                                action: (toast) => {
                                    this.$eventBus.$emit(
                                        'notification:close',
                                        toast
                                    );
                                    this.$nextTick(() => {
                                        this.$emit('edit:cancel', cancelEvent);
                                        useEdits().resetChangesMadeCount();
                                        resolve();
                                    });
                                },
                            },
                        ],
                        position
                    );
                } else {
                    this.$eventBus.$emit('notification:close');
                    this.$emit('edit:cancel');
                    resolve();
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

.navbar {
    z-index: calc(#{$zindex-sticky} + 1);
    flex: 0 0 auto; // compatibility with Safari
    background: $gray-200;
    border-bottom: 1px solid $gray-300;
    transition: $btn-transition;

    .update-hint {
        display: none;
        position: relative;
        top: 0.1rem;
        color: darken($info, 5%);
    }

    .btn-outline-light:hover {
        color: darken($info, 5%);
    }

    ::v-deep .breadcrumb {
        margin-bottom: 0.25rem !important;

        @media (min-width: 1024px) {
            margin-bottom: 0 !important;
        }
    }

    ::v-deep .typeahead-wrapper {
        width: 50%;
        min-width: 240px;
    }

    &.class-edited {
        .update-hint {
            display: inline;
            opacity: 1;
        }

        .rejected-hint {
            display: none;
        }

        &.edit-highlighted .update-hint {
            opacity: 0;
            transition: opacity 4s ease-out;
        }
    }

    &.bg-info {
        ::v-deep .breadcrumb-item {
            color: white;

            &:before {
                color: white;
            }

            &:not(.active) a {
                color: $primary;
            }
        }

        ::v-deep .search-field:disabled {
            color: white;
        }
    }

    .edit-toolbar {
        .edit-counters {
            .b-dropdown {
                align-items: center;

                &::before {
                    content: 'Changes';
                    padding-right: 0.5rem;
                    font-size: 0.875rem;
                    color: white;

                    @media (min-width: 1024px) {
                        display: none;
                    }
                }
            }

            ::v-deep .dropdown-toggle {
                height: 1.938rem;
                border-color: transparent;

                &:after {
                    vertical-align: middle;
                }
            }

            ::v-deep .dropdown-menu {
                position: absolute;
                max-width: 29rem;

                .dropdown-header-container {
                    color: $white;
                    font-weight: 500;
                    header.dropdown-header {
                        display: flex;

                        span {
                            color: $white;
                        }
                    }
                    &.dropdown-header-errors {
                        background: darken($danger, 10%);
                    }

                    &.dropdown-header-changes {
                        background-color: darken($info, 5%);
                    }

                    .dropdown-close-btn {
                        display: none;
                    }

                    &:first-of-type .dropdown-close-btn {
                        display: block;
                    }
                }

                .dropdown-item {
                    cursor: pointer;
                }

                .active,
                button:active {
                    color: $secondary;
                    font-weight: 500;
                    background: $mark-bg;
                }
            }

            .edit-count {
                &.edit-count-errors {
                    margin-right: 0.75rem;
                }
                &.edit-count-edited {
                    margin-right: 0.25rem;
                }

                .edit-unit-hint {
                    display: none;

                    @media (min-width: 1024px) {
                        display: inline;
                    }
                }

                .badge {
                    min-width: 1.7em;
                    margin-right: 0.15rem;
                    padding: 0 0.31em;
                    line-height: 1.7em;
                }
            }
        }

        .button-wrapper-center {
            margin-left: -2.5rem;
        }

        .comment-btn {
            margin-left: 1.5rem;
        }

        .comment-btn,
        ::v-deep .dropdown-toggle {
            background: darken($info, 5%);
        }
    }

    .rules-wrapper {
        width: 100%;
        background-color: white;
        padding: 1%;
    }

    .location-toolbar {
        display: flex !important;
        flex-direction: row;
    }
}
</style>
