var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"not-found"},[_c('h4',{staticClass:"text-secondary d-flex align-items-center"},[_c('font-awesome-icon',{attrs:{"icon":"info-circle","size":"2x"}}),_c('span',{staticClass:"pl-3"},[_vm._v(" Sorry, we couldn't find any "+_vm._s(_vm.isSuggestionMode ? 'pending suggestion' : 'matching ontology class')+" ")])],1),(
            _vm.isSuggestionMode &&
            _vm.isActiveSuggestion &&
            !_vm.$store.getters.canChange(_vm.ontologyID)
        )?[_c('h5',[_vm._v(" You have no edit or suggest permissions for "+_vm._s(_vm.$store.getters.ontoData(_vm.ontologyID) .ontologyShortDisplayName)+". ")]),_c('p',[_vm._v(" Please, contact the administrator if you need your permissions to be changed. ")])]:(
            _vm.isSuggestionMode && !_vm.isActiveSuggestion && _vm.hasSuggestion
        )?[_c('h5',[_vm._v(" This suggestion appears to have been "+_vm._s(_vm.suggestionStatus)+". ")]),_c('p',[_vm._v("Please, contact the editor to check the suggestion's state.")])]:(
            _vm.$store.getters.hasOntoIndex &&
            _vm.$store.getters.hasOnto(_vm.ontologyID)
        )?[_c('h5',[_vm._v("Troubleshooting tips")]),_c('ul',{staticClass:"mb-4"},[(_vm.ontologyID)?_c('li',[_c('span',{staticClass:"d-block"},[_vm._v("Check the ontology ID is correct:")]),_vm._v(" \""),_c('strong',[_vm._v(_vm._s(_vm.ontologyID))]),_vm._v("\". ")]):_vm._e(),(!_vm.isSuggestionMode)?_c('li',[_c('span',{staticClass:"d-block"},[_vm._v("Check also the primary ID:")]),_vm._v(" \""),_c('strong',{staticClass:"word-break"},[_vm._v(_vm._s(_vm.primaryID))]),_vm._v("\" ")]):_vm._e(),_c('li',[_c('b-link',{attrs:{"to":"/ontologies"}},[_vm._v("Check")]),_vm._v(" that "),_c('strong',[_vm._v(_vm._s(_vm.ontologyID))]),_vm._v(" is loaded. ")],1)]),_c('h5',[_vm._v("Help us improve "+_vm._s(_vm.appName))]),_c('p',[_c('b-link',{attrs:{"rel":"external","target":"_blank","href":_vm.helpUrl}},[_vm._v("Report")]),(!_vm.isSuggestionMode)?[_vm._v(" the unmatched class if you think it should be included in the database. ")]:[_vm._v(" the unmatched suggestion if you think this is a bug. ")]],2)]:(_vm.ontologyID.trim().length)?[_c('h5',[_vm._v("The "+_vm._s(_vm.ontologyID)+" ontology has not been loaded yet.")]),_c('p',[_vm._v(" You can load specific ontologies on the "),_c('b-link',{attrs:{"to":"/ontologies"}},[_vm._v("ontologies index page")]),_vm._v(". ")],1)]:(_vm.isSuggestionMode)?[_c('h5',[_vm._v("No entry was found for this suggestion.")]),_c('p',[_vm._v(" Please, contact the administrator to confirm if the suggestion has been removed. ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }