<template>
    <b-button
        v-show="hasPermissionToMakeChanges"
        class="tree-edit-btn"
        :id="buildTestClass('btn--make-changes')"
        size="sm"
        variant="outline-info"
        v-b-tooltip.hover="{
            title: `${tooltipVerb} any class in ${
                $store.getters.ontoData(ontologyID).ontologyShortDisplayName
            }`,
            delay: { show: showDelay, hide: 0 },
        }">
        <template v-if="hasPermissionToEdit">
            Make changes
            <span
                class="edit-icon d-inline-block rounded-circle bg-info text-white">
                <font-awesome-icon icon="pen" />
            </span>
        </template>
        <template v-else>
            Suggest changes
            <span class="edit-icon suggest-icon">
                <font-awesome-layers class="fa-lg mr-2">
                    <font-awesome-icon
                        icon="comment"
                        transform="grow-10"
                        class="text-info" />
                    <font-awesome-icon
                        icon="pen"
                        transform="shrink-5"
                        class="text-white"
                        :style="{ color: 'white', marginTop: '-.05em' }" />
                </font-awesome-layers>
            </span>
        </template>
    </b-button>
</template>

<script>
export default {
    name: 'TreeEditBtn',

    props: {
        ontologyID: {
            type: String,
            required: true,
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    computed: {
        hasPermissionToMakeChanges() {
            if (
                !this.$store.getters.user.edit ||
                !this.$store.getters.user.suggest
            )
                return false;

            return (
                this.$store.getters.user.isAdmin ||
                this.$store.getters.user.edit.includes(
                    this.ontologyID.toUpperCase()
                ) ||
                this.$store.getters.user.suggest.includes(
                    this.ontologyID.toUpperCase()
                )
            );
        },
        hasPermissionToEdit() {
            if (!this.$store.getters.user.edit) return false;

            return (
                this.$store.getters.user.isAdmin ||
                this.$store.getters.user.edit.includes(
                    this.ontologyID.toUpperCase()
                )
            );
        },
        tooltipVerb() {
            if (this.hasPermissionToEdit) {
                return 'Change';
            } else {
                return 'Suggest changes to';
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.tree-edit-btn {
    position: relative;
    padding-right: 2.4em !important;
    border-color: transparent !important;

    &:not(:hover) {
        color: darken($info, 5%) !important;
    }

    &:hover {
        color: $light;
    }

    .edit-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 2em;
        line-height: 2em;

        &.suggest-icon {
            top: 0.29em;
            right: -0.25em;
        }
    }
}
</style>
