<template>
    <b-form-textarea
        v-model="localComment"
        placeholder="Leave a comment..."
        rows="2"
        :readonly="isReadonly"
        :max-rows="maxRows"
        @change="onCommentChange">
    </b-form-textarea>
</template>

<script>
export default {
    name: 'CommentBox',

    props: {
        comment: {
            type: String,
            default: '',
        },

        maxRows: {
            type: Number,
            default: 3,
        },

        // Auto-generated comment
        default: {
            type: String,
            default: '',
        },

        isUserChange: {
            type: Boolean,
            default: false,
        },

        isReadonly: {
            type: Boolean,
            default: false,
        },
    },

    model: {
        prop: 'comment',
        event: 'change',
    },

    data() {
        return {
            localComment: this.comment,
        };
    },

    watch: {
        // Auto-fills the comment box if the user hasn't already provided one when the default comment is updated.
        default(newDefault, oldDefault) {
            if (
                newDefault.length &&
                (!this.comment.length || !this.isUserChange)
            ) {
                this.$emit('change', newDefault);
            }
        },

        // Trickles changes down from upstream.
        comment(newValue) {
            this.localComment = newValue;
        },
    },

    methods: {
        onCommentChange(newValue) {
            this.$emit('change', newValue);

            if (newValue.trim() !== this.default) {
                this.$emit('update:isUserChange', true);
            }
        },
    },
};
</script>
