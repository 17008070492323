<template>
    <span class="property-value d-flex flex-row-reverse align-items-center">
        <!-- Remove row button -->
        <font-awesome-icon
            v-if="
                (isEditable && value && propName !== 'labels') ||
                (isEditable &&
                    value &&
                    propName === 'labels' &&
                    !isPrimaryLabel)
            "
            class="stacked-remove ml-2"
            icon="times-circle"
            :id="this.buildTestClass('icon')"
            @click="
                $emit(
                    'value:delete',
                    currentValue || value,
                    property,
                    previousValue
                )
            " />

        <!-- Other entries -->
        <template>
            <span
                v-if="value"
                class="value flex-grow-1 d-inline-block stacked-item-name tags-header"
                :class="{ 'word-break': isLink }">
                <!-- Hover-on class summary -->
                <child-icon
                    v-if="isInitClass && isClassJump"
                    class="class-jump flex-shrink-0 mr-2"
                    v-b-tooltip.hover="{
                        title: 'Show this class on the tree',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click.native.prevent.stop="
                        $emit('class:jump', initClass.id)
                    " />

                <font-awesome-icon
                    class="tags-icon"
                    :icon="headerIcon"
                    :class="headerIconClass"
                    @click="toggleCollapsed" />

                <ul class="chip-list" v-if="displayedChips && !isEditMode">
                    <Chip
                        v-for="(match, index) in displayedChips"
                        :key="index"
                        :text="match.shortDisplayName"
                        :primaryID="match.primaryID"
                        :sourceUniqueID="match.sourceUniqueID"
                        :testId="'Chip' + index"
                        :label="match.primaryLabel"
                        :body="checkInternalMatch(match)"
                        :synonyms="match.synonyms"
                        :hiddenChips="match.hiddenChips" />
                </ul>

                <editable-text
                    ref="editText"
                    :id="`${global_randomID}`"
                    :isSaving="true"
                    :ontologyID="propOntoID"
                    :primaryID="primID"
                    :isSuccess="isEditSuccess && !isUnchanged"
                    :isError="isEditError && !isUnchanged"
                    :contentEditable="isEditable"
                    :text="value"
                    :label="label"
                    :isLink="isClickableLink(value)"
                    :isInternalLink="isInternalLink"
                    :isNormalise="true"
                    :maxLength="maxLength"
                    :placeholder="
                        $pluralize.singular(
                            upperFirst(humanReadable(propName))
                        ) + '...'
                    "
                    @valueUpdated="handleValueUpdated($event, property)" />
                <text-actions
                    :copyTargetName="
                        $pluralize.singular(humanReadable(propName))
                    "
                    :copyTargetEl="() => $refs.editText.containerEl"
                    :copyFullText="value"
                    :url="isLink || isLink ? value : ''"
                    :searchTargetName="
                        $pluralize.singular(humanReadable(propName))
                    "
                    :searchTargetEl="() => $refs.editText.containerEl"
                    :isSearchActions="isTextSearch" />
                <PropertyValuePopup
                    v-if="
                        typeof internalMatches[0] !== 'undefined' && !isEditMode
                    "
                    :primaryID="internalMatches[0].primaryID"
                    :primaryLabel="internalMatches[0].primaryLabel"
                    :description="getTextualDefinitions"
                    :synonyms="internalMatches[0].synonyms"
                    :target="`${global_randomID}`" />

                <b-collapse
                    v-if="isSchemaVersion2"
                    :visible="showTags"
                    :isEditable="isEditable">
                    <property-value-tags
                        :tags="tagsWithValue"
                        :isEditable="isEditable"
                        :property="property"
                        :isSavingDialogue="isSavingDialogue"
                        :propertyValue="value" />
                </b-collapse>
            </span>
        </template>
    </span>
</template>

<script>
import ClassName from '@/components/ui/ClassName';
import { findIndex, isArray } from 'lodash';
import ChildIcon from '@/components/ui/ChildIcon';
import TextActions from '@/components/ui/TextActions';
import EditableText from '@/components/ui/EditableText';
import PropertyValueTags from './PropertyValueTags';
import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
} from '@vue/composition-api';
import {
    useClassView,
    useEdits,
    useOntology,
    usePropertyBatching,
    usePropertyValues,
} from '@/compositions';
import { extractPropertyLanguageTag, iriToShortText } from '@/utils';
import Chip from '@/components/ui/Chip';
import PropertyValuePopup from '@/components/ui/PropertyValuePopup';

export default defineComponent({
    name: 'PropertyValueV2',
    components: {
        ClassName,
        ChildIcon,
        TextActions,
        EditableText,
        PropertyValueTags,
        Chip,
        PropertyValuePopup,
    },
    props: {
        isPrimaryLabel: {
            type: Boolean,
        },
        property: {
            type: Object,
        },
        // Primary ID of the class whose property is being probed
        propPrimID: {
            type: String,
            required: true,
        },

        // Ontology of the class whose property is being probed
        propOntoID: {
            type: String,
        },

        propName: {
            type: String,
            required: true,
        },

        propIri: {
            type: String,
        },

        value: {
            type: String,
            default: '',
        },

        // Character length limit for truncated values
        maxLength: {
            type: Number,
            default: 999999,
        },

        // Page size for class name results when resolving globally
        classesPageSize: {
            type: Number,
            default: 7,
        },

        bulletCharacter: {
            type: String,
            default: '&bull;',
        },

        // Whether name resolution for a class should be across ontologies
        isGlobalClassScope: {
            type: Boolean,
            default: false,
        },

        // Initial non-empty class data to bypass name resolution.
        initClass: {
            required: true,
        },

        // The property value could be a class (but not necessarily)
        isClass: {
            type: Boolean,
            default: false,
        },

        // True if the display of this class externally can be forced
        isClassJump: {
            type: Boolean,
            default: true,
        },

        // The class, irrespective of its state, is not to be actionable
        isNoClassLink: {
            type: Boolean,
            default: false,
        },

        isEditSaving: {
            type: Boolean,
            default: false,
        },

        isEditSuccess: {
            type: Boolean,
            default: false,
        },

        isEditError: {
            type: Boolean | String,
            default: false,
        },

        isUnchanged: {
            type: Boolean,
            default: false,
        },

        isTextSearch: {
            type: Boolean,
            default: true,
        },

        newClasses: {
            type: Array,
        },

        obsoleteClasses: {
            type: Array,
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
        isSavingDialogue: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        // True if the property value represents a class with unknown primary ID
        const isUnresolvedID = ref(false);
        const collapsed = ref(false);
        const isInternalLink = ref(false);
        const internalMatches = ref([]);
        const cutChips = ref(false);
        const previousValue = ref('');
        const currentValue = ref('');

        const displayedChips = computed(() => {
            const chipCap = 4;
            if (typeof internalMatches.value === 'undefined') return;
            if (internalMatches.value.length <= chipCap)
                return internalMatches.value;

            const matches = [...internalMatches.value].reverse();
            return [
                {
                    shortDisplayName: 'Ontologies...',
                    primaryID: 'Ontologies...',
                    sourceUniqueID: 'Ontologies...',
                    primaryLabel: 'Ontologies...',
                    synonyms: 'Ontologies...',
                    textualDefinitions: [],
                    hiddenChips: [...matches],
                },
            ];
        });

        const getTextualDefinitions = computed(() => {
            if (
                internalMatches.value[0] &&
                'textualDefinitions' in internalMatches.value[0] &&
                isArray(internalMatches.value[0]?.textualDefinitions) &&
                internalMatches.value[0].textualDefinitions?.length &&
                typeof internalMatches.value[0].textualDefinitions[0] !==
                    'undefined'
            ) {
                return internalMatches.value[0]?.textualDefinitions[0];
            } else {
                return '';
            }
        });

        const label = computed(() => {
            if (
                !internalMatches.value.length ||
                typeof internalMatches.value[0] === 'undefined'
            )
                return;
            return internalMatches.value[0].primaryLabel;
        });

        const primID = computed(() => {
            if (
                isInternalLink &&
                internalMatches.value.length &&
                internalMatches.value[0].primaryID
            ) {
                return internalMatches.value[0].primaryID;
            }
            return props.value;
        });

        const isEditable = computed(() => {
            return useEdits().getEditIsActive.value;
        });

        const isLink = computed(() => {
            return usePropertyValues().isClickableUrl(props.value);
        });

        const isClassNew = computed(() => {
            return (
                findIndex(props.newClasses, { primaryID: props.value }) !== -1
            );
        });

        const isClassObsolete = computed(() => {
            return (
                findIndex(props.obsoleteClasses, { primaryID: props.value }) !==
                -1
            );
        });

        const isInitClass = computed(() => {
            return props.initClass && props.initClass.hasOwnProperty('id');
        });

        /**
         * Determines whether we should show tags for the selected property value.
         * @return {boolean}
         */
        const showTags = computed(() => {
            if (useClassView().isSchemaVersion1.value) return false;

            return !collapsed.value;
        });

        /**
         * Determines which icon we should use for the property value header (chevron if it shows tags)
         * @return {string}
         */
        const headerIcon = computed(() => {
            return tagsWithValue && tagsWithValue.length
                ? 'chevron-right'
                : 'minus';
        });

        /**
         * Determines the class of a property value header based on the state.
         * @return {'tags-icon--collapsed'|'tags-icon--expanded'}
         */
        const headerIconClass = computed(() => {
            if (!tagsWithValue || !tagsWithValue.length)
                return 'tags-icon--no-children';
            return collapsed.value
                ? 'tags-icon--collapsed'
                : 'tags-icon--expanded';
        });

        const tags = computed(() => {
            if (props.propName) return;

            const propertyIri =
                props.propIri ||
                useOntology().getIriByPropName(props.propName) ||
                '';
            const lang = extractPropertyLanguageTag(props.property.value);

            return (
                usePropertyValues().getTagsByPropertyIri(
                    propertyIri,
                    props.property.value,
                    lang
                ) || []
            );
        });

        /**
         * Retrieves tags that have a value.
         * @return {*[]}
         */
        const tagsWithValue = computed(() => {
            if (!tags || !tags.length) return [];
            return tags.filter((tag) => {
                return !!tag.value;
            });
        });

        const isEditMode = computed(() => {
            return useEdits().getEditIsActive.value;
        });

        /**
         * Handles the valueUpdated event emitted by the EditableText component.
         * @param {Event} event
         * @param {definitions["CustomProperty"]} property
         */
        const handleValueUpdated = (values, property) => {
            const event = values.event;
            previousValue.value = values.previousValue;
            currentValue.value = getText(event);

            if (currentValue.value === null) return;

            emit(
                'value:update',
                currentValue.value,
                property,
                previousValue.value
            );
        };

        const checkInternalMatch = (match) => {
            if (typeof match.textualDefinitions[0] === 'undefined') {
                return '';
            }
            return match.textualDefinitions[0];
        };

        /**
         * Retrieves the text out of the valueUpdated event target.
         * @param {Event} event
         */
        const getText = (event) => {
            const target = event.target;

            if (target.classList.contains('text-container'))
                return target.innerText;

            const textContainer = target.querySelector('.text-container');

            return textContainer && textContainer.innerText;
        };

        const reset = () => {
            isUnresolvedID.value = false;
        };

        /**
         * Toggles the state (collapsed/expanded) of a property value header.
         */
        const toggleCollapsed = () => {
            if (!tags || !tags.length) return;

            collapsed.value = !collapsed.value;
        };

        const isMatch = () => {
            if (usePropertyBatching().cachedResults.value[props.value]) {
                if (props.property.name !== 'mapping') return false;
                isInternalLink.value = true;
                internalMatches.value =
                    usePropertyBatching().cachedResults.value[props.value];
                return true;
            } else {
                isInternalLink.value = false;
                return false;
            }
        };

        watch(
            () => props.propPrimID,
            () => {
                reset();
            }
        );

        watch(
            () => usePropertyBatching().isSearchIdle.value,
            () => {
                isMatch();
            },
            { immediate: true }
        );

        watch(
            () => usePropertyBatching().cachedResults.value,
            () => {
                isMatch();
            },
            { immediate: true }
        );

        onMounted(() => {
            //Scroll to new value
            const element = document.getElementById(
                iriToShortText(props.property.iri)
            );
            element?.lastElementChild?.scrollIntoView(true);
        });

        return {
            isUnresolvedID,
            isEditable,
            isClickableLink: usePropertyValues().isClickableUrl,
            isLink,
            isInternalLink,
            internalMatches,
            isEditMode,
            isClassNew,
            isClassObsolete,
            isInitClass,
            showTags,
            headerIcon,
            headerIconClass,
            tags,
            tagsWithValue,
            handleValueUpdated,
            getText,
            reset,
            toggleCollapsed,
            isMatch,
            label,
            primID,
            checkInternalMatch,
            displayedChips,
            cutChips,
            getTextualDefinitions,
            previousValue,
            currentValue,
        };
    },
});
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.property-value {
    .class-jump {
        color: $secondary;
        opacity: 0.7;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    .stacked-item-bullet {
        margin-right: 0.5rem;
        font-size: 1.5rem;
        line-height: 1rem;
        width: 0.95rem;
        text-align: center;
        color: $text-muted;
    }

    .tags-icon {
        transition: transform 0.3s ease-in-out;
        margin-right: 10px;
        cursor: pointer;

        &--expanded {
            transform: rotate(90deg);
        }

        &--no-children {
            cursor: default;
        }
    }

    .stacked-item-name {
        &:not(:hover) .text-actions {
            opacity: 0.3;
        }

        &.class-actions-hovered ::v-deep .name-label {
            color: $secondary;
        }
    }

    .stacked-remove {
        color: $info;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
            color: $danger !important;
            opacity: 1;
        }

        &:hover + .stacked-item-name ::v-deep * {
            color: $danger;
        }
    }
}

.chip-list {
    list-style-type: none !important;
    display: inline;
    padding-inline-start: 0px;
}

.label-prefix {
    margin-right: 5px;
}

.chip-list:hover {
    .chip:hover:last-child {
        background-color: #f0890b !important;
        color: black !important;
    }
    .chip:last-child {
        background-color: unset !important;
        color: $primary !important;
    }
}

.value:hover .chip-list .chip:last-child {
    background-color: #f0890b;
    color: black;
}

.chip:hover {
    background-color: #f0890b;
    color: black;
}
</style>
