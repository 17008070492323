<template>
    <span class="onto-breadcrumb">
        <b-breadcrumb class="mb-0" @click="onCrumbClick">
            <b-breadcrumb-item :to="{ name: 'ontologies-entry' }"
                >Ontologies</b-breadcrumb-item
            >

            <b-breadcrumb-item>
                <b-dropdown
                    class="onto-options"
                    id="dropdown-onto-options"
                    size="sm"
                    split>
                    <template #button-content>
                        <router-link
                            id="router-link-ontology-id"
                            :to="{ name: 'class', params: { ontologyID } }"
                            >{{
                                $store.getters
                                    .ontoData(ontologyID)
                                    .ontologyShortDisplayName.toUpperCase()
                            }}</router-link
                        >
                    </template>
                    <b-dropdown-item
                        :to="{ name: 'class', params: { ontologyID } }"
                        class="btn--ontology-summary"
                        >Ontology Summary</b-dropdown-item
                    >
                    <b-dropdown-item @click="onClickRules"
                        >View Design Pattern</b-dropdown-item
                    >
                    <div>
                        <ExportOntology
                            v-if="ontologyID.trim() !== ''"
                            :ontology-id="ontologyID"
                            :id="'list-button'" />
                    </div>
                    <b-dropdown-item
                        :to="{
                            name: 'sparql-ontology',
                            params: { ontologyID },
                        }"
                        >SPARQL Editor</b-dropdown-item
                    >
                </b-dropdown>
            </b-breadcrumb-item>

            <b-breadcrumb-item v-if="!isEditModeAltered">
                <b-dropdown
                    class="type-select"
                    id="dropdown-type-select"
                    size="sm"
                    split>
                    <template #button-content>
                        <strong>Classes</strong>
                    </template>
                    <b-dropdown-item class="menu-item" active
                        >Classes</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="menu-item"
                        :to="{ name: 'properties', params: { ontologyID } }"
                        >Properties</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="menu-item"
                        :to="{ name: 'instances', params: { ontologyID } }"
                        >Instances</b-dropdown-item
                    >
                </b-dropdown>
            </b-breadcrumb-item>

            <b-breadcrumb-item class="breadcrumb--typeahead">
                <class-typeahead
                    v-show="isClassTypeahead"
                    ref="typeahead"
                    :placeholder="`Class within ${
                        $store.getters.ontoData(ontologyID)
                            .ontologyShortDisplayName
                    }`"
                    :approximate-title="`NO CLASSES FOUND WITHIN ${
                        $store.getters.ontoData(ontologyID)
                            .ontologyShortDisplayName
                    }. Did you mean...`"
                    :matched-title="
                        'CLASSES WITHIN ' +
                        $store.getters.ontoData(ontologyID)
                            .ontologyShortDisplayName
                    "
                    :disabled="isReadonly"
                    :isAutofocus="false"
                    :suggestion-max-matches="suggestionMax"
                    :fetchSuggestions="fetchTypeaheadList"
                    :performAction="goTypeahead"
                    :forceClass="true"
                    @hit="onTypeaheadHit">
                    <template slot="inset" slot-scope="typeahead">
                        <b-button
                            v-show="
                                !isReadonly &&
                                typeahead.query &&
                                typeahead.query === currClassID
                            "
                            class="reset-btn inset-btn"
                            variant="link"
                            @click="$emit('breadcrumb:reload')">
                            <font-awesome-icon icon="undo-alt" size="sm" />
                        </b-button>
                        <b-button
                            v-show="
                                !isReadonly &&
                                typeahead.query &&
                                typeahead.query !== currClassID
                            "
                            class="inset-btn"
                            variant="link"
                            :disabled="
                                !isValidClass(
                                    typeahead.query,
                                    typeahead.results
                                )
                            "
                            @click="$refs.typeahead.onAction(typeahead.query)">
                            <font-awesome-icon icon="arrow-right" size="sm" />
                        </b-button>
                    </template>
                    <template slot="view-all">
                        <b-button
                            class="view-all-btn rounded-0 border-0"
                            variant="outline-primary"
                            @mousedown.prevent="
                                onViewSearch($refs.typeahead.query)
                            ">
                            <small
                                >View all results in
                                {{
                                    $store.getters.ontoData(ontologyID)
                                        .ontologyShortDisplayName
                                }}</small
                            >
                        </b-button>
                    </template>
                </class-typeahead>
            </b-breadcrumb-item>
        </b-breadcrumb>
    </span>
</template>

<script>
import ClassTypeahead from '@/components/ui/ClassTypeahead';
import ExportOntology from '@/components/ui/ExportOntology';
import ApiSearch from '@/api/search';
import { useValidation } from '@/compositions';

export default {
    name: 'OntoBreadcrumb',
    components: { ClassTypeahead, ExportOntology },
    props: {
        // ID of the ontology to which the current class belongs to.
        ontologyID: {
            type: String,
            required: true,
        },

        isClassTypeahead: {
            type: Boolean,
            default: true,
        },

        // Programmatic class query
        classQuery: {
            type: String,
            default: '',
        },

        // Short-form ID for the currently rendered class
        currClassID: {
            type: String,
            default: '',
        },

        // Text preceding the ontology typeahead
        lastCrumbText: {
            type: String,
            default: '',
        },

        suggestionMax: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SUGGESTION_MAX),
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        isReadonly: {
            type: Boolean,
            deafult: false,
        },

        // Serialises the entire set of class properties into a string
        classToText: {
            type: Function,
            default: (data) => '',
        },
        isEditModeAltered: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        classQuery(newText) {
            this.$refs.typeahead &&
                this.$refs.typeahead.onAutocomplete(newText);
        },
    },

    mounted() {
        // Non-reactive variable to keep track of the index for the suggestion matching the typeahead's contents.
        this.classIndex = -1;

        // Non-reactive list of breadcrumb anchors.
        this.breadcrumbAnchors = this.$el.getElementsByTagName('a');

        // Pre-populates the breadcrumb field when the view is loaded directly.
        this.$refs.typeahead.onAutocomplete(this.classQuery);
    },

    methods: {
        onClickRules() {
            useValidation().setDisplayModal(true);
        },

        /**
         * Signals a navigation event to the ontology when the route is the exact match of the breadcrumb.
         * NOTE: When the route is matched exactly, there is no navigation happening.
         */
        onCrumbClick(event) {
            const targetEl = event.target;

            if (targetEl.classList.contains('router-link-exact-active')) {
                this.$emit('breadcrumb:onto');
            }
        },

        /**
         * Determines if the typeahead's contents are matched by any suggestion. Effectively, it checks if said
         * contents represent a valid class within the current ontology. Eg: "cancer" is not valid, but "EFO:0000311" is
         * NOTE: A valid class query must conform with the expected classToText test. Exact matches alone, unless unique, are not enough.
         * @param {Object[]} [rawSuggestions=[]] - List of class datasets matched by the server.
         * @param {string} classQuery - Class name or identifier typed into the breadcrumb's typeahead field.
         */
        isValidClass: function (classQuery, rawSuggestions = []) {
            const typeahead = this.$refs.typeahead;

            this.classIndex = -1;

            if (
                typeahead &&
                typeahead.isExactMatch &&
                typeahead.matchedItems &&
                typeahead.matchedItems.length === 1
            ) {
                return true;
            } else if (rawSuggestions.length) {
                rawSuggestions.find((suggestedClass, index) => {
                    const isClassFound =
                        this.classToText(suggestedClass) === classQuery;

                    if (isClassFound) {
                        this.classIndex = index;
                        return true;
                    } else {
                        return false;
                    }
                });
            }

            return this.classIndex > -1;
        },

        /**
         * Proxy method for the typeahead component to fetch the list of suggestions for a given class query.
         * @param {string} classQuery - Class name or identifier typed into the breadcrumb's typeahead field.
         */
        fetchTypeaheadList(classQuery) {
            return ApiSearch.suggestions({
                query: classQuery,
                ontologies: [this.ontologyID],
                searchType: 'class',
            });
        },

        /**
         * Proxy method for the typeahead component to serve the corresponding route for a given class query.
         * It assumes the first matched class is the intended one to be shown, especially if it is the only one.
         * @param {string} classQuery - Class name or identifier typed into the breadcrumb's typeahead field.
         * @param {Object[]} [rawSuggestions=[]] - List of class datasets matched by the server.
         */
        goTypeahead(classQuery, rawSuggestions = []) {
            let routePrimaryID = '';

            // Class query matches exactly the chosen text representation of one of the suggested classes => uses that one
            if (rawSuggestions.length && this.classIndex > -1) {
                routePrimaryID = rawSuggestions[this.classIndex].primaryID;

                // No exact class query match found for text representation, but found for primary label => uses first suggestion entry
            } else if (this.$refs.typeahead.isExactMatch) {
                routePrimaryID =
                    this.$refs.typeahead.matchedItems[0].data.primaryID;
            }

            if (routePrimaryID) {
                this.$router.push({
                    name: 'class',
                    params: {
                        ontologyID: this.ontologyID,
                        primaryID: routePrimaryID,
                    },
                });
            }
        },

        /**
         * Copies the selected class' representative text over to the typeahead field. This ensures that the field
         * is updated even if the current class is the same as the one selected.
         * @param {Object} hitClass - Data for the selected class.
         */
        onTypeaheadHit(hitClass) {
            this.$refs.typeahead.onAutocomplete(this.classToText(hitClass));
        },

        /**
         * Shows the search results for any available exact match in the current ontology.
         * @param {string} classQuery - Class name or identifier typed into the breadcrumb's typeahead field.
         */
        onViewSearch(classQuery) {
            this.$set(this.$store.getters.filters, 'ontologies', [
                this.ontologyID,
            ]);

            if (
                this.$refs.typeahead.isExactMatch &&
                this.$refs.typeahead.matchedItems.length === 1
            ) {
                classQuery =
                    this.$refs.typeahead.matchedItems[0].data.primaryLabel;
            }

            this.$router.push({
                name: 'search-query',
                params: { query: classQuery },
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';

::v-deep .type-select {
    .dropdown-menu {
        background-color: white !important;
        position: absolute;
        top: 100%; /* Bottom of button */
        right: 0;
    }

    .dropdown-item {
        font-weight: normal !important;
        border-radius: 0px !important;
        padding-left: 1.5rem !important;
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
        font-weight: 500;
        :not(.active):hover {
            background-color: #e6eef7 !important;
            color: black !important;
        }
    }

    button {
        background-color: $primary !important;
        color: black;
        padding: 0.05rem 0.5rem;
        margin-top: -2px;
    }
}

::v-deep .onto-options {
    .dropdown-menu {
        background-color: white !important;
        position: absolute;
        top: 100%; /* Bottom of button */
        width: auto;
    }

    .dropdown-item {
        font-weight: normal !important;
        border-radius: 0px !important;
        padding-left: 1.5rem !important;
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
        font-weight: 500;
        padding-right: 1.5rem !important;
        :not(.active):hover {
            background-color: #e6eef7 !important;
            color: black !important;
        }
    }

    button {
        background-color: $primary !important;
        color: black;
        padding: 0.05rem 0.5rem;
        margin-top: -2px;
    }
}

::v-deep .active {
    background-color: $primary !important;
}

::v-deep .breadcrumb {
    display: inline-block;
    background: transparent;
    padding: 0 !important;

    .breadcrumb-item {
        display: inline-block;
        padding: 0 !important;
        vertical-align: middle !important;
        position: relative;

        &:before {
            padding-right: 0.2rem;
            padding-left: 0.4rem;
            vertical-align: middle !important;
        }

        // Optional button profile
        &:not(.active) a {
            padding: 0.1rem 0.3rem;
            text-decoration: none;
            font-weight: bold;
            border-radius: $border-radius;
            transition: $btn-transition;
        }

        // Fixed button profile for dropdown
        &:nth-child(1) a {
            &:hover {
                color: black;
                background: darken($primary, 20%);
            }
        }

        // Fixed button profile for ontology name
        &:nth-child(2) {
            m-2 {
                background-color: $property !important;
            }
        }

        // Fixed button profile for dropdown
        &:nth-child(3) {
            m-2 {
                background-color: $property !important;
            }
        }

        // Fixed button profile for dropdown
        &:nth-child(4) {
            display: inline-flex;
            padding-left: 0rem !important;

            a {
                flex-grow: 1;
            }

            &:before {
                margin-top: 0.2rem;
            }
        }
    }
}

.breadcrumb {
    width: 100%;
    display: flex !important;

    .breadcrumb--typeahead {
        flex-grow: 1;
    }
}

.typeahead-wrapper {
    vertical-align: middle !important;
    width: 75% !important;

    &.disabled {
        margin: 0 !important;
    }

    .inset-btn {
        top: 0.21em;
        right: 0.25em;
        padding: 0;
        line-height: 1em;
        position: absolute;

        &:disabled {
            opacity: 0.35;
        }

        &.reset-btn:not(:hover) {
            color: $text-muted;
        }
    }

    ::v-deep .search-field {
        height: inherit;
        padding: 0.2em 1.5em 0.2em 0.3em;
        line-height: 1.2em;
        box-shadow: none;

        &:disabled {
            height: 1.2em;
            background: transparent;
            border-color: transparent;
            padding: 0;
        }

        &:hover {
            border-color: lighten($primary, 25%) !important;
        }
    }

    &:not(.matched) ::v-deep .search-field {
        &:focus,
        &:hover {
            border-color: $gray-500 !important;
        }
    }

    ::v-deep .list-group {
        .view-all-btn:not(:hover) {
            color: $link-hover-color;
            background: $white;
        }

        // Lets suggestion list go above field's length if necessary
        @media (min-width: 1024px) {
            margin-bottom: 0 !important;
        }

        // Removes the ontology badge
        .list-group-item {
            padding: 0.75rem 1.25rem !important;

            .ontology-btn {
                display: none;
            }

            .suggestion-entry {
                margin-left: 0;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}

#router-link-ontology-id {
    color: black;
    font-weight: bold;
}

::v-deep#list-button {
    background-color: rgba(255, 255, 255, 0) !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
    border: none !important;
    vertical-align: middle !important;

    button {
        background-color: transparent !important;
        width: 100% !important;
        height: 100% !important;
        margin: 0px !important;
        border: none !important;
        border-radius: 0px !important;
        text-align: left;
        padding-left: 0px !important;
        padding-bottom: 0.15rem !important;
        padding-top: 0.15rem !important;

        span {
            margin-left: 1.5rem;
            font-size: 16px;
            font-weight: 400;
            color: rgb(33, 37, 41);
        }
    }

    button:hover {
        background: darken(#fff, 7%) !important;
    }
}
</style>
