<template>
    <div
        id="cen-class-main"
        class="class-wrapper"
        :style="{
            marginTop: `${$store.headerHeight}px`,
            top: `${$store.headerHeight}px`,
            bottom: `${$store.footerHeight}px`,
        }">
        <!-- TOP BAR -->
        <class-bar
            v-show="
                $store.getters.hasOntoIndex &&
                $store.getters.hasOnto(ontologyID)
            "
            ref="classBar"
            :ontologyID="ontologyID"
            :currShortID="shortFormID"
            :selectedClass="selectedData"
            :isEditMode="edit_isActive"
            :isRecoveryMode="isRecoveryMode"
            :isSuggestionMode="suggestion_isActive"
            :editLog="edit_changes"
            :errorLog="edit_errors"
            :errorCount="edit_errorCount"
            :autoComment="$_edit_autoComment"
            :editComment.sync="edit_comment"
            :erroredClasses="$_edit_erroredClasses"
            :hasEdits="$_edit_hasEdits"
            :editedClasses="getEditedClasses"
            :obsoleteClasses="$_edit_obsoleteClasses"
            :newClasses="$_edit_newClasses"
            :changedClasses="$_edit_changedClasses"
            :isRejectedSuggestion="$_suggestion_isRejected"
            :designPattern="designPattern"
            testId="0"
            @breadcrumb:reload="reload"
            @breadcrumb:onto="onRouteUpdate({ ontologyID })"
            @dropdown:class="handleSelectEdit"
            @edit:cancel="onEditCancel"
            @edit:submit="onEditSubmit"
            @suggestion:withdraw="onSuggestionWithdraw">
            <template slot="read-mode">
                <span class="flex-grow-1 text-center">
                    <template v-if="selectedData.ontologyUniqueID">
                        <span class="usage-hint text-muted">Size:</span>
                        {{ lastOntoSize }}
                        {{ 'class' | pluralize(lastOntoSize) }} in total
                    </template>
                    <template v-else-if="selectedData.primaryID">
                        <span class="usage-hint align-middle text-muted mr-1"
                            >Usage:</span
                        >
                        <class-name
                            v-if="selectedData.sourceUniqueID"
                            class="class-usage align-middle"
                            :ontologyID="selectedData.sourceUniqueID"
                            :primaryID="selectedData.primaryID"
                            :initClass="selectedData"
                            :classesPageSize="5"
                            :isLink="true"
                            :isGlobalScope="true"
                            :isTruncate="false" />
                    </template>
                </span>
                <tree-edit-button
                    test-id="0"
                    :ontologyID="ontologyID"
                    :disabled="!hasInfo || isLoading"
                    @click.native="onEditMode()" />
            </template>

            <edit-history
                slot="commit-summary"
                slot-scope="modal"
                :propLengthMax="250"
                :editedClasses="getEditedClasses"
                :obsoleteClasses="$_edit_obsoleteClasses"
                :newClasses="$_edit_newClasses"
                :editLog="edit_changes"
                :findClass="$refs.classTree && $refs.classTree.findClass"
                @class:review="
                    $refs.classBar.isSaveModal = false;
                    $refs.classTree.nodeSelectByID($event);
                " />
        </class-bar>

        <!-- FEEDBACK -->
        <div v-if="!hasInfo && isLoading" class="loading centered"></div>

        <!-- CONTENT -->
        <splitpanes
            v-show="hasInfo"
            class="class-body default-theme"
            ref="splitPanes"
            :class="{
                'edit-allowed': $store.getters.canChange(this.ontologyID),
                'edit-mode': edit_isActive,
                'replay-mode': suggestion_isActive,
            }"
            @resized="onInfoResize"
            @pane-maximize="onInfoMaximise">
            <pane>
                <class-tree
                    ref="classTree"
                    class="mx-2"
                    :term="selectedData"
                    :treeData="tree"
                    :isEditable="edit_isActive"
                    :editLog="edit_changes"
                    :isReplayTree="suggestion_isActive"
                    :ontologyID="ontologyID"
                    :nodeUpdated="nodeUpdated"
                    :pageOffset="pageStart"
                    @tree:paginate="handlePagination"
                    @node:updateFinished="nodeUpdated = false"
                    @node:selected="handleNodeSelect($event)"
                    @node:edit="onClassChange"
                    @bulkNodes:edit="onBulkClassChange"
                    @node:dblclick="onEditMode()" />
            </pane>
            <pane class="info-panel">
                <class-item
                    v-if="selectedData.primaryLabel"
                    ref="classItem"
                    v-bind:selectedData="selectedData"
                    :key="selectedData.id"
                    :propLengthMax="250"
                    :isTreeUnload="isTreeUnload"
                    :isEditMode="edit_isActive"
                    :newClasses="$_edit_newClasses"
                    :changedClasses="$_edit_changedClasses"
                    :obsoleteClasses="$_edit_obsoleteClasses"
                    :findClass="$refs.classTree && $refs.classTree.findClass"
                    :nodeSelectByID="
                        $refs.classTree && $refs.classTree.nodeSelectByID
                    "
                    :selectionMounted="
                        $refs.classTree && $refs.classTree.selectionMounted
                    "
                    :setClassObsolete="
                        $refs.classTree && $refs.classTree.setClassObsolete
                    "
                    :getMergedPaths="
                        $refs.classTree && $refs.classTree.getMergedPaths
                    "
                    testId="0"
                    @edit="onClassChange"
                    @add="$refs.classTree && $refs.classTree.addChildClass()"
                    @text:dblclick="onEditMode()" />
                <OntoItem
                    v-else-if="selectedData.ontologyUniqueID"
                    class="mx-3 mb-2 mt-3"
                    :key="selectedData.id"
                    :selectedOntology="selectedData"
                    @onto:size="lastOntoSize = $event" />
            </pane>
        </splitpanes>

        <!-- ERROR RECOVERY -->
        <class-error
            v-if="!hasInfo && !isLoading"
            :primaryID="primaryID"
            :isSuggestionMode="suggestion_isActive"
            :isActiveSuggestion="$_suggestion_isStrictPending"
            :suggestionStatus="$_suggestion_humanStatus"
            :hasSuggestion="$_suggestion_exists"
            :ontologyID="ontologyID" />
    </div>
</template>

<script>
import { map, remove, orderBy, findIndex, isEmpty } from 'lodash';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import TreeEditButton from '@/components/ui/TreeEditButton';
import ClassBar from '@/components/ClassMain/ClassBar';
import ClassName from '@/components/ui/ClassName';
import ClassTree from '@/components/ClassMain/ClassTree';
import ClassItem from '@/components/ClassMain/ClassItem';
import ClassError from '@/components/ClassMain/ClassError';
import EditHistory from '@/components/ClassMain/EditHistory';
import SuggestionMixin from '@/components/ClassMain/SuggestionMixin';
import RecoveryMixin from '@/components/ClassMain/RecoveryMixin';
import ApiOntology from '@/api/ontology';
import ApiEdit from '@/api/edit';
import EditMixin from '../mixins/EditMixin.js';
import { UPDATE_ROUTES_ON_CLASS_CLICK } from '@/config';
import {
    useObservers,
    useClassView,
    useEdits,
    useOntology,
} from '@/compositions';
import { useClassTree } from '@/compositions/useClassTree';
import { compareEditWithCurrent, getOntologyDesignPatterns } from '@/api-v2';
import OntoItem from '@/components/ClassMain/OntoItem';

export default {
    name: 'ClassMain',
    components: {
        Splitpanes,
        Pane,
        TreeEditButton,
        OntoItem,
        ClassBar,
        ClassName,
        ClassItem,
        ClassTree,
        ClassError,
        EditHistory,
    },
    mixins: [EditMixin, SuggestionMixin, SuggestionMixin, RecoveryMixin],
    props: {
        // Contains all the properties of the term plus other non-term data such as the class tree.
        initialData: {
            type: Object,
            default: function () {
                return {};
            },
        },

        // If true, it automatically enables the edit mode as soon as the view is rendered.
        isAutoEdit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            // ID for the ontology as set by the current route
            ontologyID: '',

            // IDs for the class as set by the current route
            primaryID: '',
            shortFormID: '',

            // data for the ontology last retrieved
            lastOntoData: {},
            lastOntoSize: 0,

            // Full information for the currently selected class/ontology
            selectedData: {},

            // Tree representation of the ontology path to the currently route-set class
            tree: {},

            // Flags if the data for the view is still being retrieved
            isLoading: true,

            // Flags if the current view is about to be destroyed
            isExiting: false,

            // True if info panel has been maximised.
            isInfoMax: false,

            // Percentage size of the info pane before maximisation.
            oldInfoSize: 50,

            /**
             * Keeps track of the mounting state of the component
             * @type {'before'|'mounting'|'complete'}
             */
            mountState: 'before',

            // Keeps track of the update status of a single tree node.
            nodeUpdated: false,

            pageStart: 0,
            pageIncrement: parseInt(process.env.VUE_APP_ROOT_SIZE),
            preventUpdate: false,
            designPattern: null,
        };
    },

    computed: {
        getClassesWithValueEditsById: function () {
            const editedClassesById = useEdits().activeEdits.value;
            return editedClassesById;
        },
        getEditedClasses: function () {
            const edits = useEdits().changesMade.value;
            if (edits < 1) return;

            if (useClassView().isSchemaVersion1.value)
                return this.$_edit_editedClasses;
            if (!useClassTree().getClassTree().value) return;
            if (this.isSuggestionMode) {
                const editedClassesIds = useEdits().getEditedClassesIds();
                return editedClassesIds.map((classId) => {
                    return useClassTree().searchClassInTree(classId);
                });
            }

            const classesWithTagEditsIds =
                useEdits().getClassesWithTagsEditsIds.value || [];
            const classesWithTagEdits = useEdits().getClassesWithTagEdits.value;
            const classesWithValueEditsById =
                this.getClassesWithValueEditsById || {};
            const classesWithValueEditsIds = Object.keys(
                classesWithValueEditsById
            );

            const uniqueClassIds = [
                ...new Set(
                    classesWithTagEditsIds.concat(classesWithValueEditsIds)
                ),
            ];

            const editedClasses = uniqueClassIds.map((classId) => {
                if (classesWithValueEditsById[classId])
                    return classesWithValueEditsById[classId];

                if (classesWithTagEdits[classId]) {
                    classesWithTagEdits[classId].isTagEdit = true;
                    return classesWithTagEdits[classId];
                }

                return [];
            });
            return editedClasses;
        },
        // Routing parameters are correct and details of the class or ontology are available
        hasInfo: function () {
            return (
                !isEmpty(this.selectedData) &&
                this.$store.getters.hasOntoIndex &&
                this.$store.getters.hasOnto(this.ontologyID)
            );
        },

        // The tree has been initialised but tree data is not available yet
        isTreeUnload: function () {
            return typeof this.tree.value === 'undefined';
        },

        /**
         * Determines if suggestion mode is on by checking if the transactionID query param is set.
         * @return {boolean}
         */
        isSuggestionMode: function () {
            return !!this.$route.params.transactionID;
        },

        isRecoveryMode: function () {
            return !!this.$route.params.recoveryTransactionID;
        },
    },

    watch: {
        $route(to, from) {
            if (this.preventUpdate) return this.routeUpdatePrevented();
            if (from.name === 'suggestion') this.resetSuggestionMode(true);

            this.onRouteUpdate(to.params, from.params);
        },

        selectedData: {
            handler: function () {
                this.silentlyUpdatePath();
                this.setDocumentData();
            },
            deep: true,
        },

        async ontologyID() {
            void this.getDesignPatterns();
        },

        // v2 helper
        edit_isActive(isActive) {
            useEdits().setEditIsActive(isActive);
        },
    },
    created() {
        this.onRouteUpdate(this.$route.params);
    },
    async mounted() {
        useClassTree().setClassTree(this.$refs.classTree);
        this.mountState = 'mounting';

        this.handleBrowsingModes();

        this.mountState = 'complete';

        useEdits().setEditChanges(this.edit_changes);
        useEdits().setEditIsActive(this.edit_isActive);

        useObservers().registerObserver('PRIMARY_LABEL_UPDATED', {
            observer: 'UPDATE_PRIMARY_LABEL',
            args: { classMainData: this },
        });

        void this.getDesignPatterns();
    },

    updated() {
        if (useEdits().block.value) {
            this.edit_isActive = false;
            this.edit_changes = {};
            useEdits().block.value = false;
        }
        useEdits().setEditChanges(this.edit_changes);
        useEdits().setEditIsActive(this.edit_isActive);
    },

    /**
     * A different class (or the absence of it) or a different ontology (or both) is being shown, re-using the present view.
     * Therefore, if on edit mode, the edit state should be reset without tree re-load (because the tree will be different anyway).
     * @param {Object} to - Target Route Object being navigated to.
     * @param {Object} from - The current route being navigated away from.
     * @param {Function} next - Function to be called to resolve the hook.
     */
    beforeRouteLeave(to, from, next) {
        const newOntoID = to.params.ontologyID;
        const newPrimaryID = to.params.primaryID;
        const oldOntoID = from.params.ontologyID;
        const oldPrimaryID = from.params.primaryID;
        const hasEdits = useEdits().getEditChanges.value;
        const isNewTree =
            (newPrimaryID && newPrimaryID !== oldPrimaryID) ||
            (newOntoID && newOntoID !== oldOntoID);

        // The current view is active and requires confirmation
        if ((this.$refs.classBar && isNewTree) || hasEdits) {
            this.$refs.classBar
                .confirmEditCancel('centerTop', false, true)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });

            // The view is not active => bypasses confirmation
        } else {
            next();
        }
    },

    methods: {
        async getDesignPatterns() {
            if (this.ontologyID.trim() !== '') {
                const newDesignPattern = await getOntologyDesignPatterns({
                    ontologyId: this.ontologyID,
                });
                if (typeof newDesignPattern !== 'undefined') {
                    this.designPattern = newDesignPattern;
                } else {
                    this.designPattern = null;
                }
            }
        },
        /**
         * Changes the loading state.
         */
        setLoading(isLoading) {
            this.isLoading = isLoading;
        },
        /**
         * Assign the currently selected data, which could be an ontology or a class.
         * @param {definitions["OntologyMetadataJSONBean"]|definitions["OntologySummaryVM"]|{}} selectedData
         * @param {'class'|'ontology'|'all'}
         */
        async setSelectedData(selectedData, scope) {
            selectedData = selectedData.data ? selectedData.data : selectedData;

            if (['ontology', 'all'].includes(scope)) {
                useOntology().setCurrentOntology(selectedData);
            } else if (this.lastOntoData) {
                useOntology().setCurrentOntology(this.lastOntoData);
            }

            if (
                !Object.entries(useOntology().getCurrentOntology.value).length
            ) {
                const ontology = await ApiOntology.get({
                    ontologyID: this.ontologyID,
                });
                useOntology().setCurrentOntology(ontology.data);
            }

            this.shortFormID = this.termID(selectedData.data || selectedData);
            this.selectedData = selectedData;

            if (['class', 'all'].includes(scope))
                useClassView().setClassData(selectedData);
        },

        /**
         * Handler for node selection event.
         * When a node is selected update the current selectedData with class data:
         * - During a transaction the class data will be retrieved from the current transaction (the class as it
         *   appears in its currently edited value)
         * - Otherwise retrieves the current class data provided in the selectedData parameter.
         * @param {definitions["OntologyJSONBean"]} selectedData
         * @return {Promise<undefined>}
         */
        async handleNodeSelect(selectedData) {
            if (!selectedData) return;

            // If it's the same node do not update the selected data
            const previouslySelectedData = useClassView().getClassData.value;
            if (previouslySelectedData.id === selectedData.id) return;

            const isEditMode = useEdits().getEditIsActive.value;

            if (isEditMode) {
                await useEdits().refreshCurrentTransaction();
                const currentClassState = useEdits().getCurrentClassState(
                    selectedData.id
                );

                if (currentClassState) selectedData = currentClassState;
            }

            await this.setSelectedData(selectedData, 'class');
        },

        handleSelectEdit(event) {
            this.$refs.classTree.nodeSelectByID(event);
        },

        /**.
         * Checks if the component is in one of the current states possible states: 'view'|'edit'|'recovery'|'suggestion'.
         */
        handleBrowsingModes() {
            const params = this.$route.params;
            try {
                if (this.isAutoEdit) this.onEditMode();
                if (this.isSuggestionMode) this.onSuggestionMode();
                if (this.isRecoveryMode)
                    this.replayTransaction(params.recoveryTransactionID);
            } catch (error) {
                this.onError(error);
            }
        },

        /**
         * Fetches suggestions and initiates an edit transaction session
         * on the transaction id specified as URL query param.
         */
        async onSuggestionMode() {
            const params = this.$route.params;
            const suggestionsRequest = await this.$_suggestion_fetch(params);

            this.ontologyID = suggestionsRequest.data.ontologyId || ' ';

            this.replayTransaction(params.transactionID).catch((error) =>
                this.onError(error)
            );
        },

        /**
         * Retrieves the ontology data ans assigns it to the selectedData property.
         * @param
         */
        async updateOntology(params) {
            if (!isEmpty(params.initialData)) {
                return this.setInitialData(params.initialData, 'ontology');
            }

            let ontology;

            const isOntologyDataAvailable = !(
                isEmpty(this.lastOntoData) ||
                this.lastOntoData.ontologyUniqueID !== params.ontologyID
            );

            if (isOntologyDataAvailable) {
                ontology = { data: this.lastOntoData };
            } else {
                ontology = await this.retrieveOntologyProperties(
                    params.ontologyID
                );
            }

            await this.setSelectedData(ontology.data, 'ontology');
        },

        /**
         * Sets the page initial data.
         * @param {Object} initialData - Class data or ontology data :-|
         * @param {'class'|'ontology'}
         */
        setInitialData(initialData, scope = 'class') {
            this.setSelectedData(initialData, scope).then(() => {
                this.shortFormID = this.termID(initialData);
                this.setLoading(false);

                if (scope === 'class') {
                    this.tree = initialData.tree || {};
                } else if (initialData.ontologyUniqueID) {
                    this.lastOntoData = initialData;
                }
            });
        },

        /**
         * Retrieves the class data and assigns it to the selectedData property.
         * @param {Object} params
         * @param {String} primaryID
         */
        async updateClass(params, primaryID) {
            if (!isEmpty(params.initialData)) {
                return this.setInitialData(params.initialData, 'class');
            }

            // const classData = await this.fetchInfoAndTree(params);
            const classData = await this.retrieveClass(primaryID);

            await this.setSelectedData(classData, 'class');
            this.shortFormID = this.termID(classData);
            return classData;
        },

        async updateTree(params, oldParams = undefined) {
            if (!isEmpty(params.initialData)) {
                if (isEmpty(this.tree)) {
                    this.fetchTree({
                        ontologyID: this.selectedData.entityUniqueID,
                        classID: this.selectedData.id,
                    });
                }

                return;
            }

            const isFromSuggestion = oldParams && oldParams.transactionID;
            const isFromRecovery = oldParams && oldParams.recoveryTransactionID;
            const forceTreeReload = isFromSuggestion || isFromRecovery;
            const treeIsReusable = this.isTreeReusable(params.ontologyID);

            // Navigation to present ontology => renders list of root classes.
            if (treeIsReusable && !forceTreeReload) {
                this.$refs.classTree.collapseRoots();
                // Navigation to a class or a different ontology => gets new tree.
            } else {
                this.setLoading(true);
                this.tree = {};
                this.fetchTree(params).catch((error) => this.onError(error));
            }
        },

        /**
         * Loads the current view data based on route parameters.
         * @param {Object} params - View parameters passed in through the route.
         * @param {Object} oldParams - View parameters before the navigation.
         */
        async onRouteUpdate(params, oldParams = undefined) {
            if (this.isSuggestionMode) return this.onSuggestionMode();
            if (this.isRecoveryMode)
                return this.replayTransaction(
                    this.$route.params.recoveryTransactionID
                );

            this.ontologyID = params.ontologyID.trim();
            this.primaryID = (params.primaryID || '').trim();

            if (!this.ontologyID) return this.onError();

            const classPrimaryID = params.shortID
                ? await this.fetchPrimaryId(params.shortID)
                : params.primaryID;
            const isRouteToClass = !isEmpty(classPrimaryID);
            const viewData = { ontology: undefined, class: undefined };

            // No class data passed in => grabs data from the server
            if (isEmpty(params.initialData)) {
                this.fetchInfoAndTree(params, this.isRecoveryMode).then(
                    async (classData) => {
                        await this.setSelectedData(classData, 'class');
                        this.shortFormID = this.termID(classData);
                    }
                );
            }

            if (isRouteToClass) {
                viewData.ontology = await this.retrieveOntologyProperties(
                    params.ontologyID
                );
                viewData.class = await this.updateClass(params, classPrimaryID);
            } else {
                viewData.ontology = await this.updateOntology(params);
            }

            await this.updateTree(params, oldParams);

            this.isLoading = false;
        },

        /**
         * Resets the currely displayed class and tree to its initial setting.
         * @param {boolean} isNotification - True if the user should be prompted for confirmation.
         * @param {number} delay - Milliseconds the frontend should wait before issuing the reload request.
         */
        reload(isNotification = true, delay = 0) {
            const params = {
                ontologyID: this.ontologyID,
                primaryID: this.primaryID,
            };

            setTimeout(() => {
                if (this.$refs.classTree) {
                    this.$refs.classTree.resetTree(isNotification).then(() => {
                        this.setLoading(true);
                        this.fetchInfoAndTree(params, true).then(
                            async (classData) => {
                                await this.setSelectedData(classData, 'class');
                            }
                        );
                    });
                }
            }, delay);
        },

        /**
         * @returns {AxiosResponse<OntologySummaryVM>}
         */
        retrieveClass(primaryID) {
            this.setLoading(true);
            return ApiOntology.query({
                ontologyID: this.ontologyID,
                primaryID,
            });
        },

        /**
         * Retrieves an ontology whose properties have not been retrieved yet.
         * @param {String} ontologyID
         * @returns {AxiosPromise<Object>}
         */
        async retrieveOntologyProperties(ontologyID) {
            const ontology = await ApiOntology.get({ ontologyID });
            this.lastOntoData = ontology.data;
            return ontology;
        },

        /**
         * Reuse tree if coming from a selected class within the existing tree.
         * @param {string} ontologyID
         * @returns {boolean}
         */
        isTreeReusable(ontologyID) {
            const currOntologyID =
                this.selectedData.sourceUniqueID ||
                this.selectedData.ontologyUniqueID;

            return !isEmpty(this.tree) && currOntologyID === ontologyID;
        },

        /**
         * Retrieves the data for both the class and the class' paths from root.
         * @params {Object} params - Parameters for the data requests.
         * @params {boolean} [isForceTreeReload = false] - True if the tree must be reloaded irrespective of data availability.
         * @todo: disentangle the two requests
         */
        async fetchInfoAndTree(params, isForceTreeReload = false) {
            let infoFetched;

            const treeIsReusable = this.isTreeReusable(params.ontologyID);
            const shortID = params.shortID;
            const primaryID = shortID
                ? await this.fetchPrimaryId(shortID)
                : params.primaryID;

            const isOntologyDataAvailable = !(
                isEmpty(this.lastOntoData) ||
                this.lastOntoData.ontologyUniqueID !== params.ontologyID
            );

            if (primaryID) {
                // Retrieves class only
                infoFetched = await this.retrieveClass(primaryID);
            } else if (isOntologyDataAvailable) {
                // Reuse existing ontology data
                infoFetched = { data: this.lastOntoData };
            } else {
                // Retrieves ontology data only
                infoFetched = await this.retrieveOntologyProperties(
                    params.ontologyID
                );
            }

            // Reloads the tree...
            // @todo Move it to it's own method

            params.classID = infoFetched.data.id;

            // Navigation to present ontology => renders list of root classes.
            if (treeIsReusable && !isForceTreeReload) {
                this.setLoading(true);
                this.fetchTree(params)
                    .catch((error) => this.onError(error))
                    .finally(() => {
                        this.setLoading(false);
                    });
                this.$refs.classTree.collapseRoots();
                // Navigation to a class or a different ontology => gets new tree.
            } else {
                this.setLoading(true);
                this.tree = {};
                this.fetchTree(params)
                    .catch((error) => this.onError(error))
                    .finally(() => {
                        this.setLoading(false);
                    });
            }
            return infoFetched.data;
        },

        /**
         * Retrieves the all the nodes in the path from the root class to a specific one plus all sibling root classes.
         * @params {Object} params - Parameters for the data requests.
         * TODO: when a transaction tree is shown (no classID in parameters), only the edited classes are rendered.
         * It should be the full paths from each edited class.
         */
        fetchTree(params, pageStart) {
            let pathsRetrieved = Promise.resolve();
            let pathRoots = [{}];

            if (params.transactionData) {
                params.classID = map(
                    params.transactionData,
                    'lastOntologyEdit.ontologyClassId'
                );
                params.classes = map(
                    params.transactionData,
                    'lastOntologyEdit.ontologyJSONBean'
                );

                this.tree = ApiOntology.toRootClasses(params);
                return Promise.resolve();
            } else {
                return new Promise((resolve, reject) => {
                    if (params.classID) {
                        pathsRetrieved = ApiOntology.pathsFromRoot(params).then(
                            (pathsResponse) => {
                                pathRoots = pathsResponse.data.leaves;
                            }
                        );
                    }

                    pathsRetrieved
                        .then(() => {
                            ApiOntology.rootClasses({
                                ontologyID: this.ontologyID,
                                pageStart,
                            })
                                .then((rootsResponse) => {
                                    let roots = rootsResponse.data.leaves;

                                    if (params.classID) {
                                        pathRoots.forEach((pathRoot) => {
                                            if (
                                                typeof pathRoot.value ===
                                                'undefined'
                                            )
                                                return;
                                            remove(roots, (rootNode) => {
                                                const value = rootNode.value;
                                                return (
                                                    value.id ===
                                                        pathRoot.value.id &&
                                                    (value.typeOfNode =
                                                        pathRoot.value.typeOfNode)
                                                );
                                            });
                                        });
                                        roots.unshift(...pathRoots);
                                    }

                                    if (pageStart > 0) {
                                        this.tree.leaves =
                                            this.tree.leaves.concat(
                                                rootsResponse.data.leaves
                                            );
                                    } else {
                                        this.tree = rootsResponse.data;
                                    }

                                    resolve(this.tree);
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                });
            }
        },

        handlePagination() {
            if (this.tree.leaves.length >= this.tree.total) return;

            const params = {
                ontologyID: this.ontologyID,
                primaryID: this.primaryID,
            };

            this.pageStart += this.pageIncrement;
            this.fetchTree(params, this.pageStart);
        },

        /**
         * Loops through suggestions and populates ontologyJSONBean of obsolete classes.
         * @param {Array<Object>} suggestedEdits The suggested edits.
         * @returns {Promise<undefined>} An empty promise.
         */
        loadObsoleteClasses(suggestedEdits) {
            return new Promise(async (resolve) => {
                const mapPromises = suggestedEdits.data.map(async (edit) => {
                    if (edit.actions[edit.actions.length - 1] !== 'OBSOLETE') {
                        return;
                    }

                    try {
                        const obsoleteClass = await ApiOntology.getClass(
                            edit.lastOntologyEdit.ontologyId,
                            edit.lastOntologyEdit.ontologyClassId
                        );
                        edit.lastOntologyEdit.ontologyJSONBean =
                            obsoleteClass.data;
                    } catch (error) {
                        edit.deleted = true;
                    }
                });

                await Promise.all(mapPromises);

                resolve();
            });
        },

        /**
         * Remove classes that have been made obsolete and are not retrievable via API call.
         * @param {Array<Object>} suggestedEdits The suggested edits.
         * @returns {Object} Filtered axios response.
         */
        removeNotFoundClasses(suggestedEdits) {
            suggestedEdits.data = suggestedEdits.data.filter((edit) => {
                return !edit.deleted;
            });

            return suggestedEdits;
        },

        /**
         * Gets the tree for a specific suggestion through its transaction and checks that it is still editable.
         * @param {string} transactionID - ID for the transaction encompassing all the edits for a given suggestion.
         */
        fetchTransactionTree(transactionID) {
            return new Promise((resolve, reject) => {
                ApiEdit.transaction({ transactionID })
                    .then(async (response) => {
                        await this.loadObsoleteClasses(response);

                        response = this.removeNotFoundClasses(response);

                        // Sets the first edited class as the selected one.
                        const selectedData =
                            response.data[0] &&
                            response.data[0].lastOntologyEdit.ontologyJSONBean;

                        this.setSelectedData(selectedData, 'class');

                        // Extracts the ontology ID from the data in case it was not provided in advance.
                        this.ontologyID =
                            response.data[0] &&
                            response.data[0].lastOntologyEdit.ontologyId;

                        // Gets the combined tree for each of the edited classes paths if user has enough permissions and ontology loaded.
                        if (
                            this.$store.getters.canChange(this.ontologyID) &&
                            this.$store.getters.hasOnto(this.ontologyID)
                        ) {
                            this.fetchTree({
                                ontologyID: this.ontologyID,
                                transactionID,
                                transactionData: response.data,
                            })
                                .then(() => resolve(response.data))
                                .catch(reject);

                            // Wrong permissions => triggers error page.
                        } else {
                            reject(
                                new Error(
                                    'Not authorised to review or ontology not loaded'
                                )
                            );
                        }
                    })
                    .catch(reject);

                // Makes sure the tree is rendered as soon as all necessary data is available.
            }).finally(() => {
                this.setLoading(false);
            });
        },

        /**
         * Applies all of the edits making up a certain suggestion on to the tree and rebuilds the edit log
         * @param {string} transactionID - ID for the transaction encompassing all the edits for a given suggestion.
         */
        async replayTransaction(transactionID) {
            if (this.mountState === 'before') return;

            const transactionTreeFetched =
                this.fetchTransactionTree(transactionID);

            useEdits().setTransactionId(transactionID);

            // Only once the tree is rendered and transaction data available should the edit mode be enabled.
            // tree $once gets called twice
            this.$refs.classTree.$refs.hierarchy.$once('tree:rendered', () => {
                transactionTreeFetched.then(async (editedClasses) => {
                    let suggests = [];
                    let isLastEditItem;
                    this.onEditMode(transactionID);
                    const promises = editedClasses.map((editData) => {
                        // const classFromTree = this.$refs.classTree.findClass(editData.lastOntologyEdit.ontologyClassId, 'id');
                        return compareEditWithCurrent({
                            editId: editData.lastOntologyEdit.id,
                            ontologyId: editData.lastOntologyEdit.ontologyId,
                        });
                    });

                    const resolvedClassEdits = await Promise.all(promises);

                    useEdits().setListOfClassChanges(resolvedClassEdits);

                    // For every entry in the transaction representing a class, rebuilds the edit log.
                    // editedClasses.forEach(async (editData, index) => {
                    //     const classFromTree = this.$refs.classTree.findClass(editData.lastOntologyEdit.ontologyClassId, 'id');
                    //     isLastEditItem = index === editedClasses.length - 1;
                    //     const editId = editData.lastOntologyEdit.id;
                    //     this.ontologyID

                    //     const classChanges = await compareEditWithCurrent()
                    // this.$_edit_addToLog(this.ontologyID, editData, classFromTree, suggests).then(() => {
                    //     if (isLastEditItem && !this.$_edit_hasEdits && !this.isRecoveryMode) {
                    //         this.onSuggestionStale();
                    //     }
                    // });

                    // });

                    // Only considers the latest suggest event when extracting the comment.
                    if (suggests.length) {
                        suggests = orderBy(suggests, 'eventDate', 'desc');
                        this.edit_comment = suggests[0].description.trim();

                        // In case the retrieved comment is a custom one.
                        // TODO: this forces all updates to an existing suggestion to never be auto-populated.
                        if (this.edit_comment) {
                            this.$refs.classBar.isCommentInteractive = true;
                        }
                    } else {
                        this.edit_comment = '';
                    }
                });
            });

            return transactionTreeFetched;
        },

        /**
         * Clears all basic state and throws an exception if an error is provided.
         * @param {string|undefined} error - Runtime error object.
         */
        onError(error = undefined) {
            this.tree = {};
            this.setSelectedData({}, 'all');
            this.setLoading(false);

            if (error) {
                throw new Error(error);
            }
        },

        /**
         * Sets all environment properties to its original value, as if editing had never happened.
         * @param {boolean} isReloadTree - Tears down the current tree view and re-renders it.
         */
        resetEditMode(reloadTree = false) {
            this.$_edit_reset();
            this.$eventBus.$emit('notification:close');

            // Reloads the tree for the focused class instead of the selected one.
            if (reloadTree) this.reload(false);
        },

        /**
         * Returns from rendering a particular suggestion, restoring any initial view state.
         */
        resetSuggestionMode(resetTree = false) {
            this.$_suggestion_reset();
            this.resetEditMode();

            if (resetTree) this.tree = {};
        },

        /**
         * Shows visual feedback whenever there has been a class property change in a suggestion.
         * @param {string} propNameOrIri
         * @param {string | string[]} newValue
         * @param {string[]} oldValue
         * @param {object} classData
         * @param {string} newSingleValue
         * @return {Promise<void>}
         */
        onClassChange(
            propNameOrIri,
            newValue,
            oldValue,
            classData,
            newSingleValue
        ) {
            this.$_edit_onClassChange(
                propNameOrIri,
                newValue,
                oldValue,
                classData,
                newSingleValue
            ).then(async () => {
                this.nodeUpdated = true;
                const oldPrimaryLabel = Array.isArray(oldValue)
                    ? oldValue[0]
                    : oldValue;
                const newPrimaryLabel = Array.isArray(newValue)
                    ? newValue[0]
                    : newValue;

                if (propNameOrIri === 'primaryLabel') {
                    useObservers().notifyObservers('PRIMARY_LABEL_UPDATED', {
                        oldValue: oldPrimaryLabel,
                        newValue: newPrimaryLabel,
                    });
                }

                if (this.suggestion_isActive) {
                    this.$refs.classBar.onSuggestionUpdate();
                }
                await useEdits().countClassChanges();
            });
        },

        async onBulkClassChange(bulkNodes) {
            await this.$_edit_onBulkClassChange(bulkNodes);
            this.nodeUpdated = true;

            if (this.suggestion_isActive) {
                this.$refs.classBar.onSuggestionUpdate();
            }
        },

        /**
         * Only allows edition if the user has appropriate authorisation and avoids accidental resetting if
         * on edit mode already.
         * @param {string} transactionID - ID for the existing unit encompassing all edits if replaying them.
         */ async onEditMode(transactionID = this.$uuid.v4()) {
            if (
                this.$store.getters.canChange(this.ontologyID) &&
                !this.edit_isActive
            ) {
                this.$_edit_start(transactionID);

                if (!this.suggestion_isActive) {
                    this.$_recovery_save_current_transaction(
                        this.ontologyID,
                        transactionID
                    );
                } else {
                    await useEdits().countClassChanges();
                }
            }
        },

        /**
         * When coming out of edit mode, it undoes any logged changes and resets the view.
         * @param {boolean} isTreeChanged - True if any changes made requires a tree reload.
         */
        onEditCancel(isTreeChanged = this.edit_isRelChanges) {
            if (useClassView().whichSchemaVersion === 1) {
                this.$_edit_rollbackLog(this.edit_errors, !isTreeChanged);
                this.$_edit_rollbackLog(this.edit_changes, !isTreeChanged);
            } else {
                useEdits().rollback();
            }

            // If this line is not here, then when cancelled out of the edit mode the merge changes will still be registered
            if (useEdits().hasMergeEditChanges.value)
                useEdits().setMergeEditChanges([]);

            this.resetEditMode(isTreeChanged);
            this.$_recovery_reset();
            this.reload(false);
        },

        /**
         * Saves the edited data, giving feeback with tailored language after the operation.
         * @param {string} submitAction - Type of submission when saving the edited data.
         */
        onEditSubmit(submitAction, createReverseMappings) {
            let successMsg = 'All class changes have been succesfully';
            let actionRoot;
            let saveAction;

            switch (submitAction) {
                case 'suggest':
                case 'comment':
                    actionRoot = 'submitt';
                    saveAction = 'suggest';
                    break;
                case 'save':
                case 'approve':
                    actionRoot = submitAction.slice(0, -1);
                    saveAction = 'commit';
                    break;
                default:
                    actionRoot = submitAction;
                    saveAction = 'reject';
            }

            this.$_edit_save(
                this.ontologyID,
                saveAction,
                this.upperFirst(actionRoot + 'ing...'),
                createReverseMappings
            )
                .then(() => {
                    if (actionRoot === 'submitt') {
                        this.onSaveSuccess(
                            `${successMsg} ${actionRoot}ed for review before being published.`,
                            true
                        );
                    } else {
                        this.onSaveSuccess(
                            `${successMsg} ${actionRoot}ed.`,
                            true
                        );
                    }
                })
                .catch((error) => {
                    this.onSaveError(submitAction, error);
                });

            // These two lines reset edit changes on save so the dialog to say you have outstanding edits left after submission
            useEdits().setMergeEditChanges([]);
            useEdits().resetTagsChanges();
            useEdits().setTransactionId('');
            this.pageStart = 0;
        },

        /**
         * Gets out of the current view mode, showing feedback if necessary.
         * @param {string} message - Text shown as confirmation of success.
         * @param {boolean} isReload - True if the tree needs to be reloaded.
         */
        onSaveSuccess(message, isReload = this.edit_isRelChanges) {
            const redirection = this.afterSuggestion();
            this.$_recovery_reset();

            // Suggestion is being revised => renders the currently selected class.
            if (this.suggestion_isActive) {
                this.resetSuggestionMode();
                this.$router.push(redirection);

                // No revision => confirms and go back to previous class view state if it is a suggestion.
            } else {
                this.$eventBus.$emit('notification:show', 'success', message);
                this.$_edit_rollbackLog(this.edit_changes, false);
                this.resetEditMode(isReload);
            }
        },

        /**
         * The user is prompted for action if there have been no errors.
         * @param {string} submitAction - Type of submission when saving the edited data.
         * @param {Object} error - Error object generated after the save operation.
         * @param {boolean} isReload - True if the tree needs to be reloaded.
         */
        onSaveError(submitAction, error, isReload = this.edit_isRelChanges) {
            this.$eventBus.$emit(
                'notification:show',
                'critical',
                `${this.addFullStop(
                    error.errorMessage
                )} Do you wish to continue editing and try again later?`,
                `${submitAction} operation failed`,
                [
                    {
                        text: 'Continue editing',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);
                        },
                    },
                    {
                        text: this.suggestion_isActive
                            ? 'Withdraw suggestion'
                            : 'Discard changes',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);

                            // Discarding an existing suggestion => withdraws
                            if (this.suggestion_isActive) {
                                this.onSuggestionWithdraw();

                                // Discarding a new suggestion => cancels edits
                            } else {
                                this.$_recovery_reset();

                                this.$nextTick(() => {
                                    this.$_edit_rollbackLog(
                                        this.edit_changes,
                                        !isReload
                                    );
                                    this.$_edit_rollbackLog(
                                        this.edit_errors,
                                        !isReload
                                    );
                                    this.resetEditMode(isReload);
                                });
                            }
                        },
                    },
                ],
                'centerTop'
            );
        },

        /**
         * Confirms whether a suggestion whose changes have already been saved elsewhere should be withdrawn
         */
        onSuggestionStale() {
            this.$eventBus.$emit(
                'notification:show',
                'critical',
                'All changes associated with this suggestion have already been saved to the ontology. Do you wish to withdraw it?',
                'Stale suggestion',
                [
                    {
                        text: 'Back to review',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);
                        },
                    },
                    {
                        text: 'Withdraw',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);
                            this.$nextTick(() => {
                                this.onSuggestionWithdraw();
                            });
                        },
                    },
                ],
                'rightTop'
            );
        },

        /**
         * Withdraws the suggestion corresponding to the current transaction ID and redirects to the current class.
         */
        onSuggestionWithdraw() {
            let changedClasses = [];
            this.$_edit_editedClasses.forEach((changedClass) => {
                changedClasses.push(changedClass.primaryLabel);
            });

            this.$_recovery_reset();
            this.$_suggestion_withdraw(
                this.edit_transactionID,
                this.ontologyID,
                changedClasses.join(', ')
            );
            this.$router.push(this.afterSuggestion());
        },

        /**
         * Determines where to redirect depending on the nature of the current selection.
         */
        afterSuggestion() {
            this.$_recovery_reset();

            const isNewSelected = useEdits().isClassNew(this.selectedData.id);

            // The selected class is new => redirects to the root ontology view.
            if (isNewSelected) {
                return {
                    name: 'ontology',
                    params: { ontologyID: this.ontologyID },
                };

                // The selected class is not new => redirects to that.
            } else {
                return {
                    name: 'class',
                    params: {
                        ontologyID: this.ontologyID,
                        primaryID: this.selectedData.primaryID,
                    },
                };
            }
        },

        /**
         * Restores the maximised info pane to its original size if double-clicked for a second time.
         */
        onInfoMaximise() {
            const treePane = this.$refs.splitPanes.panes[0];
            const infoPane = this.$refs.splitPanes.panes[1];

            if (this.isInfoMax) {
                treePane.size = 100 - this.oldInfoSize;
                infoPane.size = this.oldInfoSize;
                this.isInfoMax = false;
            } else {
                this.isInfoMax = true;
            }

            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.classItem &&
                        this.$refs.classItem.updateGraphDims();
                }, 250);
            });
        },

        /**
         * Resets the maximised flag and backs up whatever size the pane ends up with.
         */
        onInfoResize() {
            const infoPane = this.$refs.splitPanes.panes[1];

            this.isInfoMax = false;
            this.oldInfoSize = infoPane.size;
            this.$refs.classItem && this.$refs.classItem.updateGraphDims();
        },

        /**
         * Uses the ontology class resource to retrieve the class primary ID by Short ID.
         * @param {String} shortID
         * @returns {Promise<String|null>}
         */
        async fetchPrimaryId(shortID) {
            const response = await ApiOntology.classByShortId({
                ontologyID: this.ontologyID,
                shortID,
            });
            return response.data.primaryID || null;
        },

        /**
         * Updates the current address with the short ID of the selected class.
         * @todo:
         *  - use the primary ID instead of the shortId
         *  - use a route middleware to prevent update instead of a local variable (which does not work)
         *  - remove UPDATE_ROUTES_ON_CLASS_CLICK check
         *  - delete UPDATE_ROUTES_ON_CLASS_CLICK.ts file and its reference in config/index.ts
         */
        silentlyUpdatePath() {
            if (!UPDATE_ROUTES_ON_CLASS_CLICK) return;

            const shortId =
                this.selectedData.shortFormIDs &&
                this.selectedData.shortFormIDs[0];

            if (shortId) {
                const baseUrl = `/ontology/${this.ontologyID}/`;
                this.preventUpdate = true;
                this.$router.history.push({ path: baseUrl + shortId });
            }
        },

        /**
         * Shows the information for the current class/ontology in the title.
         */
        setDocumentData() {
            document.title =
                process.env.VUE_APP_NAME +
                ' | ' +
                (this.selectedData.primaryLabel ||
                    this.selectedData.ontologyUniqueID);
        },

        /**
         * When the route update has been prevented sets the preventUpdate param back to false.
         * @return {boolean}
         */
        routeUpdatePrevented() {
            this.preventUpdate = false;
            return false;
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.class-wrapper {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 !important;
    }
}

.usage-hint {
    font-weight: 500;
}
.class-usage {
    &.resolving,
    ::v-deep .name-wrapper {
        display: none;
    }
}

.class-body {
    flex-direction: column-reverse;
    margin-bottom: 3rem;

    &.default-theme {
        &.splitpanes--dragging {
            .info-panel {
                @media (min-width: 386px) {
                    overflow-x: hidden;
                }
            }
            ::v-deep .tree {
                overflow-x: hidden;
            }
        }

        .splitpanes__pane {
            background: none;
        }

        ::v-deep .splitpanes__splitter {
            display: none;
            width: 10px;
            background: $gray-200;
            border-left: 1px solid $gray-300;
            border-right: 1px solid $gray-300;

            &:before,
            &:after {
                background: $body-color !important;
            }
        }
    }

    .info-panel {
        ::v-deep .ontology-item {
            .title-container {
                flex-grow: 1;
            }

            .title-name {
                flex-grow: 1;
                margin-bottom: 0.4rem;
                text-align: center;
                color: $body-color;
                background: rgba($primary, 0.4);
            }

            .properties-btn {
                display: none;
            }

            .container.collapse.show {
                margin: 0;
                padding: 0.4rem 0;
            }
        }
    }

    &.edit-allowed:not(.edit-mode) {
        .class-item ::v-deep .text-container {
            &:hover {
                background: rgba($info, 0.08);
            }
        }
    }

    // TODO: will disappear after merged tree with all edited classes is available.
    &.replay-mode {
        ::v-deep .class-item .relationship-property {
            opacity: 0.65;
            cursor: not-allowed;
            * {
                pointer-events: none;
            }
        }
    }

    @media (max-width: 1023px) {
        &.default-theme {
            .splitpanes__pane {
                width: auto !important;
            }
        }
    }

    @media (min-width: 1024px) {
        flex-direction: row;
        overflow: hidden;
        margin-bottom: 0;

        &.default-theme {
            ::v-deep .splitpanes__splitter {
                display: block;
            }
        }

        .info-panel {
            position: relative;
            display: flex;
            overflow: auto;

            ::v-deep .ontology-item {
                flex-grow: 1;

                .item-size {
                    display: none;
                }

                .container.collapse.show {
                    .row {
                        display: block;

                        dl {
                            max-width: none;
                        }
                    }
                }
            }

            ::v-deep .class-item {
                flex-grow: 1;
                min-width: 385px;
            }
        }

        .class-tree {
            position: relative;
            height: 100%;
            margin: 0 !important;
            border: 0;
            border-right: 1px solid $gray-300;
            background: #fff;

            ::v-deep .tree-container {
                display: flex;
                flex-direction: column;
                height: 100%;
                min-width: 385px;
            }

            ::v-deep .tree {
                flex-grow: 1;
            }

            ::v-deep .tree-loading,
            ::v-deep .tree-no-results {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>
