import { isEmpty } from 'lodash';
import { useEdits } from '@/compositions';

import ApiEdit from '@/api/edit';

export default {
    data() {
        return {
            // Properties of any suggestion being edited.
            suggestion: {},

            // True when rendering the tree for the edited classes making up a certain transaction, including the edited states
            suggestion_isActive: false,
        };
    },

    computed: {
        $_suggestion_exists: function () {
            return !isEmpty(this.suggestion);
        },

        $_suggestion_isRejected: function () {
            return (
                this.$_suggestion_exists &&
                this.$store.getters.isRejected(this.suggestion.status)
            );
        },

        $_suggestion_isOwner: function () {
            return this.suggestion.userLogin === this.$store.getters.username;
        },

        // The suggestion is either open or is rejected but the owner is the current user.
        // NOTE: rejected suggestions are closed only for other reviewers.
        $_suggestion_isStrictPending: function () {
            const isRejectOpen =
                this.$_suggestion_isRejected && this.$_suggestion_isOwner;
            return (
                this.$_suggestion_exists &&
                (this.$store.getters.isSent(this.suggestion.status) ||
                    isRejectOpen)
            );
        },

        // Human-readable version of the suggestion status.
        $_suggestion_humanStatus: function () {
            if (this.$_suggestion_exists) {
                return this.$store.getters.humanStatus(this.suggestion.status);
            } else {
                return '';
            }
        },
    },

    methods: {
        $_suggestion_reset() {
            this.suggestion_isActive = false;
            useEdits().setIsSuggestionMode(false);
            this.suggestion = {};
        },

        $_suggestion_start(data = {}) {
            this.suggestion_isActive = true;
            useEdits().setIsSuggestionMode(true);
            this.suggestion = data;
        },

        $_suggestion_fetch(params) {
            const fetchParams = { transactionID: params.transactionID.trim() };

            this.$_suggestion_start(params.suggestion);

            // Silently retrieves suggestion data if not passed already.
            if (isEmpty(this.suggestion)) {
                this.$store.isPreventGlobalError = true;
                return ApiEdit.suggestion(fetchParams)
                    .then((response) => {
                        this.suggestion = response.data;

                        // If there is an editable suggestion, a transaction is guaranteed => replays.
                        if (
                            !this.$_suggestion_exists ||
                            !this.$_suggestion_isStrictPending
                        ) {
                            throw new Error(
                                'The suggestion has reached the end of its lifecycle.'
                            );
                        } else {
                            return response;
                        }
                    })
                    .finally(() => {
                        this.$store.isPreventGlobalError = false;
                    });

                // Suggestion data was provided in advance.
            } else {
                return Promise.resolve({ data: this.suggestion });
            }
        },

        $_suggestion_withdraw(transactionID, ontologyID, comment) {
            this.$store.dispatch('suggestionDel', {
                suggestionID: transactionID,
                ontologyID,
                comment,
            });
        },
    },
};
